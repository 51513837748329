import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfiguracionService } from '../seguridad_api/configurations/configuracion.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Preferencia, PreferenciaResponse } from 'src/app/model/preferencia.class';

@Injectable({
  providedIn: 'root'
})
export class PreferenciasService {
  url: string;

  constructor(private http: HttpClient,
    private configuracion: ConfiguracionService) {
    this.url = this.configuracion.serverUrlApiSegura + '/preferencia';
  }

  obtenerPreferencias(usuario: string, pantalla: string, codPreferido: string): Observable<any> {
    const url = `${this.url}?usuario=${usuario}&pantalla=${pantalla}&preferido=${codPreferido}`;
    return this.http.get<any>(url).pipe(
      map((result: any) => {
        return result.data;
      })
    )
  }

  guardarPreferencia(preferencia: any): Observable<any> {
    const url = this.url;
    return this.http.post<any>(url, preferencia).pipe(
      map((result: any) => {
        return result.data;
      })
    )
  }

  eliminarPreferencia(preferencia: Preferencia): Observable<any> {
    const url = `${this.url}/eliminar-preferencia`;
    return this.http.put<any>(url, preferencia).pipe(
      map((result: any) => {
        return result.data;
      })
    )
  }

  obtenerPreferenciasPorUsuario(usuario: string, pantalla: string): Observable<Preferencia[]> {
    const url = `${this.url}/obtener-preferencia-por-usuario/?coUsua=${usuario}&coPant=${pantalla}`;
    return this.http.get<PreferenciaResponse>(url).pipe(
      map((result: PreferenciaResponse) => {
        return result.data;
      })
    )
  }
  
}