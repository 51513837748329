import { Component, Inject, Input, OnChanges, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { IndicadoresComponent } from '../../core/gestion/indicadores/indicadores.component';
import { GestionComponent } from '../../core/gestion/gestion.component';
import { SharedService } from '../../services/shared.service';
import { Router } from '@angular/router';
import { InitCoordinatorService } from '../../services/init-coordinator.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class ContentComponent implements OnInit, OnChanges, OnDestroy {
  
  @Input() cia: any;
  ciaSelected = new EventEmitter<any>();
  lastEvent: string;
  private initSubscription: Subscription | null = null;

  constructor(
    private sharedService: SharedService,
    private router: Router,
    private initCoordinator: InitCoordinatorService
  ) { }

  ngOnInit() {
    // this.sharedService.eventEmitter.subscribe((event: any) => {
    //   this.lastEvent = event;
    //   //console.log('this.lastEvent', this.lastEvent);
    //   this.router.navigateByUrl(this.lastEvent);
    // });
    this.initSubscription = this.initCoordinator.init$.subscribe(() => {
      // Lógica de inicialización del componente hijo
      console.log('Child ngOnInit after Parent');
      // Aquí puedes colocar la lógica que depende de que el padre haya terminado de inicializarse
    });
  }

  ngOnChanges() {
    /**********THIS FUNCTION WILL TRIGGER WHEN PARENT COMPONENT UPDATES 'someInput'**************/
    //Write your code here
     //console.log('ciaContent', this.cia);
     //this.ciaSelected.emit(this.cia);
     //console.log('this.lastEvent', this.lastEvent);
  } 

  ngOnDestroy(): void {
    // Desuscribirse para evitar fugas de memoria
    if (this.initSubscription) {
      console.log('content-ngOnDestroy');
      this.initSubscription.unsubscribe();
    }
  }

}
