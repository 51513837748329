import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfiguracionService } from '../seguridad_api/configurations/configuracion.service';

@Injectable({
  providedIn: 'root'
})
export class WebServicePhoenixService {

  url: string;
  constructor(private http: HttpClient,
    private configuracion: ConfiguracionService) {
    this.url = this.configuracion.serverUrlRecurso + '/acceso_externo.xhtml';
  }

  public LoginWebServicePhoenix(token: string): Observable<any> {
    const url = this.url + `?accion=login&parametros=${token}`;
    return this.http.get<any>(url).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  public verificarEstadoServidor(): Observable<any> {
    const url = this.url + `?accion=estadoServidor&parametros=`;
    return this.http.get<any>(url).pipe(

      map((response: any) => {
        return response;
      })
    );
  }

  public accesoUrl(url: string) {
    this.http.get<any>(url).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  public cerrarSessionWebService(): Observable<any> {
    const url = this.url + `?accion=cerrarSession&parametros=0`;
    return this.http.get<any[]>(url).pipe(
      map((response: any) => {
        return response;
      })
    );
  }
}
