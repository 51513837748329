<p-dialog header="Mail {{header}}" [(visible)]="displayMail" [modal]="true" [responsive]="true"
  [closeOnEscape]="false" [closable]="false"
  [style]="{width: '800px', minWidth: '100px'}" [minY]="50" [maximizable]="true" [baseZIndex]="10000">
  <div class="p-grid" style="text-align: center">
    <div class="p-col-12 p-md-12 p-lg-12">
      <p-card>
        <div class="p-grid">
          <div class="p-col-12 p-md-12 p-lg-12">
            <div class="ui-inputgroup">
              <span class="ui-inputgroup-addon"><i class="fa fa-user"></i></span>
              <input type="text" [(ngModel)]="mailFrom" pInputText placeholder="De:" style="width: 100%" disabled>
            </div>
          </div>
          <div class="p-col-12 p-md-12 p-lg-12">
            <div class="ui-inputgroup">
              <span class="ui-inputgroup-addon"><i class="fa fa-mail-bulk"></i></span>
              <input type="text" [(ngModel)]="mailTo" pInputText placeholder="Para:" style="width: 100%" disabled>
            </div>
          </div>
          <div class="p-col-12 p-md-12 p-lg-12">
            <div class="ui-inputgroup">
              <span class="ui-inputgroup-addon"><i class="fa fa-question-circle"></i></span>
              <input type="text" [(ngModel)]="asunto" pInputText placeholder="Asunto:" style="width: 100%">
            </div>
          </div>
          <div class="p-col-12 p-md-12 p-lg-12">
            <p-editor [(ngModel)]="mensajeTexto" [style]="{'height':'320px'}">
            </p-editor>
          </div>
        </div>
      </p-card>
    </div>
  </div>
  <p-footer>
    <button type="button" pButton icon="fas fa-paper-plane" (click)="enviarMail()" label="Enviar"></button>
    <button type="button" pButton icon="fas fa-times" class="ui-button-secondary" (click)="cancelarModalMail()"
      label="Cancelar"></button>
  </p-footer>
</p-dialog>