import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-password-actualizado',
  templateUrl: './page-password-actualizado.component.html',
  styleUrls: ['./page-password-actualizado.component.scss']
})
export class PagePasswordActualizadoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    document.getElementsByTagName('body')[0].classList.add('body-dashboard');
  }

}
