<p-panel header="NOTIFICACIONES" [style]="{'margin-bottom':'10px', 'text-align': 'center'}">
    <br>
    <p-card id="cardNotificacion" styleClass="ui-card-shadow card-style-gsri">
        <div class="p-grid p-justify-center">
            <div class="p-col-6 p-md-1 p-lg-1">
                <label class="ui-outputlabel ui-widget">
                    Período:
                </label>
            </div>
            <div class="p-col-6 p-md-3 p-lg-3">
                <p-dropdown [options]="periodoSelect" optionLabel="DE_NOMB_PRDO" optionValue="ID_PRDO"
                    [(ngModel)]="periodoNoti" (onChange)="selectCombos()" filter="true"></p-dropdown>
            </div>
            <div class="p-col-12 p-md-1 p-lg-1"></div>
            <div class="p-col-6 p-md-1 p-lg-1">
                <label class="ui-outputlabel ui-widget">
                    Hecho:
                </label>
            </div>
            <div class="p-col-6 p-md-3 p-lg-3">
                <p-dropdown [options]="hechoSelect" optionLabel="DE_HECHO" optionValue="ID" [(ngModel)]="hecho"
                    (onChange)="selectCombos()" filter="true"></p-dropdown>
            </div>
        </div>
    </p-card>
    <br>

    <!-- <app-table #table [data]="dataNotificaciones" [row]="10" [totalRecords]="totalRecords" [column]="cols"
        [scrollHeight]="'400px'" [loading]="loading" [paginator]="true">
    </app-table> -->
    <p-table [columns]="cols" [value]="dataNotificaciones" [paginator]="true" [rows]="10">
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th *ngFor="let col of columns" [pSortableColumn]="col.sort == true ? col.field : null"
                    [ngStyle]="{'width': col.width}">
                    {{col.header}}
                    <p-sortIcon *ngIf="col.sort" [field]="col.field"></p-sortIcon>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr> 
                <td *ngFor="let col of columns" 
                    [ngStyle]="{'width': col.width, 'overflow-wrap': 'break-word', 'word-wrap': 'break-word'}">

                    <span *ngIf="col.type=='date'">{{rowData[col.field] | date:'dd/MM/yyyy HH:mm:ss'}}</span>
                    <span *ngIf="col.type=='label'" [innerHtml]="rowData[col.field]"></span>
                    <span *ngIf="col.type=='center'">{{rowData[col.field]}}</span>
                    <!-- hypervínculo -->
                    <span *ngIf="col.type=='link'" style="cursor: pointer">
                        <a *ngIf="rowData[col.link]!=null && rowData[col.link]!=undefined && rowData[col.link]!=''" target="_blank"
                        rel="noopener" (click)="accederEnlace(urlEnlace+rowData[col.link])">{{rowData[col.field]}}</a>
                    <span *ngIf="rowData[col.link]==null || rowData[col.link]==undefined || rowData[col.link]==''">{{rowData[col.field]}}</span>
                    </span>
                    <span *ngIf="col.type=='hecho'">
                    <img [src]="rowData[col.field] == '1' ? './../../../../assets/icons/notificacion/noti-complete.png' : './../../../../assets/icons/notificacion/noti-pendiente.png'" 
                        id="icon" alt="Menu" style="width: 20px;"/>
                    </span>
                </td>
            </tr>
        </ng-template>
    </p-table>
</p-panel>