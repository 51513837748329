import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { CardsComponent } from 'src/app/component/util/cards/cards.component';
import { UsuariosService } from 'src/app/services/seguridad/usuarios.service';

@Component({
  selector: 'app-resumen',
  templateUrl: './resumen.component.html',
  styleUrls: ['./resumen.component.scss']
})
export class ResumenComponent implements OnInit, AfterViewInit {
  
  abrirMenu = true;
  ruta = '/phoenix/indicadores';

  headerCuentasPorPagar: string;
  iconoCuentasPorPagar: string;
  bodyCuentasPorPagar: any;
  semaforoRojo: string;
  mailFromCPP: string;
  mailToCpp: string;

  headerCuentasPorCobrar: string;
  iconoCuentasPorCobrar: string; // "cuentas-x-cobrar-128.png";
  bodyCuentasPorCobrar: any;
  semaforoVerde: string;
  mailFromCPC: string;
  mailToCpc: string;

  headerVentas: string;
  iconoVentas: string;
  bodyVentas: any;
  semaforoNaranja: string;
  mailFromVentas: string;
  mailToVentas: string;

  headerRentabilidad: string;
  iconoRentabilidad: string;
  bodyRentabilidad: any;
  mailFromRentabilidad: string;
  mailToRentabilidad: string;

  headerInventario: string;
  iconoInventario: string;
  bodyInventario: any;
  mailFromInventario: string;
  mailToInventario: string;

  headerImportacion: string;
  iconoImportacion: string;
  bodyImportacion: any;
  mailFromImportacion: string;
  mailToImportacion: string;

  valorCardCPP = 75;
  valorCardCPC = 60;
  valorCardVenta = 90;
  valorCardRentabilidad = 55;
  valorCardInventario = 87;
  valorCardImportacion = 25;

  username: string;
  datosUsuario: any;

  permiso: boolean = true;

  @ViewChild('cardCPP') cardCPP: CardsComponent;
  @ViewChild('cardCPC') cardCPC: CardsComponent;
  @ViewChild('cardVentas') cardVentas: CardsComponent;
  @ViewChild('cardInventario') cardInventario: CardsComponent;
  @ViewChild('cardImportacion') cardImportacion: CardsComponent;
  @ViewChild('cardRentabilidad') cardRentabilidad: CardsComponent;
  constructor(private usuarioService: UsuariosService) { }

  ngOnInit() {
    document.getElementsByTagName('body')[0].classList.add('body-dashboard');

    this.username = sessionStorage.getItem('SESSIONUSER');
    sessionStorage.removeItem('SESSION_CARD');

    this.obtenerDatosUsuario();

  }

  ngAfterViewInit(): void {
    setInterval(() => {
      const valorCpp = Math.round(Math.random() * (100 - 0) + 0);
      const semafCpp = valorCpp < 30 ? '#FC3314' : (valorCpp > 30 && valorCpp < 65) ? '#FEB312' : '#53CB1A';
      const valorCpc = Math.round(Math.random() * (100 - 0) + 0);
      const semafCpc = valorCpc < 30 ? '#FC3314' : (valorCpc > 30 && valorCpc < 65) ? '#FEB312' : '#53CB1A';
      const valorVentas = Math.round(Math.random() * (100 - 0) + 0);
      const semafVentas = valorVentas < 30 ? '#FC3314' : (valorVentas > 30 && valorVentas < 65) ? '#FEB312' : '#53CB1A';
      const valorRentabilidad = Math.round(Math.random() * (100 - 0) + 0);
      const semafRentabilidad = valorRentabilidad < 30 ? '#FC3314' :
        (valorRentabilidad > 30 && valorRentabilidad < 65) ? '#FEB312' : '#53CB1A';
      const valorInventario = Math.round(Math.random() * (100 - 0) + 0);
      const semafInventario = valorInventario < 30 ? '#FC3314' : (valorInventario > 30 && valorInventario < 65) ? '#FEB312' : '#53CB1A';
      const valorImportacion = Math.round(Math.random() * (100 - 0) + 0);
      const semafImportacion = valorImportacion < 30 ? '#FC3314' : (valorImportacion > 30 && valorImportacion < 65) ? '#FEB312' : '#53CB1A';

      this.cardCPP.construirGrafico(valorCpp, semafCpp);
      this.cardCPC.construirGrafico(valorCpc, semafCpc);
      this.cardInventario.construirGrafico(valorInventario, semafInventario);
      this.cardVentas.construirGrafico(valorVentas, semafVentas);
      this.cardRentabilidad.construirGrafico(valorRentabilidad, semafRentabilidad);
      this.cardImportacion.construirGrafico(valorImportacion, semafImportacion);
    }, 10000);
  }

  obtenerDatosUsuario() {
    const This = this;
    this.usuarioService.obtenerUsuarioSeguro(this.username).subscribe({
      next(usuario: any) {
        if ((usuario)) {
          This.datosUsuario = usuario;
          This.cargarDatosCuentasPorPagar();
          This.cargarCuentasPorCobrar();
          This.cargarVentas();
          This.cargarRentabilidad();
          This.cargarInventario();
          This.cargarImportacion();
        }
      }
    });
  }

  cargarDatosCuentasPorPagar() {
    this.headerCuentasPorPagar = 'Cuentas Por Pagar';
    this.iconoCuentasPorPagar = 'icon-red-64.png';
    this.bodyCuentasPorPagar = [
      { header: 'Cumplimiento Pago Proveedor Extranjero', value: '50000.00', porc: '10', icon: 'far fa-money-bill-alt', class: 'card-red' },
      { header: 'Cumplimiento Pago Proveedor Nacional', value: '30000.00', porc: '30', icon: 'far fa-money-bill-alt', class: 'card-ambar' },
      {
        header: 'Notas de crédito sin aplicar en un mismo periodo', value:
          '20000.00', porc: '20', icon: 'fas fa-file-invoice-dollar', class: 'card-ambar'
      }
    ];
    this.semaforoRojo = '#FC3314';
    this.mailFromCPP = this.datosUsuario.DE_CORREO;
    this.mailToCpp = 'jarcelles@dest.com.pe';
  }

  cargarCuentasPorCobrar() {
    this.headerCuentasPorCobrar = 'Cuentas Por Cobrar';
    this.iconoCuentasPorCobrar = 'icon-green-64.png';
    this.bodyCuentasPorCobrar = [
      { header: 'Total por cobrar', value: '50000.00', porc: '50', icon: 'far fa-money-bill-alt', class: 'card-green' },
      { header: 'Total cobrado', value: '30000.00', porc: '30', icon: 'far fa-money-bill-alt', class: 'card-green' },
      { header: 'Deuda vencida', value: '20000.00', porc: '20', icon: 'far fa-money-bill-alt', class: 'card-red' }
    ];
    this.semaforoVerde = '#53CB1A';
    this.mailFromCPC = this.datosUsuario.DE_CORREO;
    this.mailToCpc = 'wespinoza@dest.com.pe';
  }

  cargarVentas() {
    this.headerVentas = 'Ventas';
    this.iconoVentas = 'icon-yellow.png';
    this.bodyVentas = [
      { header: 'Meta', value: '$50,000.00', porc: '50', icon: 'far fa-money-bill-alt', class: 'card-ambar' },
      { header: 'Total Facturado', value: '$30,000.00', porc: '30', icon: 'far fa-money-bill-alt', class: 'card-green' },
      { header: 'Ventas totales', value: '$20,000.00', porc: '20', icon: 'far fa-money-bill-alt', class: 'card-green' }
    ];
    this.semaforoNaranja = '#FEB312';
    this.mailFromVentas = this.datosUsuario.DE_CORREO;
    this.mailToVentas = 'cmendoza@dest.com.pe';
  }

  cargarRentabilidad() {
    this.headerRentabilidad = 'Rentabilidad por Producto';
    this.iconoRentabilidad = 'icon-green-64.png';
    this.bodyRentabilidad = [
      { header: 'Meta', value: '$50,000.00', porc: '50', icon: 'far fa-money-bill-alt', class: 'card-ambar' },
      { header: 'Total Facturado', value: '$30,000.00', porc: '30', icon: 'far fa-money-bill-alt', class: 'card-green' },
      { header: 'Ventas totales', value: '$20,000.00', porc: '20', icon: 'far fa-money-bill-alt', class: 'card-green' }
    ];
    this.mailFromRentabilidad = this.datosUsuario.DE_CORREO;
    this.mailToRentabilidad = 'contabilidad@dest.com.pe';
  }

  cargarInventario() {
    this.headerInventario = 'Inventario';
    this.iconoInventario = 'icon-green-64.png';
    this.bodyInventario = [
      { header: 'Meta', value: '$50,000.00', porc: '50', icon: 'far fa-money-bill-alt', class: 'card-ambar' },
      { header: 'Total Facturado', value: '$30,000.00', porc: '30', icon: 'far fa-money-bill-alt', class: 'card-green' },
      { header: 'Ventas totales', value: '$20,000.00', porc: '20', icon: 'far fa-money-bill-alt', class: 'card-green' }
    ];
    this.mailFromInventario = this.datosUsuario.DE_CORREO;
    this.mailToInventario = 'inventario@dest.com.pe';
  }

  cargarImportacion() {
    this.headerImportacion = 'Importación';
    this.iconoImportacion = 'icon-green-64.png';
    this.bodyImportacion = [
      { header: 'Meta', value: '$50,000.00', porc: '50', icon: 'far fa-money-bill-alt', class: 'card-ambar' },
      { header: 'Total Facturado', value: '$30,000.00', porc: '30', icon: 'far fa-money-bill-alt', class: 'card-green' },
      { header: 'Ventas totales', value: '$20,000.00', porc: '20', icon: 'far fa-money-bill-alt', class: 'card-green' }
    ];
    this.mailFromImportacion = this.datosUsuario.DE_CORREO;
    this.mailToImportacion = 'inventario@dest.com.pe';
  }

  openMenu(indOpenMenu) {
    this.abrirMenu = indOpenMenu;
  }

}
