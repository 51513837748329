import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

eventEmitter = new EventEmitter<any>();

constructor() { }

emitEvent(event: any) {
  this.eventEmitter.emit(event);
}

}


