import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ConfiguracionService } from '../seguridad_api/configurations/configuracion.service';
import { map } from 'rxjs/operators';
import { getParamsForUrl } from 'src/app/component/util/Util';

@Injectable({
  providedIn: 'root'
})
export class ParametrosService {

  url: string;
  urlSegura: string;

  constructor(private http: HttpClient,
    private configuracion: ConfiguracionService) {
    this.urlSegura = this.configuracion.serverUrlApiSegura;
    this.url = this.configuracion.serverUrlApi;
  }

  public getTipoIndicadores(idUsuario: number): Observable<any[]> {
    let url = this.urlSegura + `/tipo-grafico?idUsuario=${idUsuario}`;
    return this.http.get<any[]>(url).pipe(
      map((response: any) => {
        return response.data;
      })
    );
  }

  public getCompania(params:any): Observable<any[]> {
    let url = this.urlSegura + `/unidad-operativa?${getParamsForUrl(params)}`;
    return this.http.get<any[]>(url).pipe(
      map((response: any) => {
        return response.data;
      })
    );
  }

  public getPeriodo(): Observable<any[]> {
    let url = this.urlSegura + `/periodos`;
    return this.http.get<any[]>(url).pipe(
      map((response: any) => {
        return response.data;
      })
    );
  }

  public obtenerParametro(param: string): Observable<any[]> {
    let url = this.url + `/obtener-parametro?coParam=${param}`;
    return this.http.get<any[]>(url).pipe(
      map((response: any) => {
        return response.data;
      })
    );
  }

  public archivo(file: any): Observable<any[]> {
    let url = this.url + `/archivos`;
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post<any>(url, formData).pipe(
      map((response: any) => {
        return response.data;
      })
    );
  }

  public obtenerParametroPorGrupo(params: any): Observable<any[]> {
    let url = this.url + `/obtener-parametro-por-grupo${getParamsForUrl(params)}`;
    return this.http.get<any[]>(url).pipe(
      map((response: any) => {
        return response.data;
      })
    );
  }
  
}
