import { Component, OnInit, EventEmitter, ViewChild, ViewEncapsulation } from '@angular/core';
import { NotificacionService } from 'src/app/services/notificacion/notificacion.service';
import { SelectItem, Message } from 'primeng/api';
import { ParametrosService } from 'src/app/services/general/parametros.service';
import { forkJoin } from 'rxjs';
import { Router } from '@angular/router';
import { LoadingService } from 'src/app/services/util/loading.service';
import { InformarService } from 'src/app/services/util/informar.service';
import { PrefPeriodoNotificacion, PrefHechoNotificacion } from 'src/app/constantes/preferencias.const';
import { PreferenciasService } from 'src/app/services/util/preferencias.service';
import { Preferencia } from 'src/app/model/preferencia.class';
import { NgxSpinnerService } from 'ngx-spinner';
import { Usuario } from 'src/app/model/seguridad/usuario';
import { UsuariosService } from 'src/app/services/seguridad/usuarios.service';
import { MaestroService } from 'src/app/services/maestros/maestro.service';
import { UsuarioLineaService } from 'src/app/services/seguridad/usuario-linea.service';
import { VentasService } from 'src/app/services/indicadores/ventas.service';
import { VendedoresService } from 'src/app/services/vendedores/vendedores.service';
import { CuentaPagarSolesComponent } from '../reportes/cuentas-por-pagar/grafico-cuentas-por-pagar/cuenta-pagar-soles/cuenta-pagar-soles.component';
import { CuentaPagarDolaresComponent } from '../reportes/cuentas-por-pagar/grafico-cuentas-por-pagar/cuenta-pagar-dolares/cuenta-pagar-dolares.component';
import { GraficoPeriodoVencidoComponent } from '../reportes/cuentas-cobrar/grafico-cuentas-por-pagar/periodos-vencidos/grafico-periodo-venc-soles.component';
import { GraficoDeudasVencidasComponent } from '../reportes/cuentas-cobrar/grafico-cuentas-por-pagar/deudas-vencidas/grafico-deudas-vencidas/grafico-deudas-vencidas.component';
import { OpcionPorRolGerencial } from 'src/app/model/seguridad/opciones-menu';
import { OpcionesRolGerencial } from 'src/app/constantes/seguridad/opcionesRol.constante';
import { MensajeAlertError } from 'src/app/component/util/message/UtilMessage';
import { LocationStrategy } from '@angular/common';
import { ConfiguracionService } from 'src/app/services/seguridad_api/configurations/configuracion.service';

@Component({
  selector: 'app-notificaciones',
  templateUrl: './notificaciones.component.html',
  styleUrls: ['./notificaciones.component.scss']
})
export class NotificacionesComponent implements OnInit {

  hecho: any;
  periodoNoti: any;

  opcionRolGerencial: any;

  hechoSelect: SelectItem[] = [];
  periodoSelect: SelectItem[] = [];
  //periodos = ["ENE", "FEB", "MAR", "ABR", "MAY", "JUN", "JUL", "AGO", "SEP", "OCT", "NOV", "DIC"];

  eventoEmitter: EventEmitter<any> = new EventEmitter();

  cols: any[];
  totalRecords: number = 0;
  urlEnlace: string;
  dataNotificaciones: any[];
  loading: boolean;

  permiso = false; //cambiar el permiso
  //cargandoInfo = true;

  graficos: any;
  responsiveOptions;

  /** datos usuario */
  datosUsuario: Usuario;
  username: string;

  /** Gráficos ventas */
  periodos: any = { "ENE": 0, "FEB": 1, "MAR": 2, "ABR": 3, "MAY": 4, "JUN": 5, "JUL": 6, "AGO": 7, "SEP": 8, "OCT": 9, "NOV": 10, "DIC": 11 };
  periodosArray = ["ENE", "FEB", "MAR", "ABR", "MAY", "JUN", "JUL", "AGO", "SEP", "OCT", "NOV", "DIC"];
  dataGrafico: any;
  options: any;
  visualizarGrafico = false;
  msgs: Message[] = [];
  lineaVentas: any;
  companiaGrafico: any;
  periodoVentas: any;
  vendedorVentas: any;
  listaVendedores: any[] = [];
  numeroPeriodos = 6;

  visualizarIndicadores = false;

  @ViewChild("table", { static: true }) table: any;
  @ViewChild('cuentaPagarSoles') cuentaPagarSoles: CuentaPagarSolesComponent;
  @ViewChild('cuentaPagarDolares') cuentaPagarDolares: CuentaPagarDolaresComponent;
  @ViewChild('graficoPeriodosVencidos') graficoPeriodoVencido: GraficoPeriodoVencidoComponent;
  @ViewChild('graficoDeudaPorCliente') graficoDeudaPorCliente: GraficoDeudasVencidasComponent;

  constructor(private notificacionService: NotificacionService,
    private parametroService: ParametrosService,
    private router: Router,
    private loadingService: LoadingService,
    private spinner: NgxSpinnerService,
    private preferenciaService: PreferenciasService,
    private usuarioService: UsuariosService,
    private maestroService: MaestroService,
    private usuarioLineaService: UsuarioLineaService,
    private ventasService: VentasService,
    private vendedoresService: VendedoresService,
    private readonly locationStrategy: LocationStrategy,
    private configuracion: ConfiguracionService
    ) { this.spinner.show(); }

  ngOnInit() {
    this.username = sessionStorage.getItem('SESSIONUSER');
    this.urlEnlace = this.configuracion.ipRecursos.concat(this.configuracion.contextoRecurso).concat('/');
    this.opcionRolGerencial = OpcionesRolGerencial;
    this.subscribeEvents();
    this.createTable();
    this.obtenerDatosUsuario();
    this.showInfo();
  }

  cambioCia(event:any):void {
    console.log('cambiocia-notif', event);
  }

  createEstructuraGrafico(listaGrafico: any[]) {
    this.responsiveOptions = [
      {
        breakpoint: '1024px',
        numVisible: 3,
        numScroll: 3
      },
      {
        breakpoint: '768px',
        numVisible: 2,
        numScroll: 2
      },
      {
        breakpoint: '560px',
        numVisible: 1,
        numScroll: 1
      }
    ];
    this.graficos = ((listaGrafico) && listaGrafico.length > 0) ? listaGrafico : [];
  }

  obtenerDatosUsuario() {
    this.spinner.show();
    this.usuarioService.obtenerUsuarioSeguro(this.username).subscribe({
      next: (usuario: Usuario) => {
        if ((usuario)) {
          this.datosUsuario = usuario;
          this.obtenerOpcionesPorRolGerencial();
        } else {
          this.spinner.hide();
        }
      }
    });
  }

  /** obtener los permisos para visualizar gráficos y reportes */
  obtenerOpcionesPorRolGerencial() {
    if ((this.datosUsuario)) {
      this.spinner.show();
      this.usuarioService.obtenerOpcionesPorRolGerencial(this.datosUsuario.ID_USUA).subscribe({
        next: (opcionRolGerencial: OpcionPorRolGerencial[]) => {
          //console.log('opcionRolGerencial',opcionRolGerencial);
          if ((opcionRolGerencial)) {
            var listaGrafico: any[] = [];
            var listaReporte: any[] = [];
            opcionRolGerencial.forEach(rg => {
              if (rg.DE_OPCN.toUpperCase() == OpcionesRolGerencial.indicadorVenta.toUpperCase()) {
                listaGrafico.push({ type: OpcionesRolGerencial.indicadorVenta, link: rg.DE_PATH_OPCN });
                this.visualizarIndicadores = true;
              } if (rg.DE_OPCN.toUpperCase() == OpcionesRolGerencial.reporteCuentaPorPagar.toUpperCase()) {
                listaGrafico.push({ type: OpcionesRolGerencial.reporteCuentaPorPagarSoles, link: rg.DE_PATH_OPCN });
                listaGrafico.push({ type: OpcionesRolGerencial.reporteCuentaPorPagarDolares, link: rg.DE_PATH_OPCN });
                this.visualizarIndicadores = true;
              } if (rg.DE_OPCN.toUpperCase() == OpcionesRolGerencial.reporteCuentaPorCobrar.toUpperCase()) {
                listaGrafico.push({ type: OpcionesRolGerencial.reportePeriodosVencido, link: rg.DE_PATH_OPCN });
                listaGrafico.push({ type: OpcionesRolGerencial.reporteDeudasVencido, link: rg.DE_PATH_OPCN });
                this.visualizarIndicadores = true;
              // } if (rg.DE_OPCN.toUpperCase() == OpcionesRolGerencial.reporteCostoVenta.toUpperCase()) {
              //   listaReporte.push({ title: rg.DE_OPCN, icon: 'fas fa-coins', background: '#d7ecfb', border: '1px solid #3fa6ec', link: rg.DE_PATH_OPCN });
              //   this.visualizarReportes = true;
              // } if (rg.DE_OPCN.toUpperCase() == OpcionesRolGerencial.reporteCuentaLineaCredito.toUpperCase()) {
              //   listaReporte.push({ title: rg.DE_OPCN, icon: 'fas fa-clipboard-list', background: '#dbf2f2', border: '1px solid', link: rg.DE_PATH_OPCN });
              //   this.visualizarReportes = true;
              // }
              }
            });
          }
          this.createEstructuraGrafico(listaGrafico);
          //this.createEstructuraReportes(listaReporte);
          this.obtenerCombos();
        }
      });
    } else {
      MensajeAlertError();
    }
  }

  subscribeEvents() {
    this.notificacionService.change.subscribe(refrescar => {
      if (refrescar) {
        const periodo = (this.periodoNoti.ID_PRDO) ? this.periodoNoti.ID_PRDO.split('|')[0] : '';
        this.obtenerNotificacion(this.username, periodo, this.hecho.ID);
      }
    });

    this.eventoEmitter.subscribe((evento) => {
      const periodo = (this.periodoNoti.ID_PRDO) ? this.periodoNoti.ID_PRDO.split('|')[0] : '';
      this.obtenerNotificacion(evento.usuario, periodo, evento.hecho.ID);
    });
  }

  createTable() {
    this.cols = [
      //{ field: 'USUARIO', chield: 'CO_USUA', header: 'Usuario', type: 'multi', filter: false, width: "15%" },
      { field: 'CO_USUA', header: 'Usuario', type: 'center', filter: false, width: "15%" },
      { field: 'FE_CREA', header: 'Fecha y Hora', type: 'date', filter: false, width: "15%" },
      { field: 'DE_NOTI', header: 'Notificación', link: 'DE_PRMT_01', type: 'link', filter: false, width: "60%" },
      { field: 'IN_ESTA_HCHO', header: '¿Hecho?', type: 'hecho', filter: false, width: "10%" }
    ];
  }

  obtenerCombos() {
    let preferenciaPeriodo = this.preferenciaService.obtenerPreferencias(this.username, '/notificacion', 'PERIODO');
    let preferenciaHecho = this.preferenciaService.obtenerPreferencias(this.username, '/notificacion', 'HECHO');
    let getPeriodo = this.parametroService.getPeriodo();
    let compania = this.maestroService.obtenerUnidadOperativa(this.datosUsuario.ID_USUA);
    let lineaPorUsuario = this.usuarioLineaService.obtenerLineaPorUsuario(this.datosUsuario.ID_USUA);
    forkJoin([getPeriodo, preferenciaPeriodo, preferenciaHecho, compania, lineaPorUsuario])
      .subscribe(([periodoResponse, preferenciaPeriodo, preferenciaHecho, companiaResponse, lineaPorUsuarioResponse]: [any, Preferencia, Preferencia, any, any]) => {
        this.periodoSelect = periodoResponse;
        const hechoArray: any[] = [{ "ID": "", "DE_HECHO": "(Todos)" }, { "ID": "0", "DE_HECHO": "No" }, { "ID": "1", "DE_HECHO": "Si" }];
        this.hechoSelect = hechoArray;

        let prefHecho;
        if (preferenciaHecho && preferenciaHecho != null) {
          prefHecho = (preferenciaHecho.DE_VALO_PREF) ? preferenciaHecho.DE_VALO_PREF : null;
          if (prefHecho && prefHecho != null) {
            prefHecho = JSON.parse(prefHecho);
          }
        }

        if ((prefHecho)) {
          this.hecho = prefHecho;
        } else {
          this.hecho = this.hechoSelect[0];
        }

        let prefPeriodo;
        if (preferenciaPeriodo && preferenciaPeriodo != null) {
          prefPeriodo = (preferenciaPeriodo.DE_VALO_PREF) ? preferenciaPeriodo.DE_VALO_PREF : null;
          if (prefPeriodo && prefPeriodo != null) {
            prefPeriodo = JSON.parse(prefPeriodo);
          }
        }

        if ((prefPeriodo)) {
          this.periodoNoti = prefPeriodo;
        } else {
          this.periodoNoti = this.periodoSelect[this.periodoSelect.length - 1];
        }

        //this.cargandoInfo = false;
        const periodo = (this.periodoNoti.ID_PRDO) ? this.periodoNoti.ID_PRDO.split('|')[0] : '';
        this.obtenerNotificacion(this.username, periodo, this.hecho.ID);
        //evento
        this.loadingService.informarLoading({ status: true, evento: 'notificacion' });

        /** Guardar preferencia periodo */
        this.guardarPreferenciarPeriodo(this.periodoNoti);
        /** Guardar preferencia hecho */
        this.guardarPreferenciaHecho(this.hecho);

        /*.filter((prd: any) => {
          if ((this.periodos.indexOf(prd.DE_NOMB_PRDO.split('-')[0]) == 0) &&
            (prd.DE_NOMB_PRDO.split('-')[1] == new Date().getFullYear().toString().substring(2, 4))) {
            return prd;
          } else { return false; }
        })[0];*/

        /** Grafico ventas */
        if (this.visualizarIndicadores) {
          var listaCompaniaVentas = (companiaResponse) ? companiaResponse : [];
          var listaPeriodoVentas = (periodoResponse) ? periodoResponse : [];
          var listaLineaVentas = (lineaPorUsuarioResponse) ? lineaPorUsuarioResponse : [];
          
          //console.log('listaCompaniaVentas', listaCompaniaVentas);
          this.companiaGrafico = (listaCompaniaVentas) ? listaCompaniaVentas[0] : null;

          this.periodoVentas = (listaPeriodoVentas) ? listaPeriodoVentas[listaPeriodoVentas.length - 1] : null;
          this.lineaVentas = (listaLineaVentas) ? listaLineaVentas[0] : null;
          this.obtenerVendedores();
          this.emitirEventoGraficos();
        }

        this.spinner.hide();
      })
  }

  ngOnDestroy(): void {
    //localStorage.removeItem('data-header');
  }

  accederEnlace(url: string) {
    
    if ((url)) {
      
      if (url.indexOf('@') >= 0) {
        const path = url.split('@')[1];
        
        if ((path) && path.indexOf('?') >= 0) {
          const appLink = this.getWebServerRelativeUrl().split('/')[1];
          const routerLink = appLink+"/"+path.split('?')[0];
          const idProceso = path.split('?')[1];
          if ((routerLink)) {
            localStorage.setItem('notificacion.id', idProceso);
            this.router.navigateByUrl(routerLink);
          }
        }
      } else {
        window.open(url, "_blank");
      }
    }

  }

  public getWebServerRelativeUrl(): string {
    if (window
      && "location" in window
      && "pathname" in window.location) {
      return  window.location.pathname.replace(/\/$/, "");
    }
    return null;
  }


  obtenerNotificacion(codUsuario: any, periodo: any, hecho: any) {
    //this.spinner.show();
    this.loading = true;
    this.dataNotificaciones = [];
    this.notificacionService.obtenerNotificaciones(codUsuario, periodo, hecho).subscribe({
      next: (response: any) => {
        if (this.table && this.table != null) {
          this.table.dt.reset();
        }
        if ((response) && response.length > 0) {          
          this.dataNotificaciones = response;
        } else {
          this.dataNotificaciones = [];
        }
        this.loading = false;
        this.spinner.hide();
      }
    })
  }

  selectCombos() {
    this.guardarPreferenciarPeriodo(this.periodoNoti);
    this.guardarPreferenciaHecho(this.hecho);
    const periodo = (this.periodoNoti.ID_PRDO) ? this.periodoNoti.ID_PRDO.split('|')[0] : '';
    this.obtenerNotificacion(this.username, periodo, this.hecho.ID);
  }

  guardarPreferenciarPeriodo(periodo) {
    const prefPeriodoNoti = new PrefPeriodoNotificacion();
    prefPeriodoNoti.preferencia.CO_USUA = this.username;
    prefPeriodoNoti.preferencia.DE_VALO_PREF = JSON.stringify(periodo);
    this.preferenciaService.guardarPreferencia(prefPeriodoNoti.preferencia).subscribe();
  }

  guardarPreferenciaHecho(hecho) {
    const prefHechoNoti = new PrefHechoNotificacion();
    prefHechoNoti.preferencia.CO_USUA = this.username;
    prefHechoNoti.preferencia.DE_VALO_PREF = JSON.stringify(hecho);
    this.preferenciaService.guardarPreferencia(prefHechoNoti.preferencia).subscribe();
  }

  regresar() {
    this.router.navigateByUrl('/phoenix/dashboard');
  }

  /** Gráficos Ventas*/
  obtenerVendedores() {
    const idUnidOper = (this.companiaGrafico) ? this.companiaGrafico.ID_ORGN_EBS : '';
    let coTipoLnea = '';

    coTipoLnea = (this.lineaVentas) ? this.lineaVentas.CO_TIPO_LNEA : '';
    
    if (idUnidOper.toString().toUpperCase() == "201".toUpperCase()) {
      coTipoLnea = 'I';
    }

    this.vendedoresService.obtenerVendPorUsuarioLinea(this.datosUsuario.ID_USUA, coTipoLnea, idUnidOper).subscribe({
      next: (result: any) => {
        let vendedores: any[] = [];
        if ((result) && result.length > 0) {
          vendedores = result;
        }

        if (vendedores && vendedores != null && vendedores.length > 0) {
          vendedores.splice(0, 0, { 'DE_NOMB_VEND': 'TODOS', 'ID_VEND': 0 });
        } else {
          vendedores.splice(0, 0, { 'DE_NOMB_VEND': 'DATA NOT FOUND', 'ID_VEND': '' });
        }
        this.listaVendedores = vendedores;
        this.vendedorVentas = (this.listaVendedores) ? this.listaVendedores[0] : null;
        this.obtenerInformacionVentas();
      }
    })
  }

  obtenerInformacionVentas() {
    this.spinner.show();
    this.loading = true;

    const idUnidOper = (this.companiaGrafico) ? this.companiaGrafico.ID_ORGN_EBS : '';
    const periodo = (this.periodoVentas) ? this.periodoVentas.DE_NOMB_PRDO : '';
    let vendedores = '';
    if ((this.vendedorVentas) && this.vendedorVentas.ID_VEND == 0) {
      let cont = 0;
      this.listaVendedores.filter(p => p.ID_VEND != 0).forEach(v => {
        if (cont > 0) {
          vendedores += ',' + v.ID_VEND;
        } else {
          vendedores += v.ID_VEND;
        }
        cont++;
      })
    } else {
      vendedores = this.vendedorVentas.ID_VEND;
    }
    const tipoLnea = (this.lineaVentas) ? this.lineaVentas.CO_TIPO_LNEA : '';

    if ((this.table)) { this.table.dt.reset(); }
    const ventas = this.ventasService.obtenerVentas(periodo, idUnidOper, vendedores, tipoLnea);
    forkJoin(ventas).subscribe(([ventasResponse]: [any]) => {
      this.generarGrafico(ventasResponse);
      this.spinner.hide();
    });
  }

  generarGrafico(data: any) {
    if (data && data != null && data.length > 0) {
      this.visualizarGrafico = true;
      var lbls: any = Array.from(new Set(data.filter(p => p.PERIODO_INICIO != null).map(a => a.PERIODO_INICIO)).values());

      if ((lbls) && lbls.length == 6) {
      } else {
        //validar el mes faltante 
        var date = this.periodoVentas.DE_NOMB_PRDO;//new Date();
        var mes = this.periodoVentas.NU_PRDO_EBS;//date.getMonth();
        let mesNumber = mes;
        let periodo = date;//date.getFullYear().toString().substring(2, 4);
        let periodoUltimo = null;
        for (let i = 0; i < 6; i++) {
          var existe = lbls.filter(l => String(l).toLowerCase().indexOf(String(this.periodosArray[mesNumber - 1]).toLowerCase()) == 0)[0];
          if (!(existe)) {
            var periodoString = !(periodoUltimo) ? this.periodoVentas.DE_NOMB_PRDO :
              String(this.periodosArray[mesNumber - 1]).concat("-" + String((periodoUltimo) ? periodoUltimo : periodo));
            lbls.push(periodoString);
            periodoUltimo = (periodoString) ? periodoString.split('-')[1] : null;
          } else {
            periodoUltimo = (existe) ? existe.split('-')[1] : null;
          }

          if ((mesNumber - 1) == 0) mesNumber = 12;
          else mesNumber--;
        }
      }

      lbls = lbls.filter((p: any) => p != null).sort((s, v) => {
        const mes = s.split('-')[0];
        const anio = s.split('-')[1];
        const mesVersion = v.split('-')[0];
        const anioVersion = v.split('-')[1];
        if (anio == anioVersion) {
          if (this.periodos[mes] < this.periodos[mesVersion]) {
            return -1;
          } else {
            return 1;
          }
        } else if (anio < anioVersion) {
          return -1;
        }
      })

      let arregloMontos: any[] = [];
      lbls.forEach((x: any) => {
        let montos = data.filter(p => p.PERIODO_INICIO == x).reduce((acum, valor) => {
          return (parseFloat(acum) + parseFloat(valor.MONTO_DOLARES));
        }, 0);
        arregloMontos.push(montos);
      });

      this.dataGrafico = {
        labels: lbls,
        datasets: [
          {
            label: 'Ventas',
            data: arregloMontos,
            fill: false,
            backgroundColor: 'rgba(54, 162, 235, 0.2)',
            borderColor: 'rgba(54, 162, 235, 1)',
            borderWidth: 1
          }
        ]
      }
      this.options = {
        responsive: true,
        tooltips: {
          mode: 'label',
          showTooltips: true,
          callbacks: {
            label: function (tooltipItem, data) {
              return data.datasets[tooltipItem.datasetIndex].label + ": " + "S/." + data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].toLocaleString('en-PE', { style: 'currency', currency: 'PEN' }).split('PEN')[1];
            }
          }
        }
      }
    } else {
      this.dataGrafico = {};
      this.showInfo();
    }
  }

  showInfo() {
    this.msgs = [];
    this.visualizarGrafico = false;
    this.msgs.push({ severity: 'info', summary: 'Información Gráfico', detail: 'No se encontró información para visualizar el gráfico de Ventas' });
  }

  emitirEventoGraficos() {
    if ((this.cuentaPagarSoles)) {
      this.cuentaPagarSoles.eventoEmitter.emit(this.companiaGrafico);
    }
    if ((this.cuentaPagarSoles)) {
      this.cuentaPagarDolares.eventoEmitter.emit(this.companiaGrafico);
    }
    if ((this.graficoPeriodoVencido)) {
      //this.graficoPeriodoVencido.eventoEmitter.emit(this.companiaGrafico);
    }
    if ((this.graficoDeudaPorCliente)) {
      this.graficoDeudaPorCliente.eventoEmitter.emit(this.companiaGrafico);
    }
  }

}
