<div class="wrapper fadeInDown">
  <div id="formContent">
    <p-card header="Cambio de Contraseña" subheader="Ingresar los datos solicitados"
      [style]="{width: '370px'}" styleClass="ui-card-shadow card-style">
      <p-header style="text-align: center">
        <img class="center" src="Card" src="./../../../../assets/icons/icon-cambio-contrasena.png" id="icon"
          alt="Cambio Contraseña" style="width: 30%; height: 30%;" />
      </p-header>
      <form [formGroup]="resetPasswordForm" (ngSubmit)="cambiarContrasena()">
        <div class="ui-g ui-fluid">
          <div class="ui-g-12 ui-md-12">
            <div class="ui-g form-group" *ngIf="accesoInterno">
              <label for="currentPassword" class="ui-g-12 ui-md-12"><strong>Ingrese Contraseña Actual</strong></label>
            </div>
            <div class="ui-g form-group" *ngIf="accesoInterno">
              <div class="ui-g-12 ui-md-12">
                <input pPassword formControlName="currentPassword" maxlength="50" type="password" [promptLabel]="lbl"
                  [weakLabel]="weakLbl" [mediumLabel]="mediumLbl" [strongLabel]="strongLbl"
                  (change)="verificarContrasenaActual()" />
                <div *ngIf="!contrasenaValida && contrasenaIngresada">
                  <p-message [style]="{'width':'100%'}" severity="error" text="La contraseña no es válida"></p-message>
                </div>
                <div *ngIf="contrasenaValida && contrasenaIngresada">
                  <p-message [style]="{'width':'100%'}" severity="success" text="La contraseña es válida"></p-message>
                </div>
              </div>
            </div>
            <div class="ui-g form-group">
              <label for="newPassword" class="ui-g-12 ui-md-12"><strong>Ingrese Contraseña Nueva</strong></label>
            </div>
            <div class="ui-g form-group">
              <div class="ui-g-12 ui-md-12">
                <input pPassword formControlName="password" maxlength="50" type="password" [promptLabel]="lbl"
                  [weakLabel]="weakLbl" [mediumLabel]="mediumLbl" [strongLabel]="strongLbl"
                  [attr.disabled]="(!contrasenaValida && contrasenaIngresada) || (!contrasenaIngresada) ? 'disabled': null" />
              </div>
            </div>
            <div class="ui-g form-group">
              <label for="reviewNewPassword" class="ui-g-12 ui-md-12"><strong>Vuelva a Ingresar Contraseña
                  Nueva</strong></label>
            </div>
            <div class="ui-g form-group">
              <div class="ui-g-12 ui-md-12">
                <input pPassword formControlName="confirmPassword" maxlength="50" type="password" [promptLabel]="lbl"
                  [weakLabel]="weakLbl" [mediumLabel]="mediumLbl" [strongLabel]="strongLbl"
                  [attr.disabled]="(!contrasenaValida && contrasenaIngresada) || (!contrasenaIngresada) ? 'disabled': null" />
              </div>
            </div>
            <br>
            <div class="ui-g form-group">
              <button pButton *ngIf="!accesoInterno" type="submit" label="Cambiar Password" icon="pi pi-check"
                style="margin-right: .50em; margin-left: .50em" [disabled]="!resetPasswordForm.valid"></button>
              <button pButton *ngIf="accesoInterno" type="button" (click)="cambiarContrasenaInterno()"
                label="Cambiar Password" icon="pi pi-check" style="margin-right: .50em; margin-left: .50em"
                [disabled]="!resetPasswordForm.valid || !contrasenaValida"></button>
            </div>
            <br>
            <div class="ui-g form-group" *ngIf="accesoInterno">
              <button pButton type="submit" label="Cancelar" class="ui-button-raised ui-button-danger"
                style="margin-right: .50em; margin-left: .50em" (click)="cancelarCambioContrasena()"></button>
            </div>
          </div>
        </div>
      </form>
      <p-footer *ngIf="!accesoInterno">
        <a [routerLink]="['/']">Volver al inicio de sesión</a>
      </p-footer>
    </p-card>
  </div>
</div>