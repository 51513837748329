<p-toast [style]="{marginTop: '80px'}" position="top-left" key="tl"></p-toast>
<app-header #header (menuEmitter)="openMenu($event)"></app-header>
<app-menu *ngIf="!abrirMenu"></app-menu>
<br>
<div class="container-dash">
    <div class="p-grid container-cards" style="float: center">
        <div class="p-col-12 p-md-12 p-lg-6 p-xl-4" *ngIf="permiso">
            <app-cards #cardCPP [tipoCard]="1" [header]="headerCuentasPorPagar" [icono]="iconoCuentasPorPagar"
                [bodyCard]="bodyCuentasPorPagar" [ruta]="ruta" [valor]="valorCardCPP" [semaforo]="semaforoRojo"
                [mailFrom]="mailFromCPP" [mailTo]="mailToCpp" [viewDetalle]="true"></app-cards>
        </div>

        <div class="p-col-12 p-md-12 p-lg-6 p-xl-4" *ngIf="permiso">
            <app-cards #cardCPC [tipoCard]="2" [header]="headerCuentasPorCobrar" [icono]="iconoCuentasPorCobrar"
                [bodyCard]="bodyCuentasPorCobrar" [ruta]="ruta" [valor]="valorCardCPC" [semaforo]="semaforoVerde"
                [mailFrom]="mailFromCPC" [mailTo]="mailToCpc" [viewDetalle]="true">
            </app-cards>
        </div>

        <div class="p-col-12 p-md-12 p-lg-6 p-xl-4" *ngIf="permiso">
            <app-cards #cardVentas [tipoCard]="3" [header]="headerVentas" [icono]="iconoVentas" [bodyCard]="bodyVentas"
                [ruta]="ruta" [valor]="valorCardVenta" [semaforo]="semaforoNaranja" [mailFrom]="mailFromVentas"
                [mailTo]="mailToVentas" [viewDetalle]="true">
            </app-cards>
        </div>

        <div class="p-col-12 p-md-12 p-lg-6 p-xl-4" *ngIf="permiso">
            <app-cards #cardRentabilidad [tipoCard]="1" [header]="headerRentabilidad" [icono]="iconoRentabilidad"
                [bodyCard]="bodyRentabilidad" [ruta]="ruta" [valor]="valorCardRentabilidad" [semaforo]="semaforoRojo"
                [mailFrom]="mailFromCPP" [mailTo]="mailToRentabilidad" [viewDetalle]="false"></app-cards>
        </div>

        <div class="p-col-12 p-md-12 p-lg-6 p-xl-4" *ngIf="permiso">
            <app-cards #cardInventario [tipoCard]="2" [header]="headerInventario" [icono]="iconoInventario"
                [bodyCard]="bodyInventario" [ruta]="ruta" [valor]="valorCardInventario" [semaforo]="semaforoVerde"
                [mailFrom]="mailFromInventario" [mailTo]="mailToInventario" [viewDetalle]="true">
            </app-cards>
        </div>

        <div class="p-col-12 p-md-12 p-lg-6 p-xl-4" *ngIf="permiso">
            <app-cards #cardImportacion [tipoCard]="3" [header]="headerImportacion" [icono]="iconoImportacion" [bodyCard]="bodyImportacion"
                [ruta]="ruta" [valor]="valorCardImportacion" [semaforo]="semaforoNaranja" [mailFrom]="mailFromImportacion"
                [mailTo]="mailToImportacion" [viewDetalle]="true">
            </app-cards>
        </div>
    </div>
</div>