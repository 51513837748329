import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  currentUrl: any;

  constructor(private location: Location, private meta: Meta) {
    this.meta.addTag({
      name: 'Cache-Control',
      content: 'no-cache, must-revalidate, post-check=0, pre-check=0'
    });
    this.meta.addTag({
      name: 'Expires',
      content: '0'
    });
    this.meta.addTag({
      name: 'Pragma',
      content: 'no-cache'
    });
  }


  ngOnInit(): void {
    //console.log('ngOnInit');
    //this.route.url.subscribe(url => console.log('URL actual:', url));
    this.location.onUrlChange(url => {
      //console.log('Current URL:', url)
      this.currentUrl = url;
    });
  }
}
