export const environment = {
  production: false,
  ipRecursos: 'https://phoenix-qa.belatec.pe',
  //local -> ipRecursos: 'http://phoenix-desa.belatec.pe',
  portRecursos: ':443',
  //local -> portRecursos: ':8084' 
  apiUrl:'https://phoenix-qa.belatec.pe', //servidor pruebas
  //apiUrl: 'http://localhost', //local
  port: ':443'
  //port: ':3000'
};

