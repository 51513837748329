import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfiguracionService } from '../seguridad_api/configurations/configuracion.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UsuarioLineaService {

  url: string;

  constructor(private http: HttpClient,
    private configuracion: ConfiguracionService) {
    this.url = this.configuracion.serverUrlApiSegura;
  }

  obtenerLineaPorUsuario(usuario: any): Observable<any> {
    const url = `${this.url}/linea-por-usuario?idUsuario=${usuario}`;
    return this.http.get<any>(url).pipe(
      map((response: any) => {
        return response.data;
      })
    )
  }

}
