import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ConfiguracionService } from '../seguridad_api/configurations/configuracion.service';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {

  url: string;
  urlSegura: string;
  constructor(private http: HttpClient,
    private configuracion: ConfiguracionService) {
    this.url = this.configuracion.serverUrlApi;
    this.urlSegura = this.configuracion.serverUrlApiSegura;
  }

  public leerJson(): Observable<any> {
    return this.http.get<any>('./assets/archivo.json').pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  public accesoLogin(credenciales: any): Observable<any> {
    let url = this.url + `/acceso-login`;
    return this.http.post<any[]>(url, credenciales).pipe(
      map((response: any) => {
        return response.data;
      })
    );
  }

  public login(credenciales: any): Observable<any> {
    let url = this.url + `/login`;
    return this.http.post<any[]>(url, credenciales).pipe(
      map((response: any) => {
        return response.data;
      })
    );
  }

  public generarTokenAuth(): Observable<any> {
    let url = this.url + `/generarToken`;
    return this.http.get<any>(url).pipe(
      map((response: any) => {
        if (response.data["Set-Cookie"]) {
          sessionStorage.setItem('token_keyboard_encripted', response.data["Set-Cookie"]);
        }
      }), catchError(err => {
        return throwError("User defined error.");
      })
    );
  }

  public generarTokenAuthForMail(codUsuario: string): Observable<any> {
    let url = this.url + `/generar-token-envio-correo?usuario=${codUsuario}`;
    return this.http.get<any>(url).pipe(
      map((response: any) => {
        return response.data["Set-Cookie"];
      })
    );
  }

  public cambiarPassword(passwordForm: any, token: string): Observable<any> {
    let body = {
      passwordForm,
      token
    }
    let url = this.url + `/cambiar-password`;
    return this.http.put<any[]>(url, body).pipe(
      map((response: any) => {
        return response.data;
      })
    );
  }

  public actuaIndCambioPassword(datosUsuario: any, indCambioPassword: number): Observable<any> {
    let body = {
      datosUsuario,
      indCambioPassword
    }
    let url = this.url + `/indicador-cambio-password`;
    return this.http.put<any[]>(url, body).pipe(
      map((response: any) => {
        return response.data;
      })
    );
  }

  public verificarPassword(username: string, password: string): Observable<any> {
    let body = {
      username,
      password
    }
    let url = this.urlSegura + `/verificar-password`;
    return this.http.put<any>(url, body).pipe(
      map((response: any) => {
        return response.data;
      })
    );;
  }

  public cambiarPasswordInterno(passwordForm: any, username: string): Observable<any> {
    let body = {
      passwordForm,
      username
    }
    let url = this.urlSegura + `/cambiar-password-interno`;
    return this.http.put<any[]>(url, body).pipe(
      map((response: any) => {
        return response.data;
      })
    );
  }

  public finalizarSesion(codUsuario: any): Observable<any> {
    let usuario: any = new Object();
    usuario.ID_SESN = null;
    let url = this.urlSegura + `/usuarios/finalizar-session/${codUsuario}`;
    return this.http.put<any[]>(url, usuario).pipe(
      map((response: any) => {
        return response.data;
      })
    );
  }

  public cerrarSesion(codTipo: any, keySession: any, codUsuario: String): Observable<any> {
    let session: any = new Object();
    session.coTipo = codTipo;
    session.idSession = keySession;
    let url = this.urlSegura + `/usuarios/cerrar-session/${codUsuario}`;
    return this.http.put<any[]>(url, session).pipe(
      map((response: any) => {
        return response.data;
      })
    );
  }

  public finalizarSesionSinSeguridad(codUsuario: any): Observable<any> {
    let usuario: any = new Object();
    usuario.ID_SESN = null;
    let url = this.url + `/finalizar-session/${codUsuario}`;
    return this.http.put<any[]>(url, usuario).pipe(
      map((response: any) => {
        return response.data;
      })
    );
  }

  public cerrarSesionSinSeguridad(codTipo: any, keySession: any, codUsuario: String): Observable<any> {
    let session: any = new Object();
    session.coTipo = codTipo;
    session.idSession = keySession;
    let url = this.url + `/cerrar-session/${codUsuario}`;
    return this.http.put<any[]>(url, session).pipe(
      map((response: any) => {
        return response.data;
      })
    );
  }

}
