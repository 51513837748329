import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { forkJoin } from 'rxjs';
import { Mail } from 'src/app/model/mail.class';
import { MailService } from 'src/app/services/general/mail.service';
import { UsuariosService } from 'src/app/services/seguridad/usuarios.service';
import { MessageService } from 'primeng/api';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-mail-modal',
  templateUrl: './mail-modal.component.html',
  styleUrls: ['./mail-modal.component.scss']
})
export class MailModalComponent implements OnInit {

  @Input() header: string;
  @Input() displayMail: boolean;
  @Input() mailFrom: string;
  @Input() mailTo: string;

  @Output() closeModal: EventEmitter<any> = new EventEmitter();

  mensajeTexto: string;
  usuario: string;
  asunto: string;

  constructor(private mailService: MailService,
    private usuarioService: UsuariosService,
    private messageService: MessageService,
    private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.usuario = sessionStorage.getItem('SESSIONUSER');
  }

  enviarMail() {
    this.spinner.show();
    this.closeModal.emit(!this.displayMail);
    const _this = this;
    const secuencialMail = this.mailService.secuencialMail();
    const datosUsuario = this.usuarioService.obtenerUsuarioSeguro(this.usuario);
    forkJoin(secuencialMail, datosUsuario)
      .subscribe(([secuencialMail, datosUsuario]: [any, any]) => {
        let mail: Mail = new Mail();
        mail.ID_SEC = secuencialMail.secuencial + 1;
        mail.CO_PROC = 'REPORTE_MAIL';
        mail.DE_MAIL_FROM = _this.mailFrom;
        mail.DE_MAIL_TO = _this.mailTo;
        mail.DE_MAIL_CC = '';
        mail.CO_SPRD = '';
        mail.DE_ASNT = _this.asunto;
        mail.DE_MSJE = _this.mensajeTexto;
        mail.DE_ARCH = '';
        mail.BI_ARCH = null;
        mail.CO_TIPO_MAIL = '2';
        mail.ID_ESTA = 10;
        mail.CO_USUA_CREA = datosUsuario.CO_USUA;
        mail.FE_CREA = new Date();
        mail.IP_TERM_CREA = ''
        mail.CO_USUA_MODI = '';
        mail.FE_MODI = null;
        mail.IP_TERM_MODI = '';

        _this.mailService.registrarMail(mail).subscribe({
          next(response: any) {
            if (response && response != null && response.status == "200") {   
              _this.messageService.add({ severity: 'success', summary: 'Service Message Phoenix', detail: `Se ha enviado el correo a ${_this.mailTo}` });
            } else {
              _this.messageService.add({ severity: 'error', summary: 'Service Message Phoenix', detail: 'Error al registrar el correo de recuperación de contraseña' });
            }
            _this.mensajeTexto = '';
            _this.spinner.hide();
          }
        })
      });
  }

  cancelarModalMail() {
    this.mensajeTexto = '';
    this.closeModal.emit(!this.displayMail);
  }

}
