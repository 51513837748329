import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfiguracionService } from '../seguridad_api/configurations/configuracion.service';
import { Usuario, ResponseUsuario } from 'src/app/model/seguridad/usuario';
import { ResponseOpcionRolGerencial, OpcionPorRolGerencial } from 'src/app/model/seguridad/opciones-menu';

@Injectable({
  providedIn: 'root'
})
export class UsuariosService {

  openSocket: EventEmitter<any> = new EventEmitter();
  closeSocket: EventEmitter<any> = new EventEmitter();

  url: string;
  urlSeguro: string;
  constructor(private http: HttpClient,
    private configuracion: ConfiguracionService) {
    this.url = this.configuracion.serverUrlApi;
    this.urlSeguro = this.configuracion.serverUrlApiSegura + "/usuario";
  }

  public obtenerUsuario(codUsuario: string): Observable<Usuario> {
    var url = this.url + `/obtener-usuario?codUsuario=${codUsuario}`;
    return this.http.get<ResponseUsuario>(url).pipe(
      map((response: ResponseUsuario) => {
        return response.data;
      })
    );
  }

  public obtenerUsuarioSeguro(codUsuario: string): Observable<Usuario> {
    var url = this.urlSeguro + `/obtener-usuario?codUsuario=${codUsuario}`;
    return this.http.get<ResponseUsuario>(url).pipe(
      map((response: ResponseUsuario) => {
        return response.data;
      })
    );
  }

  public obtenerOpcionesPorRolGerencial(usuario: number): Observable<OpcionPorRolGerencial[]> {
    var url = this.urlSeguro + `/opciones-rol-gerencial/${usuario}`;
    return this.http.get<ResponseOpcionRolGerencial>(url).pipe(
      map((response: ResponseOpcionRolGerencial) => {
        return response.data;
      })
    );
  }

  public obtenerUsuarioComprador(idUsua: number, idUnidOper: number): Observable<OpcionPorRolGerencial[]> {
    var url = this.urlSeguro + `/usuario-comprador/${idUsua}/${idUnidOper}`;
    return this.http.get<ResponseOpcionRolGerencial>(url).pipe(
      map((response: ResponseOpcionRolGerencial) => {
        return response.data;
      })
    );
  }

  abrirSocket() {
    this.openSocket.emit();
  }

  cerrarSockets() {
    this.closeSocket.emit();
  }

}
