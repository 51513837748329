import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { fixedNumber, fromDateToPeriodo, numberWithCommas, redondearNumero, setLoadingSpinner } from 'src/app/component/util/Util';
import { Usuario } from 'src/app/model/seguridad/usuario';
import { VentasService } from 'src/app/services/indicadores/ventas.service';
import { UsuariosService } from 'src/app/services/seguridad/usuarios.service';
import { DetalleVentasComponent } from '../ventas/detalle-ventas/detalle-ventas.component';
import { map } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { InitCoordinatorService } from 'src/app/component/services/init-coordinator.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-total-ventas-card',
  templateUrl: './total-ventas-card.component.html',
  styleUrls: ['./total-ventas-card.component.scss'],
  providers: [DialogService],
})
export class TotalVentasCardComponent implements OnInit, AfterViewInit {

  
  @Input() cia: any;

  deCia: string;
  ref: DynamicDialogRef;
  username: string;
  datosUsuario: Usuario;

  totalVenta: string;
  totalMeta: string;
  porcentaje: string;

  private initSubscription: Subscription | null = null;

  constructor(
    public dialogService: DialogService,
    private usuarioService: UsuariosService,
    private ventasService: VentasService,
    private spinner: NgxSpinnerService,
    private initCoordinator: InitCoordinatorService
    ){}
   
  ngOnInit() {

  }

  ngAfterViewInit(){
    console.log('TotalVentas-AfterViewInit');

    this.initSubscription = this.initCoordinator.init$.subscribe(() => {
      
      const cia = localStorage.getItem("data-header");
      console.log('ngOnInit-total-ventas-card.cia', cia);
      this.username = sessionStorage.getItem('SESSIONUSER');
  
      if (cia){
        this.cia = JSON.parse(atob(cia));
        this.deCia = this.cia.ID_ORGN_EBS;
  
        this.obtenerDatosUsuario();
      }

      console.log('ngOnInit-total-ventas-card.cia-timer', this.cia);
    });

    
    
    // setTimeout(() => {
    //   const cia = localStorage.getItem("data-header");
    //   console.log('ngOnInit-total-ventas-card.cia', cia);
    //   this.username = sessionStorage.getItem('SESSIONUSER');
  
    //   if (cia){
    //     this.cia = JSON.parse(atob(cia));
    //     this.deCia = this.cia.ID_ORGN_EBS;
  
    //     this.obtenerDatosUsuario();
    //   }

    //   console.log('ngOnInit-total-ventas-card.cia-timer', this.cia);
    // }, 2000);

  }

  obtenerDatosUsuario() {
    this.usuarioService.obtenerUsuarioSeguro(this.username).subscribe({
      next: (usuario: any) => {
        if ((usuario)) {
          this.datosUsuario = usuario;
          this.obtenerResumenVentaMeta();
        }
      }
    });
  }

  obtenerResumenVentaMeta(): void {
    if (this.cia){
      const params = { idUnidOper: this.cia.ID_ORGN_EBS, coPrdo: fromDateToPeriodo(new Date()) };
      //console.log('params', params);

      const resumenVentas = this.ventasService.obtenerResumenVentasMeta(params).pipe(
        map((resp: any) => {
            //console.log('resp', resp);
            let meta: number; let venta: number;
            if (resp.meta && resp.meta.length>0) {
              meta = resp.meta[0].NU_MNTO_META
              this.totalMeta = numberWithCommas(fixedNumber(resp.meta[0].NU_MNTO_META,2));
            }else{
              meta = 0;
              this.totalMeta = '0.00'
            }

            if (resp.venta && resp.venta.length>0) {
              venta = resp.venta[0].NU_MNTO_VNTA;
              this.totalVenta = numberWithCommas(fixedNumber(resp.venta[0].NU_MNTO_VNTA,2));
            }else{
              venta = 0;
              this.totalVenta = '0.00'
            }
            if (meta == 0){
              this.totalMeta = 'No definido';
              this.porcentaje = 'No definido'
            }else{
              this.porcentaje = numberWithCommas(fixedNumber((venta / meta) * 100, 2));
            }
        }
      ));

      setLoadingSpinner(this.spinner, resumenVentas);
    }
  }

  ngOnChanges() {
    /**********THIS FUNCTION WILL TRIGGER WHEN PARENT COMPONENT UPDATES 'someInput'**************/
    //Write your code here
    const cia = localStorage.getItem("data-header");
    console.log('ciaCuentasPorCobrar', this.cia);
    //this.ciaSelected.emit(this.cia);
    this.deCia = this.cia?this.cia.ID_ORGN_EBS:null;
    this.obtenerResumenVentaMeta();
     //this.cia = JSON.parse(atob(cia));
     //this.deCia = this.cia.ID_ORGN_EBS;
  }

  calcularTotales(): void{

  }

  irDetalle(): void {
    console.log('irDetalle');
    // const url = '/sistema-phoenix/gestion/indicadores/detalle-ventas';
    // this.sharedService.emitEvent(url);
    // this.router.navigateByUrl('/sistema-phoenix/gestion/indicadores/detalle-ventas');
  }

  verDetalle(): void {
    console.log('irDetalle');
    this.ref = this.dialogService.open(DetalleVentasComponent, {
      data: { },
      header: 'Total Ventas vs Meta',
      width: "80%",
      contentStyle: { "max-height": "800px", overflow: "auto" },
      baseZIndex: 1000,
    })

    this.ref.onClose.subscribe((msg: any) => {
      console.log(msg);
    })
  }
}
