import { Component, OnInit, ViewChild, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ContentComponent } from '../../main-component/content/content.component';
import { SharedService } from '../../services/shared.service';
import { IndicadoresComponent } from './indicadores/indicadores.component';
import { MaestroService } from 'src/app/services/maestros/maestro.service';
import { Usuario } from 'src/app/model/seguridad/usuario';
import { map } from 'rxjs/operators';
import { ComboUnidOperativa } from 'src/app/model/maestro/combos';
import { setLoadingSpinner } from '../../util/Util';
import { NgxSpinnerService } from 'ngx-spinner';
import { UsuariosService } from 'src/app/services/seguridad/usuarios.service';

@Component({
  selector: 'app-gestion',
  templateUrl: './gestion.component.html',
  styleUrls: ['./gestion.component.scss']
})
export class GestionComponent implements OnInit {

  @ViewChild(IndicadoresComponent) indicadoresComponent: IndicadoresComponent;
  @Output() buttonClick = new EventEmitter<string>();

  constructor(
  ) { }

  ngOnInit() {
    console.log('ngOnInit-Gestion');
  }
  

  irDetalle(): void {
    //console.log('irDetalle');
    //this.router.navigateByUrl('sistema-phoenix/gestion/indicadores/detalle-ventas');
    this.buttonClick.emit('el botón fue clickeado!')
  }

  onButtonClick(event: string) {
    console.log(event);
    //this.sharedService.emitEvent(event);
    //this.router.navigateByUrl('sistema-phoenix/gestion/indicadores/detalle-ventas');
    // Output: The button was clicked!
  }

}
