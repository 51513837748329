import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-cuentas-por-pagar-card',
  templateUrl: './cuentas-por-pagar-card.component.html',
  styleUrls: ['./cuentas-por-pagar-card.component.scss']
})
export class CuentasPorPagarCardComponent implements OnInit {
  @Input() cia: any;
  deCia: string;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges() {
    /**********THIS FUNCTION WILL TRIGGER WHEN PARENT COMPONENT UPDATES 'someInput'**************/
    //Write your code here
     //console.log('ciaCuentasPorCobrar', this.cia);
     //this.ciaSelected.emit(this.cia);
     this.deCia = this.cia?this.cia.ID_ORGN_EBS:null;
  } 

  verDetalle(): void {
    
  }
}
