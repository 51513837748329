import { Component, OnInit } from '@angular/core';
import { SelectItem, MessageService } from 'primeng/api';

@Component({
  selector: 'app-ventas',
  templateUrl: './ventas.component.html',
  styleUrls: ['./ventas.component.scss']
})
export class VentasComponent implements OnInit {

ventas: any[];
cols: any[];
value1: Date;
value2: Date;
nomb: string;
nombres: string;

descripcion: string;
moneda: string;
monedas: string;
undOperativa: string;
unidadesOperativas: SelectItem[];
listasPrincipal: string;
lisPrincipal: string;
descArticulo: string;


calificador: number = 0;
  constructor() { }

  ngOnInit() {
    this.cols = [];
    this.ventas = [
      {articulo: 1, year: 1, brand: '1', color: 'red'},
      
    ]
  }
}
