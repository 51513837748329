import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
import { MustMatch } from 'src/app/component/util/validators/must.match.component';
import { AuthorizationService } from 'src/app/services/authorization/authorization.service';
import {
  ConfiguracionService
} from 'src/app/services/seguridad_api/configurations/configuracion.service';

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { UsuariosService } from 'src/app/services/seguridad/usuarios.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  resetPasswordForm: FormGroup;
  token: string;

  username: string;
  accesoInterno = false;
  contrasenaValida = false;
  contrasenaIngresada = false;
  lbl: string;
  weakLbl: string;
  mediumLbl: string;
  strongLbl: string;

  constructor(private router: Router,
    private formBuilder: FormBuilder,
    private usuarioService: UsuariosService,
    private authorizationService: AuthorizationService,
    private messageService: MessageService,
    private configuracion: ConfiguracionService,
    private spinner: NgxSpinnerService) { }

  ngOnInit() {
    document.getElementsByTagName('body')[0].classList.add('body-dashboard');

    /** Configuración para la password */
    this.lbl = "Ingrese Contraseña";
    this.weakLbl = "Débil";
    this.mediumLbl = "Buena";
    this.strongLbl = "Fuerte";

    this.username = sessionStorage.getItem("USER_CAMBIO");
    if (this.username && this.username != null) {
      this.createFormAccesoInterno();
      this.contrasenaValida = false;
      this.contrasenaIngresada = false;
      this.accesoInterno = true;
    } else {
      this.createForm();
      this.validarUrlConToken();
    }
  }

  validarUrlConToken() {
    const url = location.href;
    if (url.search('token=') > -1) {
      const token = url.split('token=')[1];
      if (!token && token == null && token == "") {
        this.router.navigateByUrl('/');
      } else {
        this.token = token;
      }
    } else {
      this.router.navigateByUrl('/');
    }
  }

  createForm() {
    this.contrasenaValida = true;
    this.contrasenaIngresada = true;
    this.resetPasswordForm = this.formBuilder.group({
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required],
    }, {
      validator: MustMatch('password', 'confirmPassword')
    });
  }

  createFormAccesoInterno() {
    this.resetPasswordForm = this.formBuilder.group({
      currentPassword: ['', Validators.required],
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required],
    }, {
      validator: MustMatch('password', 'confirmPassword')
    });
  }

  cambiarContrasena() {
    const _this = this;
    if (this.resetPasswordForm.valid) {
      this.authorizationService.cambiarPassword(this.resetPasswordForm.value, this.token).subscribe({
        next(response: any) {
          if (response.success) {
            _this.router.navigateByUrl('/page-password-change');
          }
        }
      });
    }
  }

  cancelarCambioContrasena() {
    this.router.navigateByUrl("/sistema-phoenix/gestion");
  }

  verificarContrasenaActual() {
    this.contrasenaValida = false;
    this.contrasenaIngresada = true;
    this.authorizationService.verificarPassword(this.username, this.resetPasswordForm.controls.currentPassword.value).subscribe({
      next: (response: any) => {
        if (response.success) {
          this.contrasenaValida = true;
          this.contrasenaIngresada = true;
        } else {
          this.contrasenaValida = false;
          this.contrasenaIngresada = true;
          this.resetPasswordForm.reset();
        }
      }
    })
  }

  cambiarContrasenaInterno() {
    if (this.resetPasswordForm.valid) {
      Swal.fire({
        title: 'Modificar Contraseña?',
        text: "Se realizará la modificación de su contraseña!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, Modificar!'
      }).then((result) => {
        if (result.value) {
          this.authorizationService.cambiarPasswordInterno(this.resetPasswordForm.value, this.username).subscribe({
            next: (response: any) => {
              
              if (response.success) {
                this.spinner.show();
                this.cerrarSession();
              } else {
                this.messageService.add({ severity: 'error', summary: 'Service Message Phoenix', detail: response.msg });
              }
            }
          });
        }
      });
    }
  }

  cerrarSession() {
    
    this.authorizationService.finalizarSesion(this.username).subscribe({
      next: () => {
        const url = this.configuracion.serverUrlRecurso + `/acceso_externo.xhtml?accion=cerrarSession&parametros=0`;
        window.open(url, "_blank");
        window.parent.focus();
        sessionStorage.clear();
        localStorage.clear();
        this.usuarioService.cerrarSockets();
        setTimeout(() => {
          this.spinner.hide();
          this.router.navigateByUrl('/page-password-change');
        }, 1000);
      }
    });


  }
}
