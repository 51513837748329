import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  loadingCargado: EventEmitter<boolean> = new EventEmitter();

  constructor() { }

  informarLoading(cargado: any){
    this.loadingCargado.emit(cargado);
  }
}
