import { Component, OnInit, Output, EventEmitter, Input, Inject } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { UsuariosService } from 'src/app/services/seguridad/usuarios.service';
import { WebServicePhoenixService } from 'src/app/services/authorization/web-service-phoenix.service';
import { ConfiguracionService } from 'src/app/services/seguridad_api/configurations/configuracion.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificacionService } from 'src/app/services/notificacion/notificacion.service';
import { InformarService } from 'src/app/services/util/informar.service';
import { AuthorizationService } from 'src/app/services/authorization/authorization.service';
import { Usuario } from 'src/app/model/seguridad/usuario';
import { ComboUnidOperativa } from 'src/app/model/maestro/combos';
import { MaestroService } from 'src/app/services/maestros/maestro.service';
import { map } from 'rxjs/operators';
import { fromDateToString, obtenerFechaActualFormatoCompleto, redondearNumero, setLoadingSpinner } from '../../util/Util';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TipoCambioService } from 'src/app/services/maestros/tipo-cambio/tipo-cambio.service';
import { TipoCambio, TipoCambioParam } from 'src/app/model/finanzas/tipo-cambio/TipoCambio';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input() eventoEmitter: EventEmitter<any> = new EventEmitter();
  @Output() menuEmitter: EventEmitter<any> = new EventEmitter();
  @Output() cambioCiaEmitter: EventEmitter<any> = new EventEmitter();
  @Output() contentEmitter: EventEmitter<any> = new EventEmitter();
  
  menu = true;
  username: any;
  listaCompania: ComboUnidOperativa[];
  datosUsuario: Usuario;

  items: MenuItem[];
  itemsButton: MenuItem[];
  visibleSidebar: boolean = false;

  nombreUsuario: string;
  ipTerminal: string;
  fechaLogin: Date;
  cantNoti: number;
  sexoUsuario: string;

  notificacion: any[] = [];
  headerForm: FormGroup;
  cia: any = {};

  tipoCambioVenta: string;
  tipoCambioCompra: string;

  constructor(
    private usuarioService: UsuariosService,
    private fb: FormBuilder,
    private tipoCambioService: TipoCambioService, 
    private configuracion: ConfiguracionService,
    private notificacionService: NotificacionService,
    private maestroService: MaestroService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private informarService: InformarService,
    private authorizationService: AuthorizationService) { }

  ngOnInit() {
    console.log('ngOnInit - header - Inicio');
    let usuario = sessionStorage.getItem('SESSIONUSER');
    if (usuario && usuario != null) {
      this.username = usuario;
      this.obtenerDatosUsuario();
      this.obtenerResumenNoti();
      this.eventoEmitter.subscribe(() => {
        this.obtenerResumenNoti();
      })
    }

    this.abrirMenu();
    this.crearForm();
    
  }

  crearForm() {
    this.headerForm = this.fb.group({
      cia: [{value: null, disabled: false}],
      fecha: [{value: obtenerFechaActualFormatoCompleto(), disabled: false}],
      tipoCambioVenta: [{value: null, disabled: false}],
      tipoCambioCompra: [{value: null, disabled: false}],
    });
  }

  abrirMenu() {
    //console.log('abrirMenu');
    this.menu = !this.menu;
    this.menuEmitter.emit(this.menu);
  }

  activarSidebar() {
    this.visibleSidebar = true;
  }

  obtenerListacia() {
    //console.log('obtenerListacia');
    const cia = this.maestroService.obtenerUnidadOperativa(this.datosUsuario.ID_USUA).pipe(
      map((lista: ComboUnidOperativa[]) => {
        this.listaCompania = lista;
        //console.log('this.listaCompania', this.listaCompania);
        const cia = localStorage.getItem("data-header");
        if (cia){
          this.cia = JSON.parse(atob(cia));
          this.headerForm.controls['cia'].setValue(this.cia);
        }else{
          this.headerForm.controls['cia'].setValue(lista.filter(f=>f.IN_ORGN_DFLT == '1')[0]);
          this.cia = this.headerForm.controls['cia'].value;
        }
        
        //enviar los emiter en este orden.
       //this.cambioCiaEmitter.emit(this.headerForm.controls['cia'].value);
       localStorage.setItem('data-header', btoa(JSON.stringify(this.cia)));
       this.menuEmitter.emit(false); //se coloca en falso para que lo cambie a verdadero en la funcion del openMenu.
       this.contentEmitter.emit(this.cia);

       console.log('ngOnInit - header - Fin');
      })
    );

    setLoadingSpinner(this.spinner, cia);
  }

  obtenerTipoCambioVenta(): void {
    const params: TipoCambioParam = {  FE_CONV_STR: fromDateToString(new Date()),
                      CO_TIPO_CONV: '1001',
                      CO_MNDA_DSDE: 'USD',
                      CO_MNDA_HSTA: 'PEN'
                    };

    const tipoCambio = this.tipoCambioService.obtenerTipoCambio(params).pipe(
      map((response: TipoCambio[]) => {
        if (response && response.length > 0){
          this.headerForm.controls.tipoCambioVenta.setValue(`S/. ${redondearNumero(response[0].NU_TASA_CONV,4)}`);
          this.tipoCambioVenta = `S/. ${redondearNumero(response[0].NU_TASA_CONV,4)}`;
        }else{
          this.headerForm.controls.tipoCambioVenta.setValue('No definido');
          this.tipoCambioVenta = `No definido`;
        }
      })
    );

    setLoadingSpinner(this.spinner, tipoCambio);
  }

  obtenerTipoCambioCompra(): void {
    const params: TipoCambioParam = {  FE_CONV_STR: fromDateToString(new Date()),
                      CO_TIPO_CONV: '1000',
                      CO_MNDA_DSDE: 'USD',
                      CO_MNDA_HSTA: 'PEN'
                    };

    const tipoCambio = this.tipoCambioService.obtenerTipoCambio(params).pipe(
      map((response: TipoCambio[]) => {
        if (response && response.length > 0){
          this.headerForm.controls.tipoCambioCompra.setValue(`S/. ${redondearNumero(response[0].NU_TASA_CONV,4)}`);
          this.tipoCambioCompra = `S/. ${redondearNumero(response[0].NU_TASA_CONV,4)}`;
        }else{
          this.headerForm.controls.tipoCambioCompra.setValue('No definidoC');
          this.tipoCambioCompra = `No definido`;
        }
      })
    );

    setLoadingSpinner(this.spinner, tipoCambio);
  }

  cambioCia(): void{
    console.log('cambioCia');
    this.cambioCiaEmitter.emit(this.headerForm.controls['cia'].value);
    //localStorage.setItem('cia', this.headerForm.controls['cia'].value);
  }

  obtenerDatosUsuario() {
    this.usuarioService.obtenerUsuarioSeguro(this.username).subscribe({
      next: (usuario: Usuario) => {
        if (usuario) {
          this.datosUsuario = usuario;
          this.nombreUsuario = usuario.NO_USUA.concat(' ' + usuario.AP_PATE).concat(' ' + usuario.AP_MATE);
          this.ipTerminal = usuario.IP_TERM_SESN;
          this.fechaLogin = usuario.FE_LOGN ? usuario.FE_LOGN : new Date();
          this.sexoUsuario = usuario.IN_SEXO;

          this.obtenerListacia();
          this.obtenerTipoCambioVenta();
          this.obtenerTipoCambioCompra();
        }
      }
    });
  }

  cambiarContrasena() {
    sessionStorage.setItem("USER_CAMBIO", this.username);
    this.router.navigateByUrl('/reset-password');
  }

  cerrarSession() {
    const key = sessionStorage.getItem('KEYSESSION');
    //this.authorizationService.finalizarSesion(this.username).subscribe({
    this.authorizationService.cerrarSesion('01', key, this.username).subscribe({
      next: () => {
        const url = this.configuracion.serverUrlRecurso + `/acceso_externo.xhtml?accion=cerrarSession&parametros=0`;
        window.open(url, "_blank");
        window.parent.focus();
        sessionStorage.clear();
        localStorage.clear();
        this.spinner.show();
        this.usuarioService.cerrarSockets();
        setTimeout(() => {
          this.router.navigateByUrl("/");
        }, 1000);
      }
    });
  }

  verNotificaciones() {
    const event: any = { index: 2, emiter: true };
    this.informarService.informarPrincipal("/sistema-phoenix/gestion/notificacion");
    //this.notificacionEmitter.emit(event);
    this.router.navigateByUrl("/sistema-phoenix/gestion/notificacion");
  }

  obtenerResumenNoti() {
    this.notificacionService.obtenerResumenNoti(this.username).subscribe({
      next: (resp: any) => {
        this.cantNoti = resp.cantidad;
        this.notificacion = resp.notificacion;
      }
    });
  }
}
