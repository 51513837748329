import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfiguracionService } from '../seguridad_api/configurations/configuracion.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { getParamsForUrl } from 'src/app/component/util/Util';

@Injectable({
  providedIn: 'root'
})
export class VentasService {

  url: string;

  socketEmitter: EventEmitter<any> = new EventEmitter();

  constructor(private http: HttpClient,
    private configuracion: ConfiguracionService) {
    this.url = this.configuracion.serverUrlApiSegura;
  }

  obtenerResumenVentasMeta(params: any): Observable<any> {
    const url = `${this.url}/kpi/obtener-resumen-venta-meta${getParamsForUrl(params)}`
    return this.http.get<any>(url).pipe(
      map((response: any) => {
        return response.data;
      })
    )
  }

  obtenerVentas(fecha: string, compania: string, vendedores: string, tipoLnea: string): Observable<any> {
    const url = `${this.url}/kpi/ventas?fecha=${fecha}&compania=${compania}&vendedores=${vendedores}&tipoLnea=${tipoLnea}`
    return this.http.get<any>(url).pipe(
      map((response: any) => {
        return response.data;
      })
    )
  }

  emitirEventoVentas() {
    this.socketEmitter.emit(true);
  }
}
