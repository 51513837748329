import { Component, OnInit, Input } from '@angular/core';
import { VentasService } from 'src/app/services/indicadores/ventas.service';
import { Message } from 'primeng/api';

@Component({
  selector: 'app-grafico-ventas',
  templateUrl: './grafico-ventas.component.html',
  styleUrls: ['./grafico-ventas.component.scss']
})
export class GraficoVentasComponent implements OnInit {

  @Input() nameGrafico: string;
  @Input() dataGrafico: any;
  @Input() options: any;
  @Input() visualizarGrafico: any;
  @Input() msgs: Message;

  data: any;

  constructor() { }

  ngOnInit() { }

}
