<p-messages *ngIf="visualizarMensaje" [(value)]="msgs" [showTransitionOptions]="'500ms'"></p-messages>
<p-table #dt [columns]="cols" [value]="data" [paginator]="paginator" [rows]="row"
  [style]="{width:'100%'}" [scrollHeight]="scrollHeight" [resizableColumns]="true" [responsive]="true"
  [(selection)]="selectedRow" selectionMode="{{selectionMode}}" (onRowSelect)="onRowSelect($event)"
  [frozenWidth]="frozenWidth" [frozenColumns]="frozenColumns" [metaKeySelection]="false"
  (onRowUnselect)="onRowUnselect($event)" [loading]="loading" dataKey="{{datakey}}">
  <ng-template pTemplate="emptymessage" let-columns>
    <tr style="text-align: center">
      <td [attr.colspan]="columns.length">
        No se encontraron registros.
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="caption" *ngIf="globalFilter">
    <div style="text-align: right" (window:resize)="onResize($event.currentTarget.window.innerWidth)">
      <div class="ui-inputgroup">
        <span class="ui-inputgroup-addon"><i class="pi pi-search"></i></span>
        <input type="text" pInputText size="35" (input)="dt.filterGlobal($event.target.value, 'contains')"
          style="width: 100%;">
      </div>
    </div>
  </ng-template>

  <ng-template pTemplate="caption" *ngIf="!globalFilter">
    <div style="text-align: right" (window:resize)="onResize($event.currentTarget.window.innerWidth)">
      <div class="ui-inputgroup" *ngIf="filtroGlobal">
        <span class="ui-inputgroup-addon"><i class="pi pi-search"></i></span>
        <input type="text" pInputText size="35" (input)="dt.filterGlobal($event.target.value, 'contains')"
          style="width: calc(100%);">
      </div>
    </div>
  </ng-template>

  <ng-template pTemplate="colgroup" let-columns>
    <colgroup>
      <col *ngFor="let col of columns" [style.width]="col.width">
    </colgroup>
  </ng-template>

  <ng-template pTemplate="header" let-columns>
    <tr *ngIf="exportExcel">
      <td>
        <div style="text-align: left; padding-left: 3px; padding-bottom: 3px">
          <i pTooltip="exportar excel" tooltipPosition="right" tooltipEvent="hover" class="far fa-file-excel"
            style="font-size:30px;color:rgb(0, 128, 0);cursor: pointer;" (click)="exportAsXLSX()" disabled></i>
        </div>
      </td>
      <td style="text-align: center;">
        <b>{{cabeceraTabla}}</b>
      </td>
    </tr>
    <tr>
      <th *ngIf="tblCheckbox" style="width: 3em">
        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
      </th>
      <th *ngFor="let col of columns" [pSortableColumn]="col.sort == true ? col.field : null" pResizableColumn>
        {{col.header}}
        <p-sortIcon *ngIf="col.sort" [field]="col.field"></p-sortIcon>
      </th>
    </tr>
    <tr *ngIf="filterSomeHeader">
      <th *ngFor="let col of columns" [ngSwitch]="col.filter" [ngStyle]="{'width': col.width}" pResizableColumn>
        <input *ngSwitchCase="true" pInputText type="text"
          (input)="dt.filter($event.target.value, col.field, col.filterMatchMode)">
        <p-dropdown *ngSwitchCase="'select'" styleClass="table-dropdown" [options]="semaforo" appendTo="body"
          (onChange)="dt.filter($event.value, col.field, 'equals')">
          <ng-template let-semaforo pTemplate="selectedItem">
            <img *ngIf="semaforo.value != null" src="./../../../../assets/icons/semaforo/{{semaforo.icon}}.png"
              style="width:16px;vertical-align:middle" />
            <span *ngIf="semaforo.value == null"
              style="vertical-align:middle; margin-left: .5em">{{semaforo.label}}</span>
          </ng-template>
          <ng-template let-semaforo pTemplate="item">
            <div class="ui-helper-clearfix" style="position: relative;height: 25px;">
              <img *ngIf="semaforo.value != null" src="./../../../../assets/icons/semaforo/{{semaforo.icon}}.png"
                style="width:24px;position:absolute;top:1px;left:5px" />
              <div *ngIf="semaforo.value == null" style="font-size:14px;margin-top:4px;float:left">
                {{semaforo.label}}
              </div>
            </div>
          </ng-template>
        </p-dropdown>
        <p-dropdown *ngSwitchCase="'combo'" styleClass="table-dropdown" [options]="arrayCombo" appendTo="body"
          [style]="{'width':'100%'}" (onChange)="dt.filter($event.value, col.field, 'equals')">
        </p-dropdown>
        <p-selectButton *ngSwitchCase="'selectbutton'" [options]="optionButtons" [(ngModel)]="selectedModes"
          optionLabel="title"></p-selectButton>
      </th>
    </tr>
    <tr>
    </tr>
  </ng-template>

  <ng-template *ngIf="!selectRow && !expandRow" pTemplate="body" let-rowData let-columns="columns">
    <tr>
      <td *ngFor="let col of columns" [pEditableColumn]="col.type==='select'" [ngStyle]="{'width': col.width}"
        class="ui-resizable-column" [ngClass]="{'positionLabel': col.type=='label' || col.type=='concat' || col.type=='link',
        'positionCurrency': col.type=='currency' || col.type=='number' || col.type=='doller', 'positionSemaforo': col.type=='hecho' 
        || col.type=='semaforo', 'positionCenter': col.type=='center' || col.type=='porc' || col.type=='checkbox'}">
        <!-- <span class="ui-column-title">{{ col.header }}</span> -->
        <!-- formato fecha y hora -->
        <span *ngIf="col.type=='date'">{{rowData[col.field] | date:'dd/MM/yyyy HH:mm:ss'}}</span>
        <!-- formato fecha -->
        <span *ngIf="col.type=='fecha'">{{rowData[col.field] | date:'dd/MM/yyyy'}}</span>
        <!-- concatenación multiple campos -->
        <span *ngIf="col.type=='concat'">{{rowData[col.attr] + '-' + rowData[col.field]}}</span>
        <!-- formato texto -->
        <!-- <span *ngIf="col.type=='label'">{{rowData[col.field]}}</span> -->
        <span *ngIf="col.type=='label'" [innerHtml]="rowData[col.field]"></span>
        <!-- formato centrado -->
        <span *ngIf="col.type=='center'">{{rowData[col.field]}}</span>
        <!-- formato tipo number -->
        <span *ngIf="col.type=='number'">{{rowData[col.field] |  number:'1.2'}}</span>
        <!-- formato tipo moneda -->
        <span *ngIf="col.type=='currency'">{{rowData[col.field] |  number:'1.2'}}</span>
        <!-- formato tipo dolar -->
        <span *ngIf="col.type=='doller'">${{rowData[col.field] |  number:'1.2'}}</span>
        <!-- Campo Array -->
        <span *ngIf="col.type=='multi'">{{rowData[col.field][0][col.chield]}}</span>
        <!-- formato porcentaje -->
        <span *ngIf="col.type=='porc'">
          <span *ngIf="rowData[col.field]!=undefined && rowData[col.field]!=null && rowData[col.field]!=''">
            {{rowData[col.field] |number:'.0-2'}}%
          </span>
        </span>
        <!-- hypervínculo -->
        <span *ngIf="col.type=='link'" style="cursor: pointer">
          <a *ngIf="rowData[col.link]!=null && rowData[col.link]!=undefined && rowData[col.link]!=''" target="_blank"
            rel="noopener" (click)="accederEnlace(urlEnlace+rowData[col.link])">{{rowData[col.field]}}</a>
          <span
            *ngIf="rowData[col.link]==null || rowData[col.link]==undefined || rowData[col.link]==''">{{rowData[col.field]}}</span>
        </span>
        <!-- imágen para notificación -->
        <span *ngIf="col.type=='hecho'">
          <img
            [src]="rowData[col.field] == '1' ? './../../../../assets/icons/notificacion/noti-complete.png' : './../../../../assets/icons/notificacion/noti-pendiente.png'"
            id="icon" alt="Menu" style="width: 20px;" />
        </span>
        <!-- imagen tipo semaforo -->
        <span *ngIf="col.type=='semaforo'">
          <img
            [src]="rowData[col.field] == 1 ? './../../../../assets/icons/semaforo/icon-green2.png' 
              : rowData[col.field] == 2 ? './../../../../assets/icons/semaforo/icon-yellow.png' 
              : (rowData[col.field] != 1 && rowData[col.field] != 2) ? './../../../../assets/icons/semaforo/icon-red2.png' : '' "
            id="icon" alt="Menu" style="width: 20px;" />
        </span>
        <!-- combo selected -->
        <p-cellEditor *ngIf="col.type=='select'">
          <ng-template pTemplate="input">
            <p-dropdown [(ngModel)]="rowData.brand" [style]="{'width':'100%'}"></p-dropdown>
          </ng-template>
          <ng-template pTemplate="output">
            {{rowData.brand}}
          </ng-template>
        </p-cellEditor>
        <!-- checked -->
        <p-checkbox *ngIf="col.type=='checkbox'" [(ngModel)]="rowData[col.field]" binary="true"></p-checkbox>

        <!-- botones -->
        <button pButton type="button" *ngIf="col.type=='btnUpdate'" icon="fas fa-redo-alt" (click)="updateRow(rowData)"
          style="margin: 3px;" class="btn-bs-primary" pTooltip="Actualizar" tooltipPosition="left" tooltipEvent="hover"
          tooltipZIndex="20000"></button>
        <button pButton type="button" *ngIf="col.type=='btnDelete'" icon="fas fa-trash-alt" class="btn-bs-danger"
          (click)="deleteRow(rowData)" style="margin: 3px;" pTooltip="Eliminar" tooltipPosition="left"
          tooltipEvent="hover" tooltipZIndex="20000"></button>
        <button pButton type="button"
          *ngIf="col.type=='btnAdd' && (rowData[col.condition]==true || rowData[col.condition]==undefined)"
          [icon]="col.icon" class="btn-bs-dark" (click)="addRow(rowData)" style="margin: 3px;" [pTooltip]="col.pTooltip"
          tooltipPosition="left" tooltipEvent="hover" tooltipZIndex="20000"></button>
      </td>
    </tr>
  </ng-template>
  <ng-template *ngIf="selectRow" pTemplate="body" let-rowData let-columns="columns">
    <tr [pSelectableRow]="rowData">
      <td *ngIf="tblCheckbox">
        <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
      </td>
      <td *ngFor="let col of columns" [ngStyle]="{'width': col.width}" class="ui-resizable-column" [ngClass]="{'positionLabel': col.type=='label' || col.type=='concat' || col.type=='link',
          'positionCurrency': col.type=='currency' || col.type=='number' || col.type=='doller', 'positionSemaforo': col.type=='hecho' 
          || col.type=='semaforo', 'positionCenter': col.type=='center' || col.type=='porc' || col.type=='checkbox'}">
        <!-- <span class="ui-column-title">{{ col.header }}</span> -->
        <!-- formato fecha y hora -->
        <span *ngIf="col.type=='date'">{{rowData[col.field] | date:'dd/MM/yyyy HH:mm:ss'}}</span>
        <!-- formato fecha -->
        <span *ngIf="col.type=='fecha'">{{rowData[col.field] | date:'dd/MM/yyyy'}}</span>
        <!-- concatenación multiple campos -->
        <span *ngIf="col.type=='concat'">{{rowData[col.attr] + '-' + rowData[col.field]}}</span>
        <!-- formato texto -->
        <span *ngIf="col.type=='label'" [innerHtml]="rowData[col.field]"></span>
        <!-- formato centrado -->
        <span *ngIf="col.type=='center'">{{rowData[col.field]}}</span>
        <!-- formato tipo number -->
        <span *ngIf="col.type=='number'">{{rowData[col.field] |  number:'1.2'}}</span>
        <!-- formato tipo moneda -->
        <span *ngIf="col.type=='currency'">{{rowData[col.field] |  number:'1.2'}}</span>
        <!-- formato tipo moneda -->
        <span *ngIf="col.type=='doller'">${{rowData[col.field] |  number:'1.2'}}</span>
        <!-- Campo Array -->
        <span *ngIf="col.type=='multi'">{{rowData[col.field][0][col.chield]}}</span>
        <!-- formato porcentaje -->
        <span *ngIf="col.type=='porc'">
          <span *ngIf="rowData[col.field]!=undefined && rowData[col.field]!=null && rowData[col.field]!=''">
            {{rowData[col.field] |number:'.0-2'}}%
          </span>
        </span>
        <!-- hypervínculo -->
        <span *ngIf="col.type=='link'" style="cursor: pointer">
          <a *ngIf="rowData[col.link]!=null && rowData[col.link]!=undefined && rowData[col.link]!=''" target="_blank"
            rel="noopener" (click)="accederEnlace(urlEnlace+rowData[col.link])">{{rowData[col.field]}}</a>
          <span
            *ngIf="rowData[col.link]==null || rowData[col.link]==undefined || rowData[col.link]==''">{{rowData[col.field]}}</span>
        </span>
        <!-- imágen para notificación -->
        <span *ngIf="col.type=='hecho'">
          <img
            [src]="rowData[col.field] == '1' ? './../../../../assets/icons/notificacion/noti-complete.png' : './../../../../assets/icons/notificacion/noti-pendiente.png'"
            id="icon" alt="Menu" style="width: 20px;" />
        </span>
        <!-- imagen tipo semaforo -->
        <span *ngIf="col.type=='semaforo'">
          <img [src]="rowData[col.field] == 1 ? './../../../../assets/icons/semaforo/icon-green2.png' 
                : rowData[col.field] == 2 ? './../../../../assets/icons/semaforo/icon-yellow.png' 
                : (rowData[col.field] == 3) ? './../../../../assets/icons/semaforo/icon-red2.png' : '' " id="icon"
            alt="Menu" style="width: 20px;" />
        </span>
        <!-- combo selected -->
        <p-cellEditor *ngIf="col.type=='select'">
          <ng-template pTemplate="input">
            <p-dropdown [(ngModel)]="rowData.brand" [style]="{'width':'100%'}"></p-dropdown>
          </ng-template>
          <ng-template pTemplate="output">
            {{rowData.brand}}
          </ng-template>
        </p-cellEditor>
        <!-- checked -->
        <p-checkbox *ngIf="col.type=='checkbox'" [(ngModel)]="rowData[col.field]" binary="true"></p-checkbox>

        <!-- botones -->
        <button pButton type="button" *ngIf="col.type=='btnUpdate'" icon="fas fa-redo-alt" (click)="updateRow(rowData)"
          style="margin: 3px;" class="btn-bs-primary" pTooltip="Actualizar" tooltipPosition="left" tooltipEvent="hover"
          tooltipZIndex="20000"></button>
        <button pButton type="button" *ngIf="col.type=='btnDelete'" icon="fas fa-trash-alt" class="btn-bs-danger"
          (click)="deleteRow(rowData)" style="margin: 3px;" pTooltip="Eliminar" tooltipPosition="left"
          tooltipEvent="hover" tooltipZIndex="20000"></button>
        <button pButton type="button"
          *ngIf="col.type=='btnAdd' && (rowData[col.condition]==true || rowData[col.condition]==undefined)"
          [icon]="col.icon" class="btn-bs-dark" (click)="addRow(rowData)" style="margin: 3px;" [pTooltip]="col.pTooltip"
          tooltipPosition="left" tooltipEvent="hover" tooltipZIndex="20000"></button>
      </td>
    </tr>
  </ng-template>
  <ng-template *ngIf="expandRow" pTemplate="body" let-rowData let-rowIndex="rowIndex" let-expanded="expanded"
    let-columns="columns">
    <tr class="ui-widget-header" *ngIf="rowGroupMetadata[rowData[textGroupMetadata]].index === rowIndex">
      <td colspan="7">
        <a [pRowToggler]="rowData">
          <i [ngClass]="expanded ? 'fa fa-fw fa-chevron-circle-down' : 'fa fa-fw fa-chevron-circle-right'"></i>
          <span *ngFor="let colGroup of colGroupMetadata"><label>{{colGroup.header}}:</label>{{rowData[colGroup.field]}}
          </span>
        </a>
      </td>
    </tr>
  </ng-template>
  <ng-template *ngIf="expandRow" pTemplate="rowexpansion" let-rowData let-rowIndex="rowIndex" let-columns="columns">
    <tr>
      <td *ngFor="let col of columns" [ngStyle]="{'width': col.width}">
        <!-- <span class="ui-column-title">{{ col.header }}</span> -->
        {{rowData[col.field]}}
      </td>
    </tr>
  </ng-template>

  <ng-template pTemplate="footer">
    <tr>
      <td *ngFor="let total of dataTotal; let i = index" [ngClass]="{'positionLabel': total.type=='header',
      'positionCurrency': total.type=='currency' || total.type=='porc'}">
        <span *ngIf="total.type=='header'">{{total['field']}}</span>
        <span *ngIf="total.type=='number'">{{total['value']}}</span>
        <span *ngIf="total.type=='currency'">{{total['value'] |  number:'1.2'}}</span>
        <span *ngIf="total.type=='doller'">${{total['value']  |  number:'1.2'}}</span>
        <span *ngIf="total.type=='porc'">{{total['value'] + ' %'}}</span>
      </td>
    </tr>
  </ng-template>
</p-table>