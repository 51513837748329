<!-- <div class="p-grid" *ngIf="openMenu">
  <div class="p-col-12 p-md-12 p-lg-12" style="font-size: 1.2em !important; text-align: center">
    <label for="roles"><strong>Rol:</strong></label>
    <p-dropdown [options]="roles" [(ngModel)]="selectedRoles" optionValue="CODIGO" optionLabel="DESCRIPCION"
    (onChange)="seleccionarRol()" filter="true">
    </p-dropdown>
  </div>
</div> 
<br>
-->
<!-- <div class="p-col-12 p-md-3 p-lg-2 contenedor" *ngFor="let opcion of opcionesPorRol" style="text-align: center">
  <a target="_blank" rel="noopener" (click)="accederEnlace(opcion.DE_PATH_OPCN, opcion.IN_OPCN_JS)">
    <img class="imagen" [src]="'./../../../../assets'+opcion.DE_PATH_ICON" id="icon" alt="Enlace" />
  </a>
  <div class="ui-g form-group">
      <div class="ui-g-12 ui-md-12" style="font-size: 1.2em !important">
        <span>{{opcion.DE_OPCN}}</span> 
      </div>
  </div>
</div> -->

<div class="p-col-12" style="text-align: center; margin-top: 10px">
  <img src="./../../../../assets/icons/{{logo}}" id="icon" alt="Sidebar" />
</div>

<p-panelMenu [model]="items" [transitionOptions]="'100ms'" [multiple]="false" [style]="{'width':'100%'}"></p-panelMenu>

<!-- <div class="p-grid" *ngIf="openMenu">
  <div class="p-col-12"><br></div>
  <div class="p-col-12 p-md-6 p-lg-6" style="text-align:-webkit-right;"><strong>Seleccione Rol:</strong></div>
  <div class="p-col-12 p-md-6 p-lg-6">
    <p-dropdown [options]="roles" [(ngModel)]="selectedRoles" optionValue="CODIGO" optionLabel="DESCRIPCION"
                (onChange)="seleccionarRol()" filter="true"
                >
    </p-dropdown>
  </div>
  <div class="p-col-12 p-md-3 p-lg-2 contenedor" *ngFor="let opcion of opcionesPorRol" style="text-align: center">
    <a target="_blank" rel="noopener" (click)="accederEnlace(opcion.DE_PATH_OPCN, opcion.IN_OPCN_JS)">
      <img class="imagen" [src]="'./../../../../assets'+opcion.DE_PATH_ICON" id="icon" alt="Enlace" />
    </a>
    <div class="ui-g form-group">
      <div class="ui-g-12 ui-md-12" style="font-size: 1.2em !important">
        <span>{{opcion.DE_OPCN}}</span> 
      </div>
    </div>
  </div>
</div>
<app-footer *ngIf="openMenu"></app-footer> -->