import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DetalleVentasComponent } from './indicador-gestion/ventas/detalle-ventas/detalle-ventas.component';
import { GraficoVentasComponent } from './indicador-gestion/ventas/grafico-ventas/grafico-ventas.component';
import { IndicadoresComponent } from './indicadores.component';


const routes: Routes = [
  { path: '', component: IndicadoresComponent, children: [
    { path: 'detalle-ventas', component: DetalleVentasComponent },
    { path: 'grafico-ventas', component: GraficoVentasComponent }
  ]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class IndicadoresRoutingModule { }
