import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
import { ColoresSemaforo } from 'src/app/constantes/colores';

@Component({
  selector: 'app-cards',
  templateUrl: './cards.component.html',
  styleUrls: ['./cards.component.scss']
})
export class CardsComponent implements OnInit {

  @Input() tipoCard: number;
  @Input() header: string;
  @Input() icono: string;
  @Input() bodyCard: any;
  @Input() ruta: string;
  @Input() valor: string = '0';
  @Input() semaforo: string;
  @Input() responsable: string;
  @Input() mailFrom: string;
  @Input() mailTo: string;
  @Input() viewDetalle: boolean;

  gaugeType = "full";
  gaugeValue = 15.3;
  gaugeLabel = "Logrado";
  gaugeAppendText = "%";
  thresholdConfig = {
    '0': { color: ColoresSemaforo.rojo },
    '40': { color: ColoresSemaforo.ambar },
    '75.5': { color: ColoresSemaforo.verde }
  };

  optionsCard: any = {};

  view: any[] = [280, 100];
  display: boolean = false;
  displayMail: boolean = false;
  text: string;

  constructor(private router: Router,
    private spinner: NgxSpinnerService,
    private messageService: MessageService) { }

  ngOnInit() {
    this.spinner.hide();

    this.construirGrafico(this.valor, this.semaforo);

  }


  construirGrafico(valor, colorSemaforo) {
    this.optionsCard = {
      tooltip: {
        formatter: "{a} <br/>valor : {c}%" //formateo del tooltip
      },
      toolbox: {
        show: true,
        feature: {
          saveAsImage: { show: true, title: "save" }
        }
      },
      series: [
        {
          name: this.header,
          type: 'gauge',
          splitNumber: 10,
          axisLine: {
            show: true,
            lineStyle: {
              color: [[0.3, '#FC3314'], [0.65, '#FEB312'], [1, '#53CB1A']],
              width: 8
            }
          },
          axisTick: {
            splitNumber: 10,
            length: 12,
            lineStyle: {
              color: 'auto'
            }
          },
          axisLabel: {
            textStyle: {
              color: 'auto'
            }
          },
          splitLine: {
            show: true,
            length: 30,
            lineStyle: {
              color: 'auto'
            }
          },
          pointer: {
            width: 5
          },
          title: {
            subtext: this.header,
            show: false,
            offsetCenter: [0, '-40%'],
            textStyle: {
              fontWeight: 'bolder',
              color: '#eee'
            }
          },
          detail: {
            formatter: '{value}%',
            textStyle: {
              color: 'black',
              fontWeight: 'bolder'
            }
          },
          data: [{ value: valor, name: this.header }]
        }
      ]
    }

    this.semaforo = colorSemaforo;
  }

  clickCard() {
    this.router.navigateByUrl("/phoenix/indicadores");
  }

  verDetalleCard(ruta: string) {
    sessionStorage.setItem('SESSION_CARD', this.header);
    this.router.navigateByUrl(ruta);
  }

  showModalDetalle() {
    this.display = true;
  }

  showTopLeft() {
    this.messageService.add({ key: 'tl', severity: 'info', summary: 'Service Message Phoenix', detail: 'Estamos trabajando en la funcionalidad de la opción!' });
  }

  showModalMail() {
    this.displayMail = true;
  }

  cancelarModalMail(event: any) {
    this.displayMail = event;
  }

}
