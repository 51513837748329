import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './component/main-component/header/header.component';
import { MenuComponent } from './component/main-component/menu/menu.component';
import { FooterComponent } from './component/main-component/footer/footer.component';
import { FlexLayoutModule } from '@angular/flex-layout';

import { IndicadorCuentasPorPagarServicioEmiter } from './component/core/gestion/reportes/cuentas-cobrar/indicador-cuentas-por-pagar.service.emiter';
import { LoginComponent } from './component/access-control/login/login.component';
import { ResetPasswordComponent } from './component/access-control/reset-password/reset-password/reset-password.component';
import { Page404Component } from './component/template/page404/page404.component';
import { Page500Component } from './component/template/page500/page500.component';
import { PagePasswordActualizadoComponent } from './component/template/page-password-actualizado/page-password-actualizado.component';
import { HttpConfigInterceptorService } from './services/seguridad_api/configurations/seguridad-interceptor.service';
import { PageTokenInvalidoComponent } from './component/template/page-token-invalido/page-token-invalido.component';
import { PageTokenVencidoComponent } from './component/template/page-token-vencido/page-token-vencido.component';
import { PageCambioPasswordComponent } from './component/template/page-cambio-password/page-cambio-password.component';
import { CookieService } from 'ngx-cookie-service';
import { PrincipalComponent } from './component/main-component/principal/principal.component';
import { LoadingComponent } from './component/util/loading/loading.component';
import { CardsComponent } from './component/util/cards/cards.component';
import { ResumenComponent } from './component/core/gestion/dashboard/resumen/resumen.component';
import { MailModalComponent } from './component/util/mail/mail-modal/mail-modal.component';
import { GenericoModule } from './generico.module';
import { NegocioComponent } from './component/core/negocio/negocio/negocio.component';
import { DatePipe } from '@angular/common';
import { GestionComponent } from './component/core/gestion/gestion.component';
import { ContentComponent } from './component/main-component/content/content.component';
import { IndicadoresComponent } from './component/core/gestion/indicadores/indicadores.component';
import { IndicadoresModule } from './component/core/gestion/indicadores/indicadores.module';
import { ReportesModule } from './component/core/gestion/reportes/reportes.module';
import { ReportesComponent } from './component/core/gestion/reportes/reportes.component';
import { NotificacionesComponent } from './component/core/gestion/notificaciones/notificaciones.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    MenuComponent,
    FooterComponent,
    LoginComponent,
    ResetPasswordComponent,
    Page404Component, Page500Component, PagePasswordActualizadoComponent,
    PageTokenInvalidoComponent,
    PageTokenVencidoComponent,
    PageCambioPasswordComponent,
    ResumenComponent,
    MailModalComponent,
    NegocioComponent,
    PrincipalComponent,
    LoadingComponent,
    CardsComponent,
    ContentComponent,
    GestionComponent,
    IndicadoresComponent,
    ReportesComponent,
    NotificacionesComponent,
  ],
  imports: [
    BrowserModule, 
    HttpClientModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    GenericoModule,
    FlexLayoutModule,
    IndicadoresModule,
    // ,
    // WebSocketModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigInterceptorService, multi: true },
    IndicadorCuentasPorPagarServicioEmiter,
    CookieService,
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
