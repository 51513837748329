import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfiguracionService {

  /* Ambiente de pruebas locales*/
  public servidor = environment.ipRecursos;
  public servidorLocation = this.servidor;
  public urlApi = "/api";
  public urlSeguro = "/seguro"
  public contextoRecurso = "/sistemaPhoenix";
  public servidorRecurso = this.contextoRecurso + "/pages";
  public ipRecursos = environment.ipRecursos.concat(environment.portRecursos);
  public serverUrlApi = environment.apiUrl.concat(environment.port) + this.urlApi;
  public serverUrlApiSegura = environment.apiUrl.concat(environment.port) + this.urlApi + this.urlSeguro;
  public serverUrlRecurso = environment.ipRecursos.concat(environment.portRecursos) + this.contextoRecurso.concat('/pages') + this.urlApi;
}
