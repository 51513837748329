import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { RolesService } from 'src/app/services/seguridad/roles.service';
import { OpcionesRolService } from 'src/app/services/seguridad/opciones-rol.service';
import { ConfiguracionService } from 'src/app/services/seguridad_api/configurations/configuracion.service';

import { Router } from '@angular/router';
import { Roles } from 'src/app/model/seguridad/roles';
import { PrefRoles } from 'src/app/constantes/preferencias.const';
import { PreferenciasService } from 'src/app/services/util/preferencias.service';
import { forkJoin } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { map } from 'rxjs/operators';
import { setLoadingSpinner } from '../../util/Util';
import {MenuItem} from 'primeng/api';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})

export class MenuComponent implements OnInit {


  @Input() openMenu: boolean;
  @Output() responseMenu: EventEmitter<any> = new EventEmitter();

  roles: Roles[];
  selectedRoles: any;
  opcionesPorRol: any[] = [];
  urlEnlace: string;
  items: MenuItem[];
  username: string;
  cia: any;
  logo: any;

  constructor(
    private rolesService: RolesService,
    private opcionesRolService: OpcionesRolService,
    private configuracion: ConfiguracionService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private preferenciaService: PreferenciasService,
    private sharedService: SharedService,
    ) { }

  ngOnInit() {
    this.spinner.show();
    this.urlEnlace = this.configuracion.ipRecursos;
    this.username = sessionStorage.getItem('SESSIONUSER');
    this.cia = localStorage.getItem("data-header");
    if (this.cia){
      this.cia = JSON.parse(atob(this.cia));
    }
    this.obtenerRolesPorUsuario();
  }

  armarMenuItem() {
    let item: any = {}
    this.items = [];

    this.roles.forEach(rol => {
      //console.log('roles:', rol.DESCRIPCION, rol.CODIGO);
      let items: any[] = [];
      this.opcionesPorRol.filter(f=> f.ID_ROL == rol.CODIGO).forEach(opcion => {
        items.push( {label: opcion.DE_OPCN, icon: 'pi pi-pw pi-file', command: (event: Event) => {this.accederEnlace(opcion.DE_PATH_OPCN, opcion.IN_OPCN_JS)} });
        //console.log('opcion:', opcion);
      });

      let itemsReportes: any = [];
      itemsReportes.push({label: 'Reportes'});
 
      //console.log('items:', items);
      items = items.sort((a,b)=> a.label<b.label? -1 : 1);
      //console.log('items_sort', items);
      item = { label: rol.DESCRIPCION, icon: 'pi pi-pw pi-file', items: items };
      this.items.push(item);
    });

    //console.log('this.items', this.items);
  }

  obtenerRolesPorUsuario() {
    
    this.logo = this.cia.DE_LOGO;
    const preferenciaPeriodo = this.preferenciaService.obtenerPreferencias(this.username, '/menu', 'ROLES');
    const roles = this.rolesService.obtenerRolesTipoPorUsuario(this.username, 'E', this.cia.ID_ORGN_EBS);
    forkJoin([preferenciaPeriodo, roles]).subscribe(([preferenciaResponse, rolesResponse]: [any, any]) => {
        //console.log('rolesResponse',rolesResponse);
        let listaRoles: any[] = [];
        if ((rolesResponse)) {
          listaRoles = rolesResponse;
        }
        this.roles = listaRoles;

        if ((preferenciaResponse)) {
          let prefRoles;
          prefRoles = (preferenciaResponse.DE_VALO_PREF) ? preferenciaResponse.DE_VALO_PREF : null;
          if ((prefRoles)) {
            prefRoles = JSON.parse(prefRoles);
            this.selectedRoles = prefRoles;
          } else {
            this.selectedRoles = this.roles[0];
          }
        }

        this.obtenerOpcionesPorRol(this.selectedRoles);
      });
  }

  obtenerOpcionesPorRol(selectRol) {
    const opciones = this.opcionesRolService.obtenerOpcionesPorRolUsuario(this.username, '').pipe(
      map((opcionRolResponse: any) => {
        this.opcionesPorRol = [];
        if ((opcionRolResponse)) {
          this.opcionesPorRol = opcionRolResponse;

          this.armarMenuItem();
        }
      })
    );

    setLoadingSpinner(this.spinner, opciones);
  }

  seleccionarRol() {
    this.guardarPreferencia();
    this.obtenerOpcionesPorRol(this.selectedRoles);
  }

  guardarPreferencia() {
    const prefRoles = new PrefRoles();
    prefRoles.preferencia.CO_USUA = this.username;
    prefRoles.preferencia.DE_VALO_PREF = JSON.stringify(this.selectedRoles);
    this.preferenciaService.guardarPreferencia(prefRoles.preferencia).subscribe();
  }

  accederEnlace(url: string, tipoOpcion: number) {
    if ((tipoOpcion) && tipoOpcion == 1) {
      //console.log('urlMenu', url);
      this.router.navigateByUrl(url);
      //this.responseMenu.emit(false);
    } else {
      window.open(this.urlEnlace.concat(url), "_blank");
    }
  }

}
