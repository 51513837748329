export class Preferencia{
    _id?: String;
    ID_PREF?: Number;
    CO_USUA?: String;
    CO_PANT?: String;
    CO_FUNC?: String;
    CO_PREF?: String;
    DE_VALO_PREF?: string;
    IN_REGI_ACTI?: String;
    CO_USUA_CREA?: String;
    FE_CREA?: Date;
    IP_TERM_CREA?: String;
    CO_USUA_MODI?: String;
    FE_MODI?: String;
    IP_TERM_MODI?: String;
}

export class PreferenciaResponse{
    data: Preferencia[];
}