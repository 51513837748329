<div class="card">
  <p-card header="{{header}}" subheader="Responsable {{responsable}}" [style]="{width: '300px'}"
    styleClass="ui-card-cards">
    <div class="row">
      <div class="p-col-12 p-md-12 p-lg-12">
        <div echarts [options]="optionsCard" class="card-chart"></div>
      </div>
      <div class="p-col-12 p-md-12 p-lg-12" style="text-align: center; background: #eaecff">
        <button class="btn-icon" pTooltip="detalle" tooltipPosition="right" tooltipEvent="hover"
          (click)="showModalDetalle()">
          <i class="fas fa-traffic-light" style="font-size:22px; color: brown" [ngStyle]="{'color': semaforo}"></i>
        </button>
        <button *ngIf="viewDetalle" class="btn-icon" pTooltip="indicadores" tooltipPosition="right" tooltipEvent="hover"
          (click)="verDetalleCard(ruta)">
          <i class="fas fa-file-invoice" style="font-size:22px; color: brown"></i>
        </button>
        <button class="btn-icon" pTooltip="call" tooltipPosition="right" tooltipEvent="hover" (click)="showTopLeft()">
          <i class="fas fa-phone" style="font-size:22px; color: brown"></i>
        </button>
        <button class="btn-icon" pTooltip="send mail" tooltipPosition="right" tooltipEvent="hover"
          (click)="showModalMail()">
          <i class="fas fa-envelope-open-text" style="font-size:22px; color: brown"></i>
        </button>
      </div>
    </div>
  </p-card>
</div>

<p-dialog header="Detalle {{header}}" [(visible)]="display" [modal]="true" [responsive]="true"
  [style]="{width: '800px', minWidth: '150px'}" [minY]="50" [maximizable]="true" [baseZIndex]="10000">
  <div class="p-grid" style="text-align: center">
    <div class="p-col-12 p-md-6 p-lg-4" *ngFor="let body of bodyCard">
      <p-card>
        <div class="p-grid text-center">
          <div class="p-col-12 p-md-6 p-lg-6">
          </div>
          <div class="p-col-12 p-md-6 p-lg-5">
            <i class="{{body.icon}}" style="color: blue"></i><br>
            <label>{{body.header}}</label>
          </div>
        </div>
      </p-card>
    </div>
  </div>
  <p-footer>
    <button type="button" pButton icon="pi pi-check" (click)="display=false" label="Ok"></button>
  </p-footer>
</p-dialog>

<app-mail-modal [header]="header" [displayMail]="displayMail" (closeModal)="cancelarModalMail($event)" [mailFrom]="mailFrom"
[mailTo]="mailTo"></app-mail-modal>