import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { MessageService } from 'primeng/api';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { AuthorizationService } from '../../authorization/authorization.service';
import { ConfiguracionService } from './configuracion.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { UsuariosService } from '../../seguridad/usuarios.service';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class HttpConfigInterceptorService implements HttpInterceptor {

  sesionExpirada = false;

  constructor(
    private messageService: MessageService,
    private usuarioService: UsuariosService,
    private cookieService: CookieService,
    private router: Router,
    private configuracion: ConfiguracionService,
    private spinner: NgxSpinnerService,
    private authorizationService: AuthorizationService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token: string = sessionStorage.getItem('JSESSIONID');
    const refreshToken: string = sessionStorage.getItem('JSESSIONREFID');
    const usuario: string = sessionStorage.getItem('SESSIONUSER');
    if (token) {
      const bearer = 'Bearer '.concat(token).concat(',BearerRefresh ').concat(refreshToken);
      req = req.clone({ headers: req.headers.set('Authorization', bearer) });
      if (req.url.indexOf('acceso_externo.xhtml') === -1) {
        req = req.clone({ headers: req.headers.set('ID', usuario) });
      }
    }
    /*if (!req.headers.has('Content-Type')) {
      req = req.clone({ headers: req.headers.set('Content-Type', 'application/json') });
    }*/
    req = req.clone({ headers: req.headers.set('Accept', 'application/json') });

    const This = this;
    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        this.sesionExpirada = false;
        const response: any = event;
        if ((response) && (response.body) &&
          (response.body.refresh)) {
          this.refrescarToken(response);
        } else {
          if ((response.body) && (response.body.data) && response.body.data == "Token no es valido") {
            // Swal.fire({
            //   title: 'El tiempo para usar la aplicación ha expirado',
            //   text: "Se redireccionará a la página de acceso de la aplicación.",
            //   icon: 'warning',
            //   showCancelButton: false,
            //   confirmButtonColor: '#3085d6',
            //   cancelButtonColor: '#d33',
            //   confirmButtonText: 'Ok'
            // }).then(() => {
            //   this.sesionExpirada = true;
            //   sessionStorage.setItem('SESSIONEXPIRED', String(this.sesionExpirada));
            //   this.removerSession();
            // });
          }
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        this.spinner.hide();
        let data = {};
        console.error(error);
        data = {
          reason: error && error.error.msg ? error.error.msg : '',
          status: error.status,
          success: error && error.error.success ? error.error.success : '',
        };
        This.interceptError(data);
        return throwError(error);
      })
    );
  }

  interceptError(error: any) {
    //
    if (error.status === '401' || error.status === 401) {
      if (error.reason === 'jwt expired' || error.reason === 'Auth token no está presente') {
        const sessionExpired = sessionStorage.getItem('SESSIONEXPIRED');
        if (!(sessionExpired) || sessionExpired == 'false') {
          // Swal.fire({
          //   title: 'El tiempo para usar la aplicación ha expirado',
          //   text: "Se redireccionará a la página de acceso de la aplicación.",
          //   icon: 'warning',
          //   showCancelButton: false,
          //   confirmButtonColor: '#3085d6',
          //   cancelButtonColor: '#d33',
          //   confirmButtonText: 'Ok'
          // }).then(() => {
          //   this.sesionExpirada = true;
          //   sessionStorage.setItem('SESSIONEXPIRED', String(this.sesionExpirada));
          //   this.removerSession();
          // });
        }
      } else {
        this.messageService.add({ severity: 'error', summary: 'Service Message Phoenix', detail: error.reason });
      }
    } else if ((error.status === '500' || error.status === 500) || error.status === '400' || error.status === 400) {
      this.messageService.add({ severity: 'error', summary: 'Service Message Phoenix', detail: error.reason });
    } else if (error.status === '404' || error.status === 404) {
      this.messageService.add({ severity: 'error', summary: 'Service Message Phoenix', detail: 'El servicio no se encuentra disponible' });
    } else if (error.status === '0' || error.status === 0) {
      this.messageService.add({
        severity: 'error', summary: 'Service Message Phoenix',
        detail: 'Los Recursos de Phoenix no se encuentran disponible, Por favor comunicarse con el área de sistemas'
      });
      this.sesionExpirada = true;
      sessionStorage.setItem('SESSIONEXPIRED', String(this.sesionExpirada));
      sessionStorage.clear();
      localStorage.clear();
      this.spinner.show();
      setTimeout(() => {
        this.router.navigateByUrl('/login');
      }, 1000);
    }
  }

  refrescarToken(response: any) {
    sessionStorage.removeItem('JSESSIONID');
    sessionStorage.removeItem('JSESSIONREFID');
    const bearer = response.body.refresh.split(',')[0];
    const bearerRefresh = response.body.refresh.split(',')[1];
    sessionStorage.setItem('JSESSIONID', bearer.slice(7, bearer.length));
    sessionStorage.setItem('JSESSIONREFID', bearerRefresh.slice(14, bearerRefresh.length));
  }

  removerSession() {
    const usuario = sessionStorage.getItem('SESSIONUSER');
    const key = sessionStorage.getItem('KEYSESSION');
    //this.authorizationService.finalizarSesionSinSeguridad(usuario).subscribe({
    this.authorizationService.cerrarSesionSinSeguridad('01', key, usuario).subscribe({
      next: () => {
        const url = this.configuracion.serverUrlRecurso + `/acceso_externo.xhtml?accion=cerrarSession&parametros=0`;
        window.open(url, '_blank');
        window.parent.focus();
        sessionStorage.clear();
        localStorage.clear();
        this.spinner.show();
        this.usuarioService.cerrarSockets();
        setTimeout(() => {
          this.router.navigateByUrl('/login');
        }, 1000);
      }
    });
  }

}
