import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfiguracionService } from '../seguridad_api/configurations/configuracion.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NotificacionService {

  url: string;
  @Output() change: EventEmitter<boolean> = new EventEmitter();

  constructor(private http: HttpClient,
    private configuracion: ConfiguracionService) {
    this.url = this.configuracion.serverUrlApiSegura;
  }

  public obtenerNotificaciones(codUsuario: string, periodo: string, hecho: string): Observable<any> {
    var url = this.url + `/notificaciones?codUsuario=${codUsuario}&periodo=${periodo}&hecho=${hecho}`;
    return this.http.get<any[]>(url).pipe(
      map((response: any) => {
        return response.data;
      })
    );
  }

  public obtenerResumenNoti(codUsuario: string): Observable<any> {
    var url = this.url + `/resumen-notificacion?codUsuario=${codUsuario}`;
    return this.http.get<any[]>(url).pipe(
      map((response: any) => {
        return response.data;
      })
    );
  }

  public actualizarNotificacionVisto(notificacion: any): Observable<any> {
    let body = {};
    var url = this.url + `/notificacion-visto/${notificacion.ID_NOTI}`;
    return this.http.put<any>(url, body).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  public actualizarNotificacionVistoPorUsuario(idUsuario: any): Observable<any> {
    let body = {};
    var url = this.url + `/notificacion-visto-usuario/${idUsuario}`;
    return this.http.put<any>(url, body).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  public informarRefresh(refrescando: boolean) {
    this.change.emit(refrescando);
  }
}
