import { Directive, HostListener, Output, EventEmitter, ElementRef, Self, Renderer2, forwardRef } from "@angular/core";
import {
    A,
    Z,
} from '@angular/cdk/keycodes';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
    selector: '[uppercase]',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => UppercaseDirective),
            multi: true,
        },
    ],
})
export class UppercaseDirective implements ControlValueAccessor {

    _onChange: (_: any) => void;

    _touched: () => void;

    @Output() ngModelChange: EventEmitter<any> = new EventEmitter();
    value: any;

    constructor(@Self() private _el: ElementRef, private _renderer: Renderer2) {

    }

    @HostListener('keyup', ['$event']) onKeyDown(evt: any) {
        const keyCode = evt.keyCode;
        if ((keyCode >= A && keyCode <= Z) || keyCode == 229) {
            const value = this._el.nativeElement.value.toUpperCase();
            this._renderer.setProperty(this._el.nativeElement, 'value', value);
            this._onChange(value);
            evt.preventDefault();
        } else {
            this._renderer.setProperty(this._el.nativeElement, 'value', this._el.nativeElement.value);
            this._onChange(this._el.nativeElement.value);
            evt.preventDefault();
        }
    }

    @HostListener('blur', ['$event'])
    onBlur() {
        this._touched();
        const value = this._el.nativeElement.value.toUpperCase();
        this._renderer.setProperty(this._el.nativeElement, 'value', value);
        this._onChange(value);
    }

    /** Implementation for ControlValueAccessor interface */
    writeValue(value: any): void {
        this._renderer.setProperty(this._el.nativeElement, 'value', value);
    }

    /** Implementation for ControlValueAccessor interface */
    registerOnChange(fn: (_: any) => void): void {
        this._onChange = fn;
    }

    /** Implementation for ControlValueAccessor interface */
    registerOnTouched(fn: () => void): void {
        this._touched = fn;
    }

    /** Implementation for ControlValueAccessor interface */
    setDisabledState(isDisabled: boolean): void {
        this._renderer.setProperty(this._el.nativeElement, 'disabled', isDisabled);
    }
}