export var ColoresHtml = [
    "rgba(255, 99, 132, 0.2)",
    "rgba(54, 162, 235, 0.2)",
    "rgba(255, 206, 86, 0.2)",
    "rgba(75, 192, 192, 0.2)",
    "rgba(153, 102, 255, 0.2)",
    "rgba(255, 159, 64, 0.2)",
    "rgba(255, 141, 198, 0.2)",
    "rgba(64, 255, 159, 0.2)",
    "rgba(255, 44, 44, 0.2)"
];

export var ColoresBorderHtml = [
    'rgba(255,99,132,1)',
    'rgba(54, 162, 235, 1)',
    'rgba(255, 206, 86, 1)',
    'rgba(75, 192, 192, 1)',
    'rgba(153, 102, 255, 1)',
    'rgba(255, 159, 64, 1)',
    'rgba(255, 141, 198, 1)',
    'rgba(64, 255, 159, 1)',
    'rgba(255, 44, 44, 1)'
]

export const ColoresSemaforo = {
    rojo: '#FC3314',
    ambar: '#FEB312',
    verde: '#53CB1A'
} 