<!-- <p-panel header="REPORTES" [toggleable]="true" *ngIf="visualizarReportes"
    [style]="{'margin-bottom':'10px', 'text-align': 'center', 'cursor': 'pointer'}">
    <div class="p-grid" style="cursor: pointer;">
        <div class="p-col-12 p-md-6 p-lg-4" *ngFor="let reporte of listadoReportes" [routerLink]="[reporte.link]">
            <p-card header="" [style]="{width: '100%', background: reporte.background, border: reporte.border}">
                <div><i class="{{reporte.icon}}"></i><b> {{reporte.title}}</b></div>
            </p-card>
        </div>
    </div>

</p-panel> -->
<h1>reportes</h1>
<!-- <router-outlet></router-outlet> -->