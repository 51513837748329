import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { IndicadoresRoutingModule } from './indicadores-routing.module';
import { GenericoModule } from 'src/app/generico.module';
import { CuentasPorCobrarCardComponent } from './indicador-gestion/cuentas-por-cobrar-card/cuentas-por-cobrar-card.component';
import { CuentasPorPagarCardComponent } from './indicador-gestion/cuentas-por-pagar-card/cuentas-por-pagar-card.component';
import { TotalVentasCardComponent } from './indicador-gestion/total-ventas-card/total-ventas-card.component';
import { GraficoVentasComponent } from './indicador-gestion/ventas/grafico-ventas/grafico-ventas.component';
import { DetalleVentasComponent } from './indicador-gestion/ventas/detalle-ventas/detalle-ventas.component';


@NgModule({
  declarations: [
    CuentasPorCobrarCardComponent,
    CuentasPorPagarCardComponent,
    TotalVentasCardComponent,
    DetalleVentasComponent,
    GraficoVentasComponent
  ],
  imports: [
    CommonModule,
    IndicadoresRoutingModule,
    GenericoModule,
  ],
  exports: [
    CuentasPorCobrarCardComponent,
    CuentasPorPagarCardComponent,
    TotalVentasCardComponent,
    DetalleVentasComponent,
    GraficoVentasComponent
  ],
  schemas: [ NO_ERRORS_SCHEMA ]
})

export class IndicadoresModule { }
