<div class="wrapper fadeInDown">
  <div id="formContent">
    <p-card header="Contraseña Actualizada" subheader="Su contraseña ha sido cambiada, por favor acceda nuevamente" [style]="{width: '350px'}"
      styleClass="ui-card-shadow card-style">
      <p-header style="text-align: center">
        <img class="center" src="Card" src="./../../../../assets/icons/icon-exito.png" id="icon"
          alt="Token Invalido" style="width: 50%; height: 50%;" />
      </p-header>
      <div class="ui-g ui-fluid">
        <div class="ui-g-12 ui-md-12">
          <br><br><br><br>
          <div class="ui-g form-group">
            <button pButton type="button" label="Ir a la Página de Inicio" [routerLink]="['/']"
              class="ui-button-raised ui-button-info"></button>
          </div>
        </div>
      </div>

    </p-card>
  </div>
</div>