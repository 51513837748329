<!-- <div id="divSpinner" *ngIf="cargandoInfo" style=" text-align: center">
  <app-loading></app-loading>
</div> -->
<div class="p-grid" *ngIf="!cargandoInfo">
  <div class="p-col-12 p-md-12 p-lg-12">
    <p-card styleClass="ui-card-shadow card-style-gris">
      <div class="p-grid p-justify-center">
        <div class="p-col-6 p-md-2 p-lg-2">
          <label class="ui-outputlabel ui-widget">
            Compañía:
          </label>
        </div>
        <div class="p-col-6 p-md-4 p-lg-4">
          <p-dropdown [options]="companiaSelect" optionLabel="DE_NOMB_ORGN" optionValue="CO_ORGN" [(ngModel)]="compania"
            (onChange)="buscarInformacionVentas()" filter="true"></p-dropdown>
        </div>
        <div class="p-col-6 p-md-2 p-lg-2">
          <label class="ui-outputlabel ui-widget">
            Período:
          </label>
        </div>
        <div class="p-col-6 p-md-4 p-lg-4">
          <p-dropdown [options]="periodoSelect" optionLabel="DE_NOMB_PRDO" optionValue="ID_PRDO" [(ngModel)]="periodo"
            (onChange)="buscarInformacionVentas()" filter="true">
          </p-dropdown>
        </div>
      </div>
      <div class="p-grid">
        <div class="p-col-6 p-md-2 p-lg-2">
          <label class="ui-outputlabel ui-widget">
            Línea:
          </label>
        </div>
        <div class="p-col-6 p-md-4 p-lg-4">
          <p-dropdown [options]="lineaSelect" optionLabel="DE_TIPO_LNEA" optionValue="CO_TIPO_LNEA" [(ngModel)]="linea"
            (onChange)="buscarInformacionPorLnea($event)" filter="true"></p-dropdown>
        </div>
        <div class="p-col-6 p-md-2 p-lg-2">
          <label class="ui-outputlabel ui-widget">
            Vendedor:
          </label>
        </div>
        <div class="p-col-6 p-md-4 p-lg-4">
          <p-dropdown [options]="vendedorSelect" optionLabel="DE_NOMB_VEND" optionValue="ID_VEND" [(ngModel)]="vendedor"
            (onChange)="buscarInformacionPorVendedor($event)" filter="true">
          </p-dropdown>
        </div>
      </div>
      <br>
    </p-card>
  </div>
  <div class="p-col-12 p-md-12 p-lg-6">
    <p-card styleClass="ui-card-shadow card-dodgerblue">
      <div class="p-grid">
        <div class="p-col-6 p-md-5 p-lg-5">
          <label class="ui-outputlabel ui-widget">
            <b> Meta:</b>
          </label>
        </div>
        <div class="p-col-6 p-md-7 p-lg-7 div-right">
          <span> ${{meta |  number:'1.2'}}</span>
        </div>
        <div class="p-col-6 p-md-5 p-lg-5">
          <label class="ui-outputlabel ui-widget">
            <b> Total Facturado:</b>
          </label>
        </div>
        <div class="p-col-6 p-md-7 p-lg-7 div-right">
          <span> ${{totalMontoDolares |  number:'1.2'}}</span>
        </div>
      </div>
    </p-card>
  </div>
  <div class="p-col-12 p-md-12 p-lg-6">
    <p-card styleClass="ui-card-shadow card-green">
      <div class="p-grid">
        <div class="p-col-6 p-md-5 p-lg-5">
          <label class="ui-outputlabel ui-widget">
            <b> Diferencia ($):</b>
          </label>
        </div>
        <div class="p-col-6 p-md-7 p-lg-7 div-right">
          <span> (${{diferencia |  number:'1.2'}})</span>
        </div>
        <div class="p-col-6 p-md-5 p-lg-5">
          <label class="ui-outputlabel ui-widget">
            <b>Avance (%):</b>
          </label>
        </div>
        <div class="p-col-6 p-md-7 p-lg-7 div-right">
          <span *ngIf="avancePeriodo>0 && avancePeriodo<100"> {{avancePeriodo | number:'1.1-2'}}%</span>
          <span *ngIf="avancePeriodo==0 || avancePeriodo==100"> {{avancePeriodo}}%</span>
        </div>
      </div>
    </p-card>
  </div>
  <div class="p-col-12 p-md-12 p-lg-6 divGrafico">
    <app-grafico-ventas [nameGrafico]="'Cumplimiento de Meta (Último 6 meses)'" [dataGrafico]="dataGrafico"
      [options]="options" [msgs]="msgs" [visualizarGrafico]="visualizarGrafico"></app-grafico-ventas>
  </div>
  <div class="p-col-12 p-md-12 p-lg-6 divTable">
    <p-panel ngClass="panel-indicadores" [style]="{'margin-bottom':'10px', 'text-align': 'center'}"
      [showHeader]="false">
      <app-table #table [data]="data" [row]="10" [totalRecords]="totalRecords" [column]="cols" [scrollHeight]="'325px'"
        [loading]="loading" [dataTotal]="dataTotal" [exportExcel]="true" [dataExport]="dataExcel"
        [nombreArchivo]="nombreArchivo" [paginator]="true">
      </app-table>
    </p-panel>
  </div>
</div>