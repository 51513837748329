import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfiguracionService } from '../seguridad_api/configurations/configuracion.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class VendedoresService {

  url: string;

  constructor(
    private http: HttpClient,
    private configuracion: ConfiguracionService) {
    this.url = this.configuracion.serverUrlApiSegura;
  }


  obtenerVendPorUsuarioLinea(usuario: number, coTipoLnea: string, idUnidOper: number): Observable<any> {
    const url = `${this.url}/vend-por-usuario-linea?idUsuario=${usuario}&coTipoLnea=${coTipoLnea}&idUnidOper=${idUnidOper}`;
    return this.http.get<any>(url).pipe(
      map((response: any) => {
        return response.data;
      })
    );
  }

  obtenerVendedorMeta(vendedores: string): Observable<any> {
    const url = `${this.url}/vendedor-meta?vendedores=${vendedores}`;
    return this.http.get<any>(url).pipe(
      map((response: any) => {
        return response.data;
      })
    );
  }

}
