export const HTMLRecuperacionContrasena = 
'<!DOCTYPE html><head>'+
'<meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />'+
'<title></title>'+
'<style>'+
'body {'+
'	background-color: #e5e0e0;'+
'}'+
'.sombraclara {'+
'	float: center;'+
'	width: 100%;'+
'	font-size: 12px;'+
'	font-family: Arial, Helvetica, sans-serif;'+
'	color: #434343;'+
'}'+
'.titulo {'+
'	font-family: Arial, Helvetica, sans-serif;'+
'	font-size: 20px;'+
'	color: #999999;'+
'	text-align: right;'+
'}'+
'.texto {'+
'	font-family: Arial, Helvetica, sans-serif;'+
'	color: #434343;'+
'	font-size: 13px;'+
'}'+
'.table-1 {'+
'	background: #FFFFFF;'+
'	width: 600px;'+
'	padding: 10px 40px 10px 40px;'+
'	margin: 0 auto;'+
'}'+
'.table-0 {'+
'    width: 100%;'+
'    height: 100%;'+
'    background-color: #f0f2f4;'+
'	border-spacing: 0px;'+
'}'+
'</style>'+
'</head>'+
'<body>'+
'	<table class="table-0">'+
'		<tr>'+
'			<td style="" height="80">'+
'			</td>'+
'		</tr>'+
'		<tr>'+
'			<td>'+
'				<table class="table-1">'+
'					<tr>'+
'						<td width="95%" height="50" style="text-align: center;">'+
'							<img src="https://www.dest.pe/wp-content/phoenix-image/phoenix_logo.png" width="50" />'+
'						    <span class="titulo"> <em>SISTEMA PHOENIX</em></span> <br />'+
'						</td>'+
'					</tr>'+
'					<tr style="height: 25px;" >'+
'						<td></td>'+
'					</tr>'+
'					<tr>'+
'						<td height="30" colspan="5" class="texto">'+
'							<p>'+
'								<b>Estimado(a) <span>{nombreUsuario}</span></b>'+
'							</p>'+
'							<p>'+
'								Para atender su solicitud de restablecimiento de contraseña, se	ha generado el siguiente link :<br />'+
'							</p>'+
'							<div class="sombraclara" style="text-align: center;">'+
'								<a href="{link}">Haga click aquí para restablecer contraseña</a> <br />'+
'							</div> <br />'+
'							<p>'+
'							Si el link superior no funciona, copie esto en su navegador web:'+
'							</p>'+
'							<span style="color: #695c5c;">{link}</span>'+
'							<p>'+
'							Se agradece tener en consideración las siguientes recomendaciones:'+
'							</p>'+
'							<ul>'+
'								<li>Considerar que el link generado expirará dentro de <span>{tmpoExpiPass}</span> minutos.</li>'+
'								<li>El presente es un correo desatendido, por favor no responder este email.</li>'+
'								<li>Si tiene alguna consulta por favor comunicarse al siguiente correo: sistemas@dest.com.pe</li>'+
'							</ul> <br />'+
'							<p style="height: 20px;" >'+
'							</p>'+
'							<p>'+
'								<img src="https://www.dest.pe/wp-content/phoenix-image/logo_derteano_20.jpg" width="60" />'+
'							</p>'+
'							<p>'+
'								Atentamente, <br /> Sistemas Derteano &#38; Stuker'+
'							</p>'+
'						</td>'+
'					</tr>'+
'				</table>'+
'			</td>'+
'		</tr>'+
'		<tr>'+
'			<td height="80">'+
'			</td>'+
'		</tr>'+
'	</table>'+
'</body>'+
'</html>'