import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-reportes',
  templateUrl: './reportes.component.html',
  styleUrls: ['./reportes.component.scss']
})
export class ReportesComponent implements OnInit {

  costoVenta: boolean;
  ctaLnaCrdt: boolean;

  listaOpciones: any = [];
  cardActive: string;
  visualizarReportes = false;
  listadoReportes: any;
  
  constructor(private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    // this.seleccionarReporte('1');
    // this.listaOpciones = [
    //   { opcion: '1', label: 'Costo de Venta' },
    //   { opcion: '2', label: 'Cuenta Línea de Crédito' }
    // ];
    console.log('ngOnInit-reportes')
  }

  cuentasPagar():void {
    console.log('cuentasPagar');
    this.router.navigate(['cuentas-por-pagar'], {relativeTo: this.route});
  }

  createEstructuraReportes(listaReporte: any[]) {
    this.listadoReportes = ((listaReporte) && listaReporte.length > 0) ? listaReporte : [];
  }

  seleccionarReporte(opcion) {
    switch (opcion) {
      case '1':
        this.costoVenta = true;
        this.ctaLnaCrdt = false;
        break;
      case '2':
        this.costoVenta = false;
        this.ctaLnaCrdt = true;
        break;
    }
    if (this.cardActive && this.cardActive != null && this.cardActive.trim() != '') {
      document.getElementById('card'.concat(this.cardActive)).classList.remove('card-active-reporte')
    }
    setTimeout(() => {
      var elementIndi = document.getElementById('card'.concat(opcion));
      if (elementIndi && elementIndi != null) {
        document.getElementById('card'.concat(opcion)).classList.add('card-active-reporte');
        this.cardActive = opcion;
      }
    }, 0.1);
  }

  verNotificaciones() {
    this.router.navigateByUrl('/sistema-phoenix/gestion/notificacion');
  }
}
