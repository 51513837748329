<p-panel header="INDICADORES" [toggleable]="true" [style]="{'margin-bottom':'10px', 'text-align': 'center'}" *ngIf="visualizarIndicadores">
    <div class="p-grid">
        <div class="p-col-4">
            <app-total-ventas-card [cia]="cia" *ngIf="indicadorTotalVentas"></app-total-ventas-card>
        </div>
        <div class="p-col-4">
           <app-cuentas-por-cobrar-card [cia]="cia" *ngIf="indicadorCuentasPorCobrar"></app-cuentas-por-cobrar-card>
        </div> 
        <div class="p-col-4">
            <app-cuentas-por-pagar-card [cia]="cia" *ngIf="indicadorCuentasPorPagar"></app-cuentas-por-pagar-card>
        </div>
    </div>
</p-panel>  