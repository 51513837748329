import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ConfiguracionService } from '../seguridad_api/configurations/configuracion.service';
import { Opciones, ResponseOpciones } from 'src/app/model/seguridad/opciones-menu';

@Injectable({
  providedIn: 'root'
})
export class OpcionesRolService {

  url: string;
  constructor(private http: HttpClient,
    private configuracion: ConfiguracionService) {
    this.url = this.configuracion.serverUrlApiSegura;
  }

  public obtenerOpcionesPorRol(idRol: string): Observable<any[]> {
    let url = this.url + `/opciones-rol?idRol=${idRol}`;
    return this.http.get<any[]>(url).pipe(
      map((response: any) => {
        return response.data;
      })
    )
  }

  public obtenerOpcionesPorRolUsuario(codUsuario:string, idRol: string): Observable<Opciones[]> {
    let url = this.url + `/opciones-rol-usuario?usuario=${codUsuario}&rol=${idRol}`;
    return this.http.get<ResponseOpciones>(url).pipe(
      map((response: ResponseOpciones) => {
        return response.data;
      })
    )
  }

}
