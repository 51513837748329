import { Preferencia } from '../model/preferencia.class';

export class PrefTipoIndicadorCuentaXPagar {
    preferencia: Preferencia = new Preferencia();
    constructor() {
        this.preferencia.CO_PANT = '/indicadores';
        this.preferencia.CO_FUNC = 'BUSQUEDA';
        this.preferencia.CO_PREF = 'TIPO_INDICADOR';
    }
}

export class PrefCompaniaCuentaXPagar {
    preferencia: Preferencia = new Preferencia();
    constructor() {
        this.preferencia.CO_PANT = '/indicadores/cuentasxpagar';
        this.preferencia.CO_FUNC = 'BUSQUEDA';
        this.preferencia.CO_PREF = 'COMPAÑIA';
    }
}

export class PrefCompaniaCuentaXCobrar {
    preferencia: Preferencia = new Preferencia();
    constructor() {
        this.preferencia.CO_PANT = '/indicadores/cuentasxcobrar';
        this.preferencia.CO_FUNC = 'BUSQUEDA';
        this.preferencia.CO_PREF = 'COMPAÑIA';
    }
}

export class PrefCompaniaVentas {
    preferencia: Preferencia = new Preferencia();
    constructor() {
        this.preferencia.CO_PANT = '/indicadores/ventas';
        this.preferencia.CO_FUNC = 'BUSQUEDA';
        this.preferencia.CO_PREF = 'COMPAÑIA';
    }
}

export class PrefPeriodoVentas {
    preferencia: Preferencia = new Preferencia();
    constructor() {
        this.preferencia.CO_PANT = '/indicadores/ventas';
        this.preferencia.CO_FUNC = 'BUSQUEDA';
        this.preferencia.CO_PREF = 'PERIODO';
    }
}

export class PrefLineaVentas {
    preferencia: Preferencia = new Preferencia();
    constructor() {
        this.preferencia.CO_PANT = '/indicadores/ventas';
        this.preferencia.CO_FUNC = 'BUSQUEDA';
        this.preferencia.CO_PREF = 'LÍNEA';
    }
}

export class PrefVendedorVentas {
    preferencia: Preferencia = new Preferencia();
    constructor() {
        this.preferencia.CO_PANT = '/indicadores/ventas';
        this.preferencia.CO_FUNC = 'BUSQUEDA';
        this.preferencia.CO_PREF = 'VENDEDOR';
    }
}

export class PrefPeriodoNotificacion {
    preferencia: Preferencia = new Preferencia();
    constructor() {
        this.preferencia.CO_PANT = '/notificacion';
        this.preferencia.CO_FUNC = 'BUSQUEDA';
        this.preferencia.CO_PREF = 'PERIODO';
    }
}

export class PrefHechoNotificacion {
    preferencia: Preferencia = new Preferencia();
    constructor() {
        this.preferencia.CO_PANT = '/notificacion';
        this.preferencia.CO_FUNC = 'BUSQUEDA';
        this.preferencia.CO_PREF = 'HECHO';
    }
}

export class PrefPeriodoInicioReporte {
    preferencia: Preferencia = new Preferencia();
    constructor() {
        this.preferencia.CO_PANT = '/reportes';
        this.preferencia.CO_FUNC = 'BUSQUEDA';
        this.preferencia.CO_PREF = 'PERIODO_INICIO';
    }
}

export class PrefPeriodoFinReporte {
    preferencia: Preferencia = new Preferencia();
    constructor() {
        this.preferencia.CO_PANT = '/reportes';
        this.preferencia.CO_FUNC = 'BUSQUEDA';
        this.preferencia.CO_PREF = 'PERIODO_FIN';
    }
}

export class PrefOrganizacionReporte {
    preferencia: Preferencia = new Preferencia();
    constructor() {
        this.preferencia.CO_PANT = '/reportes';
        this.preferencia.CO_FUNC = 'BUSQUEDA';
        this.preferencia.CO_PREF = 'ORGANIZACION';
    }
}

export class PrefRoles {
    preferencia: Preferencia = new Preferencia();
    constructor() {
        this.preferencia.CO_PANT = '/menu';
        this.preferencia.CO_FUNC = 'BUSQUEDA';
        this.preferencia.CO_PREF = 'ROLES';
    }
}

export class PrefTransferenciaCompania {
    preferencia: Preferencia = new Preferencia();
    constructor() {
        this.preferencia.CO_PANT = '/inventario/transferencia-interorganizacion';
        this.preferencia.CO_FUNC = 'BUSQUEDA';
        this.preferencia.CO_PREF = 'TRANSFERENCIA-COMPANIA';
    }
}

export class PrefTransferenciaOrgOrigen {
    preferencia: Preferencia = new Preferencia();
    constructor() {
        this.preferencia.CO_PANT = '/inventario/transferencia-interorganizacion';
        this.preferencia.CO_FUNC = 'BUSQUEDA';
        this.preferencia.CO_PREF = 'TRANSFERENCIA-ORIGEN';
    }
}

export class PrefTransferenciaOrgDestino {
    preferencia: Preferencia = new Preferencia();
    constructor() {
        this.preferencia.CO_PANT = '/inventario/transferencia-interorganizacion';
        this.preferencia.CO_FUNC = 'BUSQUEDA';
        this.preferencia.CO_PREF = 'TRANSFERENCIA-DESTINO';
    }
}

export class PrefTransferenciaCompaniaAlmacen {
    preferencia: Preferencia = new Preferencia();
    constructor() {
        this.preferencia.CO_PANT = '/inventario/transferencia-almacen';
        this.preferencia.CO_FUNC = 'BUSQUEDA';
        this.preferencia.CO_PREF = 'TRANSFERENCIA-COMPANIA';
    }
}

export class PrefTransferenciaAlmacenOrigen {
    preferencia: Preferencia = new Preferencia();
    constructor() {
        this.preferencia.CO_PANT = '/inventario/transferencia-almacen';
        this.preferencia.CO_FUNC = 'BUSQUEDA';
        this.preferencia.CO_PREF = 'TRANSFERENCIA-ORIGEN';
    }
}

export class PrefArticuloCompania {
    preferencia: Preferencia = new Preferencia();
    constructor() {
        this.preferencia.CO_PANT = '/compras/productos';
        this.preferencia.CO_FUNC = 'BUSQUEDA';
        this.preferencia.CO_PREF = 'ARTICULO-COMPANIA';
    }
}

export class PrefArticuloAlmacen {
    preferencia: Preferencia = new Preferencia();
    constructor() {
        this.preferencia.CO_PANT = '/compras/productos';
        this.preferencia.CO_FUNC = 'BUSQUEDA';
        this.preferencia.CO_PREF = 'ARTICULO-ALMACEN';
    }
}

export class PrefFacturaCompania {
    preferencia: Preferencia = new Preferencia();
    constructor() {
        this.preferencia.CO_PANT = '/pagos/factura-proveedor';
        this.preferencia.CO_FUNC = 'BUSQUEDA';
        this.preferencia.CO_PREF = 'FACTURA-COMPANIA';
    }
}

export class PrefFacturaFechaInicio {
    preferencia: Preferencia = new Preferencia();
    constructor() {
    this.preferencia.CO_PANT = '/pagos/factura-proveedor';
    this.preferencia.CO_FUNC = 'BUSQUEDA';
    this.preferencia.CO_PREF = 'FACTURA-FECHA_INICIO';
    }
}

export class PrefFacturaFechaFin {
    preferencia: Preferencia = new Preferencia();
    constructor() {
        this.preferencia.CO_PANT = '/pagos/factura-proveedor';
        this.preferencia.CO_FUNC = 'BUSQUEDA';
        this.preferencia.CO_PREF = 'FACTURA-FECHA_FIN';
    }
}

export class PrefFacturaProveedor {
    preferencia: Preferencia = new Preferencia();
    constructor() {
        this.preferencia.CO_PANT = '/pagos/factura-proveedor';
        this.preferencia.CO_FUNC = 'BUSQUEDA';
        this.preferencia.CO_PREF = 'FACTURA-PROVEEDOR';
    }
}

export class PrefFacturaEtapa {
    preferencia: Preferencia = new Preferencia();
    constructor() {
        this.preferencia.CO_PANT = '/pagos/factura-proveedor';
        this.preferencia.CO_FUNC = 'BUSQUEDA';
        this.preferencia.CO_PREF = 'FACTURA-ETAPA';
    }
}

export class PrefFacturaEstado {
    preferencia: Preferencia = new Preferencia();
    constructor() {
        this.preferencia.CO_PANT = '/pagos/factura-proveedor';
        this.preferencia.CO_FUNC = 'BUSQUEDA';
        this.preferencia.CO_PREF = 'FACTURA-ESTADO';
    }
}