import { Component, OnInit, ViewChild, EventEmitter } from '@angular/core';
import { VentasService } from 'src/app/services/indicadores/ventas.service';
import { SelectItem, Message } from 'primeng/api';
import { forkJoin } from 'rxjs';
import { ParametrosService } from 'src/app/services/general/parametros.service';
import { UsuarioLineaService } from 'src/app/services/seguridad/usuario-linea.service';
import { VendedoresService } from 'src/app/services/vendedores/vendedores.service';
import { fromStringToDate } from 'src/app/component/util/Util';
import { PreferenciasService } from 'src/app/services/util/preferencias.service';
import { Preferencia } from 'src/app/model/preferencia.class';
import { PrefLineaVentas, PrefVendedorVentas, PrefCompaniaVentas, PrefPeriodoVentas } from 'src/app/constantes/preferencias.const';
import { UsuariosService } from 'src/app/services/seguridad/usuarios.service';
import { MaestroService } from 'src/app/services/maestros/maestro.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-detalle-ventas',
  templateUrl: './detalle-ventas.component.html',
  styleUrls: ['./detalle-ventas.component.scss']
})
export class DetalleVentasComponent implements OnInit {

  usuario: string;
  periodos: any = { "ENE": 0, "FEB": 1, "MAR": 2, "ABR": 3, "MAY": 4, "JUN": 5, "JUL": 6, "AGO": 7, "SEP": 8, "OCT": 9, "NOV": 10, "DIC": 11 };
  periodosArray = ["ENE", "FEB", "MAR", "ABR", "MAY", "JUN", "JUL", "AGO", "SEP", "OCT", "NOV", "DIC"];

  data: any = [];
  dataExcel: any = [];
  dataTotal: any = [];
  dataGrafico: any = [];
  totalRecords: any;
  cols: any = [];
  loading: boolean;
  options: any;

  lineaSelect: any[];
  vendedorSelect: any[] = [];
  linea: any;
  vendedor: any;

  cargandoInfo = true;
  //combo
  compania: any;
  periodo: any;
  companiaSelect: SelectItem[] = [];
  periodoSelect: SelectItem[] = [];

  meta = 0;
  totalMontoDolares = 0;
  diferencia = 0;
  avancePeriodo: number = 0;

  numeroPeriodos = 6;
  metaPeriodoPorVendedor: any[] = [];

  visualizarGrafico: boolean = true;
  msgs: Message[] = [];

  nombreArchivo: string;
  datosUsuario: any;

  @ViewChild("table") table: any;

  constructor(private ventasService: VentasService,
    private parametroService: ParametrosService,
    private usuarioLineaService: UsuarioLineaService,
    private vendedoresService: VendedoresService,
    private preferenciaService: PreferenciasService,
    private usuarioService: UsuariosService,
    private maestroService: MaestroService,
    private spinner: NgxSpinnerService) { this.spinner.show(); }

  ngOnInit() {
    console.log('ngOnInit', 'detalle-ventas');
    this.usuario = sessionStorage.getItem('SESSIONUSER');
    const _this = this;
    this.ventasService.socketEmitter.subscribe((resp) => {
      if (resp) _this.obtenerVendedores(null);
    });

    this.cols = [
      { field: 'VENDEDOR', header: 'Vendedor', type: 'label', filter: false, width: "30%" },
      { field: 'NOMCOMPLETO', attr: 'NUMDOCIDENTIDAD', header: 'Nombre/Razón Social', type: 'concat', filter: false, width: "45%" },
      { field: 'MONTO_DOLARES', header: 'Monto Dólares', type: 'doller', filter: false, width: "25%" }
    ];
    
    this.obtenerDatosUsuario();
  }

  obtenerDatosUsuario() {
    this.usuarioService.obtenerUsuarioSeguro(this.usuario).subscribe({
      next: (usuario: any) => {
        if ((usuario)) {
          this.datosUsuario = usuario;
          this.obtenerCombos();
        } else {
          this.spinner.hide();
        }
      }
    });
  }

  obtenerCombos() {
    this.companiaSelect = [];
    this.periodoSelect = [];
    let preferenciasCompania = this.preferenciaService.obtenerPreferencias(this.usuario, '/indicadores/ventas', 'COMPAÑIA');
    let preferenciaPeriodo = this.preferenciaService.obtenerPreferencias(this.usuario, '/indicadores/ventas', 'PERIODO');
    let preferenciaLinea = this.preferenciaService.obtenerPreferencias(this.usuario, '/indicadores/ventas', 'LÍNEA');
    let preferenciaVendedor = this.preferenciaService.obtenerPreferencias(this.usuario, '/indicadores/ventas', 'VENDEDOR');
    //let compania = this.parametroService.getCompania();
    let compania = this.maestroService.obtenerUnidadOperativa(this.datosUsuario.ID_USUA);
    let periodo = this.parametroService.getPeriodo();
    let lineaPorUsuario = this.usuarioLineaService.obtenerLineaPorUsuario(this.datosUsuario.ID_USUA);
    forkJoin(preferenciasCompania, preferenciaPeriodo, preferenciaLinea, preferenciaVendedor, compania, periodo, lineaPorUsuario)
      .subscribe((
        [preferenciasCompania, preferenciaPeriodo, preferenciaLinea, preferenciaVendedor, companiaResponse, periodoResponse, lineaPorUsuarioResponse]
          : [Preferencia, Preferencia, Preferencia, Preferencia, any, any, any]
      ) => {
        this.companiaSelect = companiaResponse;
        this.periodoSelect = periodoResponse;

        let prefCompania;
        if (preferenciasCompania && preferenciasCompania != null) {
          prefCompania = preferenciasCompania.DE_VALO_PREF && preferenciasCompania.DE_VALO_PREF != null ? preferenciasCompania.DE_VALO_PREF : null;
          if (prefCompania && prefCompania != null) {
            prefCompania = JSON.parse(prefCompania);
          }
        }

        if (prefCompania && prefCompania != null) {
          this.compania = prefCompania;
        } else {
          this.compania = (this.companiaSelect != undefined && this.companiaSelect != null) ? this.companiaSelect[0] : null;
        }

        let prefPeriodo;
        if (preferenciaPeriodo && preferenciaPeriodo != null) {
          prefPeriodo = preferenciaPeriodo.DE_VALO_PREF && preferenciaPeriodo.DE_VALO_PREF != null ? preferenciaPeriodo.DE_VALO_PREF : null;
          if (prefCompania && prefCompania != null) {
            prefPeriodo = JSON.parse(prefPeriodo);
          }
        }

        if (prefPeriodo && prefPeriodo != null) {
          this.periodo = prefPeriodo;
        } else {
          var date = new Date();
          var indice = this.periodoSelect.length - date.getMonth() - 1;
          this.periodo = (this.periodoSelect != undefined && this.periodoSelect != null) ? this.periodoSelect[indice] : null;
        }

        let prefLinea;
        if (preferenciaLinea && preferenciaLinea != null) {
          prefLinea = preferenciaLinea.DE_VALO_PREF && preferenciaLinea.DE_VALO_PREF != null ? preferenciaLinea.DE_VALO_PREF : null;
          if (prefLinea && prefLinea != null) {
            prefLinea = JSON.parse(prefLinea);
          }
        }

        if ((lineaPorUsuarioResponse) && lineaPorUsuarioResponse.length > 0) {
          this.lineaSelect = lineaPorUsuarioResponse; //.map(x => x.flex[0]);
        }

        if (prefLinea && prefLinea != null) {
          this.linea = prefLinea;
        } else {
          this.linea = ((this.lineaSelect) && this.lineaSelect.length > 0) ? this.lineaSelect[0] : this.lineaSelect.push({ 'DE_TIPO_LNEA': 'DATA NOT FOUND', 'CO_TIPO_LNEA': '' });
        }

        this.obtenerVendedores(preferenciaVendedor);
      })
  }

  obtenerVendedores(preferenciaVendedor: Preferencia) {
    const idUnidOper = this.compania && this.compania != null ? this.compania.ID_ORGN_EBS : '';
    let coTipoLnea = '';
    
    coTipoLnea = this.linea && this.linea != null ? this.linea.CO_TIPO_LNEA : '';
    if (idUnidOper.toString().toUpperCase() == "201".toUpperCase()) {
      coTipoLnea = 'I';
    }

    this.vendedoresService.obtenerVendPorUsuarioLinea(this.datosUsuario.ID_USUA, coTipoLnea, idUnidOper).subscribe({
      next: (result: any) => {
        let vendedores: any[] = [];
        if ((result) && result.length > 0) {
          vendedores = result;//.map(v => v.ID_VEND);
        }

        if (vendedores && vendedores != null && vendedores.length > 0) {
          vendedores.push({ 'DE_NOMB_VEND': 'TODOS', 'ID_VEND': 0 });
        } else {
          vendedores.push({ 'DE_NOMB_VEND': 'DATA NOT FOUND', 'ID_VEND': '' });
        }

        this.vendedorSelect = vendedores;
        this.vendedorSelect = this.vendedorSelect.sort((s, v) => {
          return (s.ID_VEND - v.ID_VEND);
        });

        let prefVendedor;
        if (preferenciaVendedor && preferenciaVendedor != null) {
          prefVendedor = preferenciaVendedor.DE_VALO_PREF && preferenciaVendedor.DE_VALO_PREF != null ? preferenciaVendedor.DE_VALO_PREF : null;
          if (prefVendedor && prefVendedor != null) {
            prefVendedor = JSON.parse(prefVendedor);
          }
        }

        if (prefVendedor && prefVendedor != null) {
          this.vendedor = prefVendedor;
        } else {
          this.vendedor = this.vendedorSelect && this.vendedorSelect != null ? this.vendedorSelect[0] : null;
        }

        this.obtenerInformacionVentas();
      }
    })
  }

  obtenerInformacionVentas() {
    this.spinner.show();
    this.loading = true;
    const idUnidOper = (this.compania) ? this.compania.ID_ORGN_EBS : '';
    const periodo = (this.periodo) ? this.periodo.DE_NOMB_PRDO : '';
    let vendedores = '';
    if ((this.vendedor) && this.vendedor.ID_VEND == 0) {
      let cont = 0;
      this.vendedorSelect.filter(p => p.ID_VEND != 0).forEach(v => {
        if (cont > 0) {
          vendedores += ',' + v.ID_VEND;
        } else {
          vendedores += v.ID_VEND;
        }
        cont++;
      })
    } else {
      vendedores = this.vendedor.ID_VEND;
    }
    const tipoLnea = (this.linea) ? this.linea.CO_TIPO_LNEA : '';

    if ((this.table)) { this.table.dt.reset(); }
    const ventas = this.ventasService.obtenerVentas(periodo, idUnidOper, vendedores, tipoLnea);
    const vendedorMetas = this.vendedoresService.obtenerVendedorMeta(vendedores);
    forkJoin(ventas, vendedorMetas).subscribe(([ventasResponse, vendedorMetas]: [any, any]) => {
      let dataTemporal: any = [];

      if ((ventasResponse) && ventasResponse.length > 0) {
        ventasResponse.forEach(v => dataTemporal.push(v));
        this.data = dataTemporal.filter(p => p.PERIODO_INICIO == this.periodo.DE_NOMB_PRDO).sort((s, v) => {
          return -(s.MONTO_DOLARES - v.MONTO_DOLARES);
        });
      } else {
        this.data = [];
      }

      this.nombreArchivo = 'ventas';
      /**repore excel */
      let reporteExcel = [];
      if ((this.data) && this.data.length > 0) {
        this.data.forEach(v => {
          let objReporte: any = new Object();
          objReporte['VENDEDOR'] = v.VENDEDOR;
          objReporte['NOMBRE COMPLETO'] = v.NOMCOMPLETO;
          objReporte['MONTO DOLARES'] = v.MONTO_DOLARES;
          reporteExcel.push(objReporte);
        });
      }

      this.dataExcel = reporteExcel;
      /***/
      this.totalMontoDolares = this.data.filter(p => p.MONTO_DOLARES != null).reduce((acum, valor) => {
        return Number.parseFloat(acum) + Number.parseFloat(valor.MONTO_DOLARES);
      }, 0)

      this.dataTotal = [
        { field: 'Total', value: '', type: 'header' },
        { field: '', value: '', type: 'number' },
        {
          field: 'TotalCobrar', value: this.totalMontoDolares, type: 'doller'
        }
      ];
      //obtener datos de Meta
      var periodoFin: any = fromStringToDate(periodo);
      let mesInicial = periodoFin.getMonth() - 5;
      let anioInicial = 0;
      if (mesInicial < 0) {
        anioInicial = periodoFin.getFullYear() - 1;
        mesInicial = 12 + mesInicial;
      } else {
        anioInicial = periodoFin.getFullYear();
      }

      let metaPeriodoPorVend = [];
      if ((vendedorMetas)) {
        for (var i = 0; i < this.numeroPeriodos; i++) {
          if (mesInicial > 11) {
            mesInicial = 0;
            anioInicial++;
          }

          const cadenaPeriodoIni = this.periodosArray[mesInicial] + '-' + anioInicial.toString().substring(2, 4);

          const metaPorPeriodo = vendedorMetas
            .filter(p => p.COPRDO == cadenaPeriodoIni)
            .map(r => r)
            .reduce((acum, valor) => (acum + valor.NUMNTO), 0);//vendedorMetas.filter(p => p.CO_PRDO == cadenaPeriodoIni).map(r => r);
          if (metaPorPeriodo != null && metaPorPeriodo > 0) {
            metaPeriodoPorVend.push([cadenaPeriodoIni] + '|' + metaPorPeriodo);
          } else {
            metaPeriodoPorVend.push([cadenaPeriodoIni] + '|' + 0);
          }

          mesInicial++;
        }
      }

      this.metaPeriodoPorVendedor = metaPeriodoPorVend;
      this.obtenerDetalleVentas();
      this.generarGrafico(ventasResponse, metaPeriodoPorVend);
      this.cargandoInfo = false;
      this.loading = false;
      this.spinner.hide();
    });
  }

  generarGrafico(data: any, metaVendedor: any) {
    if (data && data != null && data.length > 0) {
      this.visualizarGrafico = true;
      var lbls: any = Array.from(new Set(data.filter(p => p.PERIODO_INICIO != null).map(a => a.PERIODO_INICIO)).values());

      if ((lbls) && lbls.length == 6) {
      } else {
        //validar el mes faltante 
        var date = this.periodo.DE_NOMB_PRDO;//new Date();
        var mes = this.periodo.NU_PRDO_EBS;//date.getMonth();
        let mesNumber = mes;
        let periodo = date;//date.getFullYear().toString().substring(2, 4);
        let periodoUltimo = null;
        for (let i = 0; i < 6; i++) {
          var existe = lbls.filter(l => String(l).toLowerCase().indexOf(String(this.periodosArray[mesNumber - 1]).toLowerCase()) == 0)[0];
          if (!(existe)) {
            var periodoString = !(periodoUltimo) ? this.periodo.DE_NOMB_PRDO :
              String(this.periodosArray[mesNumber - 1]).concat("-" + String((periodoUltimo) ? periodoUltimo : periodo));
            lbls.push(periodoString);
            periodoUltimo = (periodoString) ? periodoString.split('-')[1] : null;
          } else {
            periodoUltimo = (existe) ? existe.split('-')[1] : null;
          }

          if ((mesNumber - 1) == 0) mesNumber = 12;
          else mesNumber--;
        }
      }

      lbls = lbls.filter((p: any) => p != null).sort((s, v) => {
        const mes = s.split('-')[0];
        const anio = s.split('-')[1];
        const mesVersion = v.split('-')[0];
        const anioVersion = v.split('-')[1];
        if (anio == anioVersion) {
          if (this.periodos[mes] < this.periodos[mesVersion]) {
            return -1;
          } else {
            return 1;
          }
        } else if (anio < anioVersion) {
          return -1;
        }
      })

      let arregloMontos: any[] = [];
      lbls.forEach(x => {
        let montos = data.filter(p => p.PERIODO_INICIO == x).reduce((acum, valor) => {
          return (parseFloat(acum) + parseFloat(valor.MONTO_DOLARES));
        }, 0);
        arregloMontos.push(montos);
      });

      //Sort para el arreglo de meta por vendedor
      var metaPorVendedor = Array.from(metaVendedor).filter((p: any) => p != null).sort((s: any, v: any) => {
        const mes = s.split('|')[0].split('-')[0];
        const anio = s.split('|')[0].split('-')[1];
        const mesVersion = v.split('|')[0].split('-')[0];
        const anioVersion = v.split('|')[0].split('-')[1];
        if (anio == anioVersion) {
          if (this.periodos[mes] < this.periodos[mesVersion]) {
            return -1;
          } else { return 1; }
        } else if (anio < anioVersion) { return -1; }
      });

      metaPorVendedor = metaPorVendedor.map((meta: any) => {
        if (meta && meta != null) {
          const valorMeta = meta.split('|')[1];
          return valorMeta && valorMeta != null ? Number.parseFloat(valorMeta) : 0;
        }
      })

      this.dataGrafico = {
        labels: lbls,
        datasets: [
          {
            label: 'Ventas',
            data: arregloMontos,
            fill: false,
            backgroundColor: 'rgba(54, 162, 235, 0.2)',
            borderColor: 'rgba(54, 162, 235, 1)',
            borderWidth: 1
            //steppedLine: true,
            //lineTension: 0,
            //borderWidth: 1.5,
            //radius: 5
          },
          {
            label: 'Meta',
            data: metaPorVendedor,
            fill: false,
            backgroundColor: 'rgba(255, 99, 132, 0.2)',
            borderColor: 'rgba(255,99,132,1)',
            borderWidth: 1
            //steppedLine: true,
            //lineTension: 0,
            //borderWidth: 1.5,
            //radius: 5
          }
        ]
      }
      this.options = {
        responsive: true,
        tooltips: {
          mode: 'label',
          showTooltips: true,
          callbacks: {
            label: function (tooltipItem, data) {
              return data.datasets[tooltipItem.datasetIndex].label + ": " + "USD: " + data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].toLocaleString('en-PE', { style: 'currency', currency: 'PEN' }).split('PEN')[1];
            }
          }
        }
      }
    } else {
      this.dataGrafico = {};
      this.showInfo();
    }

  }

  obtenerDetalleVentas() {
    this.meta = this.metaPeriodoPorVendedor.filter(p => p != null && p.split('|')[0] == this.periodo.DE_NOMB_PRDO).map(r => r.split('|')[1])[0];
    this.diferencia = Math.abs(this.meta - this.totalMontoDolares);
    const valorMeta = (this.meta) && this.meta > 0 ? ((this.totalMontoDolares / this.meta) * 100) : 0;

    if ((valorMeta)) {
      if (valorMeta > 99.99 || valorMeta > 100) {
        this.avancePeriodo = 100;
      } else {
        this.avancePeriodo = valorMeta;
      }
    }
  }

  buscarInformacionVentas() {
    //Guardar preferencia
    let PreferenciaCompania = new PrefCompaniaVentas();
    PreferenciaCompania.preferencia.CO_USUA = this.usuario;
    PreferenciaCompania.preferencia.DE_VALO_PREF = JSON.stringify(this.compania);
    this.preferenciaService.guardarPreferencia(PreferenciaCompania.preferencia).subscribe();

    //Guardar preferencia
    let preferenciaPeriodo = new PrefPeriodoVentas();
    preferenciaPeriodo.preferencia.CO_USUA = this.usuario;
    preferenciaPeriodo.preferencia.DE_VALO_PREF = JSON.stringify(this.periodo);
    this.preferenciaService.guardarPreferencia(preferenciaPeriodo.preferencia).subscribe();

    //this.obtenerInformacionVentas();
    this.obtenerVendedores(null);
  }

  buscarInformacionPorLnea(linea: any) {
    //Guardar preferencia
    let preferenciaLinea = new PrefLineaVentas();
    preferenciaLinea.preferencia.CO_USUA = this.usuario;
    preferenciaLinea.preferencia.DE_VALO_PREF = JSON.stringify(linea.value);
    this.preferenciaService.guardarPreferencia(preferenciaLinea.preferencia).subscribe();
    this.obtenerVendedores(null);
  }

  buscarInformacionPorVendedor(vendedor: any) {
    //Guardar preferencia
    let preferenciaVendedor = new PrefVendedorVentas();
    preferenciaVendedor.preferencia.CO_USUA = this.usuario;
    preferenciaVendedor.preferencia.DE_VALO_PREF = JSON.stringify(vendedor.value);
    this.preferenciaService.guardarPreferencia(preferenciaVendedor.preferencia).subscribe();
    this.obtenerInformacionVentas();
  }

  showInfo() {
    this.msgs = [];
    this.visualizarGrafico = false;
    this.msgs.push({ severity: 'info', summary: 'Información Gráfico', detail: 'No se encontró información para visualizar el gráfico de Ventas' });
  }

}
