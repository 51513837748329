import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-cuenta-cobrar",
  templateUrl: "./cuenta-cobrar.component.html",
  styleUrls: ["./cuenta-cobrar.component.scss"],
})
export class CuentaCobrarComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
