<p-card header="Cuentas por Pagar {{deCia}}" [style]="{'background-color': '#fcfc7b'}">
  <div class="p-grid">
      <div class="p-col-6">
          <span>Total deuda:</span>
      </div>
      <div class="p-col-6">
          <span>S/. 1,211,500.00</span>
      </div>
      <div class="p-col-6">
          <span>Total deuda vencida:</span>
      </div>
      <div class="p-col-6">
          <span>S/. 500,500.00</span>
      </div>
      <div class="p-col-6">
          <span>% de Avance:</span>
      </div>
      <div class="p-col-6">
          <span>41.31%</span>
      </div>
      <div class="p-col-12">
        <button type="button" pButton pRipple icon="pi pi-search" (click)="verDetalle()" 
                pTooltip="Ver Detalle" tooltipPosition="bottom" label="Ver Detalle" 
                style="background-color:transparent; border-color:black; color:black"></button>
      </div>
  </div>
</p-card>