import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InitCoordinatorService {
  private initSubject = new Subject<void>();

  // Observable que otros componentes pueden suscribirse para escuchar el evento de inicialización
  init$ = this.initSubject.asObservable();

  // Método para emitir el evento de inicialización
  notifyInit(): void {
    this.initSubject.next();
  }
}
