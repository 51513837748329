import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { InformarService } from 'src/app/services/util/informar.service';
import { Preferencia } from 'src/app/model/preferencia.class';
import { SharedService } from 'src/app/component/services/shared.service';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DetalleVentasComponent } from './indicador-gestion/ventas/detalle-ventas/detalle-ventas.component';
import { MaestroService } from 'src/app/services/maestros/maestro.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { UsuariosService } from 'src/app/services/seguridad/usuarios.service';
import { map } from 'rxjs/operators';
import { Usuario } from 'src/app/model/seguridad/usuario';
import { ComboUnidOperativa } from 'src/app/model/maestro/combos';
import { setLoadingSpinner } from 'src/app/component/util/Util';

@Component({
  selector: 'app-indicadores',
  templateUrl: './indicadores.component.html',
  styleUrls: ['./indicadores.component.scss'],
  
})
export class IndicadoresComponent implements OnInit {

  cia: any;
  @Output() buttonClick = new EventEmitter<string>();

  visualizarIndicadores: boolean = true;
  indicadorTotalVentas: boolean = true;
  indicadorCuentasPorCobrar: boolean = false;
  indicadorCuentasPorPagar: boolean = false;
  datosUsuario: Usuario;
  username: any;

  constructor(
    private maestroService: MaestroService,
    private spinner: NgxSpinnerService,
    private usuarioService: UsuariosService,
  ) { }

  ngOnInit() {
    //this.obtenerDatosUsuario();
  }
  
  // obtenerListacia() {
  //   console.log('Indicadores-obtenerListacia');
  //   const cia = this.maestroService.obtenerUnidadOperativa(this.datosUsuario.ID_USUA).pipe(
  //     map((lista: ComboUnidOperativa[]) => {
  //      localStorage.setItem('data-header', btoa(JSON.stringify(lista[0])));
  //     })
  //   );

  //   setLoadingSpinner(this.spinner, cia);
  // }
  
  // obtenerDatosUsuario() {
  //   console.log('Indicadores-obtenerDatosUsuario');
  //   let usuario = sessionStorage.getItem('SESSIONUSER');
  //   this.usuarioService.obtenerUsuarioSeguro(usuario).subscribe({
  //     next: (usuario: Usuario) => {
  //       if (usuario) {
  //         this.datosUsuario = usuario;
  //         this.obtenerListacia();
  //       }
  //     }
  //   });
  // }

  cambioCia(event: any): void {
    console.log('cambioCiaIndicadores', event);
    this.cia = event;
  }

  obtenerPermisos(): void{
    
  }

}
