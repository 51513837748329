export const OpcionesRolGerencial = {
    reporteCostoVenta: 'Reporte de Costo de Venta',
    indicadorVenta: 'Indicador de Ventas',
    reporteCuentaLineaCredito: 'Reporte de Cuentas de Líneas de Crédito',
    reporteCuentaPorCobrar: 'Reporte de Cuentas por Cobrar',
    reportePeriodosVencido: 'periodo-vencidos',
    reporteDeudasVencido: 'deudas-vencidas',
    reporteCuentaPorPagar: 'Reporte de Cuentas por Pagar',
    reporteCuentaPorPagarSoles: 'cuentas-pagar-soles',
    reporteCuentaPorPagarDolares: 'cuentas-pagar-dolares'
}