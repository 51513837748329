<app-header #header (menuEmitter)="openMenu($event)" (cambioCiaEmitter)="cambioCia($event)" (contentEmitter)="cambioContent($event)"  flexLayout="column"></app-header>
<div flexLayout="row" fxFlex>    
    <div [fxFlex]="flexMenu">
        <div class="p-grid" >
            <!-- <div class="p-col-12" style="text-align: center; margin-top: 10px">
                <img src="./../../../../assets/icons/{{logo}}" id="icon" alt="Sidebar" *ngIf="abrirMenu"/>
            </div> -->
            <div class="p-col-12">
                <app-menu #menu *ngIf="abrirMenu"></app-menu>
            </div>
        </div>
        
    </div>
    <div [fxFlex]="flexContent" class="content">
        <app-content #content [cia]="cia"></app-content> 
    </div>
</div>
<app-footer flexLayout="column"></app-footer>