import { Directive, Output, EventEmitter, HostListener, ElementRef, Renderer2, Self, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  ZERO,
  NUMPAD_ZERO,
  NUMPAD_NINE,
  NINE,
} from '@angular/cdk/keycodes';


@Directive({
  selector: '[numberFormat]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumberFormatDirective),
      multi: true,
    },
  ],
})
export class NumberFormatDirective implements ControlValueAccessor {
  _onChange: (_: any) => void;

  _touched: () => void;

  @Output() ngModelChange: EventEmitter<any> = new EventEmitter();
  value: any;

  constructor(@Self() private _el: ElementRef, private _renderer: Renderer2) { }

  @HostListener('keyup', ['$event']) onKeyDown(evt: any) {
    const keyCode = evt.keyCode;
    if ((keyCode >= ZERO && keyCode <= NINE) || (keyCode >= NUMPAD_ZERO && keyCode <= NUMPAD_NINE) || keyCode == 229) {
      const numberString = this._el.nativeElement.value.replace(/,/g,'');
      var parts = numberString.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      const value = parts.join('.');
      this._renderer.setProperty(this._el.nativeElement, 'value', value);
      this._onChange(value);
      evt.preventDefault();
    } else {
      this._renderer.setProperty(this._el.nativeElement, 'value', this._el.nativeElement.value);
      this._onChange(this._el.nativeElement.value);
      evt.preventDefault();
    }
  }

  @HostListener('blur', ['$event'])
  onBlur() {
    this._touched();
  }

  /** Implementation for ControlValueAccessor interface */
  writeValue(value: any): void {
    this._renderer.setProperty(this._el.nativeElement, 'value', value);
  }

  /** Implementation for ControlValueAccessor interface */
  registerOnChange(fn: (_: any) => void): void {
    this._onChange = fn;
  }

  /** Implementation for ControlValueAccessor interface */
  registerOnTouched(fn: () => void): void {
    this._touched = fn;
  }

  /** Implementation for ControlValueAccessor interface */
  setDisabledState(isDisabled: boolean): void {
    this._renderer.setProperty(this._el.nativeElement, 'disabled', isDisabled);
  }



}
