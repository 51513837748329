import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({ name: 'datePersonalize' })
export class DatePersonalizePipe implements PipeTransform {
   
    constructor(private dp: DatePipe){}
    transform(date: Date, format: string) {
        const mes = date.getMonth();
        let mesTransform: string;
        switch (mes) {
            case 0:
                mesTransform = 'ENE';
                break;
            case 1:
                mesTransform = 'FEB';
                break;
            case 2:
                mesTransform = 'MAR';
                break;
            case 3:
                mesTransform = 'ABR';
                break;
            case 4:
                mesTransform = 'MAY';
                break;
            case 5:
                mesTransform = 'JUN';
                break;
            case 6:
                mesTransform = 'JUL';
                break;
            case 7:
                mesTransform = 'AGO';
                break;
            case 8:
                mesTransform = 'SET';
                break;
            case 9:
                mesTransform = 'OCT';
                break;
            case 10:
                mesTransform = 'NOV';
                break;
            case 11:
                mesTransform = 'DIC';
                break;
        }
        console.log(mesTransform);
        if (mesTransform) {
            const dpTransf = this.dp.transform(date, format).substring(3);
            return mesTransform.concat(dpTransf);
        } else return this.dp.transform(date, format);

    }
}