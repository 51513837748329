import { Component, OnInit, OnDestroy } from '@angular/core';
import { WebsocketService } from 'src/app/services/websocket.service';
import { Subscription } from 'rxjs';
import { UsuariosService } from 'src/app/services/seguridad/usuarios.service';
import { NotificacionService } from 'src/app/services/notificacion/notificacion.service';
import { ConfiguracionService } from 'src/app/services/seguridad_api/configurations/configuracion.service';


@Component({
  selector: 'app-negocio',
  templateUrl: './negocio.component.html',
  styleUrls: ['./negocio.component.scss']
})
export class NegocioComponent implements OnInit, OnDestroy {

  /*private refreshNotificacion: Subscription;
  private pushNotification: Subscription;
  private pushMsgNoti: Subscription;
  private openSocket: Subscription;
  private closeSocket: Subscription;

  urlEnlace: string;
  usuario: string;*/

  constructor(/*private websocketService: WebsocketService,
    private usuarioService: UsuariosService,
    private notificacionService: NotificacionService,
    private configuracionService: ConfiguracionService*/) {
    //this.urlEnlace = this.configuracionService.ipRecursos + this.configuracionService.contextoRecurso;
    //this.subscribeSocket();
  }

  ngOnInit() {
    /*this.refreshNotificacion = this.websocketService.pushNotificacion.subscribe(push => {
      const notificacion = (push) ? JSON.parse(push).notificacion : null;
      this.pushNotificacion(notificacion, true);
    });

    this.pushMsgNoti = this.websocketService.pushMessageNot.subscribe(push => {
      const data = (push) ? JSON.parse(push).data : null;
      this.pushNotificacion(data, false);
    });

    this.openSocket = this.usuarioService.openSocket.subscribe(() => {
      this.subscribeSocket();
    });

    this.closeSocket = this.usuarioService.closeSocket.subscribe(() => {
      this.unsubscribeEvents();
    });*/
  }

  ngOnDestroy(): void {
    /*if ((this.openSocket)) this.openSocket.unsubscribe();
    if ((this.closeSocket)) this.closeSocket.unsubscribe();
    this.unsubscribeEvents();*/
  }

  subscribeSocket() {
    /*this.usuario = sessionStorage.getItem('SESSIONUSER');
    if ((this.usuario)) {
      this.subscribeWebSocket();
    }*/
  }

  unsubscribeEvents() {
    /*if ((this.refreshNotificacion)) this.refreshNotificacion.unsubscribe();
    if ((this.pushNotification)) this.pushNotification.unsubscribe();
    if ((this.pushMsgNoti)) this.pushMsgNoti.unsubscribe();
    this.websocketService.closeSocket();*/
  }

  subscribeWebSocket() {
    //obtener el id del usuario
    /*this.usuarioService.obtenerUsuario(this.usuario).subscribe({
      next: (respuesta: any) => {
        if ((respuesta)) {
          this.websocketService.subscribeNotification(respuesta.ID_USUA.toString());
        }
      }
    });*/
  }

  pushNotificacion(obj: any, personalizado: boolean) {
    /*let conf: Array<any> = [];

    if (!personalizado) {
      conf.push({
        'title': 'Notificación',
        'content': `Tienes ${obj.count} pendientes de revisión.`,
        'link': '',
        'icon': './../assets/icons/phoenix_logo.png',
        'sound': `./../../assets/sound/birdroid.mp3` //la ruta debe ser desde notification.service.ts
      });
      this.notificacionPushService.crearNotification(conf);
      //Si se mostro la notificación (EL PUSH SE MOSTRO EN LA PANTALLA) => actualizar la notificación
      this.notificacionService.actualizarNotificacionVistoPorUsuario(obj.id).subscribe();
    } else {
      if ((obj)) {
        conf.push({
          'title': 'Notificación',
          'content': `${obj.DE_NOTI}`,
          'link': `${this.urlEnlace}/${obj.DE_PRMT_01}`,
          'icon': './../assets/icons/phoenix_logo.png',
          'sound': `./../../assets/sound/${obj.DE_SNDO}` //la ruta debe ser desde notification.service.ts
        });
        this.notificacionPushService.crearNotification(conf);
        //Si se mostro la notificación (EL PUSH SE MOSTRO EN LA PANTALLA) => actualizar la notificación
        this.notificacionService.actualizarNotificacionVisto(obj).subscribe();
      }
    }*/

  }

}
