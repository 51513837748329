import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-cambio-password',
  templateUrl: './page-cambio-password.component.html',
  styleUrls: ['./page-cambio-password.component.scss']
})
export class PageCambioPasswordComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    document.getElementsByTagName('body')[0].classList.add('body-dashboard');
  }

}
