<!-- <div class="header">
  <a class="logo" (click)="abrirMenu()">
    <img [src]="menu ? './../../../../assets/icons/icon-menu.png' : './../../../../assets/icons/icon-eliminar.png'" id="icon" alt="iconMenu" style="width: 20px;" />
  </a>
  <div class="header-left">
      <a>PHOENIX</a>
    </div>
  <div class="header-right">
      <p-splitButton label="Save" icon="pi pi-file" [model]="itemsButton"></p-splitButton>
    <a>{{datosUsuario}}</a>
  </div>
</div> -->

<header class="header-fixed">
  <form [formGroup]="headerForm">
    <div class="header-limiter">
      <div class="p-grid">
        <div class="p-col-4" style="align-items: center; display: grid; font-size: '16px'; font-family: inherit">
          <div class="p-grid">
            <div class="p-col-12">
              <div class="p-grid">
                <div class="p-col-1">
                  <h1><a class="logo" (click)="abrirMenu()">
                    <img [src]="menu ? './../../../../assets/icons/icon-menu.png' : './../../../../assets/icons/icon-eliminar.png'"
                      id="icon" alt="Menu" style="width: 20px; cursor: pointer;" />
                  </a></h1>
                </div>
                <div class="p-col-1" style="align-items: center; display: grid; font-size: '16px'; font-family: inherit">
                  Cia:
                </div>
                <div class="p-col-10">
                  <p-dropdown [options]="listaCompania" formControlName="cia" filter="true" (onChange)="cambioCia()"
                  placeholder="-- Seleccione --" optionLabel="DE_UNID_OPER" optionValue="ID_ORGN_EBS" 
                  [style]="{'width':'100%'}" [showClear]="false" required="false" >
                  </p-dropdown>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="p-col-8" style="align-items: center; display: grid; font-size: '12px'; font-family: inherit">
          <div class="p-grid" >
            <div class="p-col-12" style="align-items: center; display: grid; font-size: '12px'; font-family: inherit">
              <div class="p-grid">
                <div class="p-col-4" style="align-items: center; display: grid; font-size: '14px'; font-family: inherit; text-align: center">
                  <input type="text" pInputText formControlName="fecha" style="
                  background-color: transparent;
                  font-size: 12px;
                  font-weight: bold;
                  border: black;
                  color: white;
                  text-align: center;
                  ">
                </div>
                <div class="p-col-1 tipoCambioHeader">
                  T.C. Venta:
                </div>
                <div class="p-col-2 tipoCambioHeader">
                  {{ tipoCambioVenta }}
                </div>
                <div class="p-col-1 tipoCambioHeader">
                  T.C. Compra:
                </div>
                <div class="p-col-2 tipoCambioHeader">
                  {{ tipoCambioCompra }}
                </div>

                <div class="p-col-2" style="text-align: right; display: grid; font-size: '14px'; font-family: inherit">
                  <nav>
                    <a (click)="op1.toggle($event)" style="cursor: pointer"><i class="pi pi-bell"><span
                          class="label-warning">{{cantNoti}}</span></i></a>
                    &nbsp;&nbsp;
                    <a (click)="op2.toggle($event)" style="cursor: pointer">{{username}}<i class="pi pi-angle-down"></i></a>
                  </nav>
    
                  <p-overlayPanel #op1>
                    <div class="ui-g ui-fluid text-center">
                      <div class="ui-g-12 ui-md-12">
                        <div class="ui-inputgroup">
                          <span><b>Tienes {{cantNoti}} notificaciones</b></span>
                        </div>
                      </div>
                    </div>
                    <ul class="alert-header" *ngFor="let noti of notificacion">
                      <li>
                        <a class="list-noti" (click)="verNotificaciones()" title="{{noti.DE_NOTI}}"><i class="fas fa-exclamation"
                            style="color: red"></i>&nbsp;{{noti.DE_NOTI | slice:0:30 }} ...</a>
                      </li>
                    </ul>
                    <li class="footer" (click)="verNotificaciones()">
                      <a>Ver Todo</a>
                    </li>
                  </p-overlayPanel>
    
                  <p-overlayPanel #op2>
                    <div style="text-align: center">
                      <img *ngIf="sexoUsuario == 'M'" src="./../../../../assets/icons/masculino-72.png" id="icon" alt="Sidebar" />
                      <img *ngIf="sexoUsuario == 'F'" src="./../../../../assets/icons/femenino-72.png" id="icon" alt="Sidebar" />
                      <p><strong>{{nombreUsuario}}</strong></p>
                      <span><i class="fas fa-laptop"></i> {{ipTerminal}}</span><br>
                      <span><i class="fas fa-sign-in-alt"></i> {{fechaLogin | date:'dd/MM/yyyy HH:mm:ss' }}</span>
                      <br><br>
                      <button pButton type="button" (click)="cambiarContrasena()" label="Cambiar Contraseña"
                        class="p-button-raised p-button-rounded"></button>
                      <button pButton type="button" (click)="cerrarSession()" label="Cerrar Sesión"
                        class="p-button-raised p-button-rounded p-button-danger"></button>
                    </div>
                  
                  </p-overlayPanel>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </form>
</header>

