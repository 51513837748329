import { Component, OnInit, Input, ViewChild, Output, EventEmitter, Renderer2 } from '@angular/core';
import { Table } from 'primeng/table';
import { ConfiguracionService } from 'src/app/services/seguridad_api/configurations/configuracion.service';
import { ExcelService } from 'src/app/services/util/excel.service';
import { Message, SelectItem } from 'primeng/api';

import { Router } from '@angular/router';
import { MensajePhoenixInfo } from '../message/UtilMessage';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {

  @Input() data?: any;
  @Input() column?: any;
  @Input() totalRecords?: any;
  @Input() scrollHeight?: any;
  @Input() row?: any;
  @Input() paginator?: boolean;
  @Input() loading?: boolean;
  @Input() dataTotal?: any;
  @Input() exportExcel?: boolean;
  @Input() dataExport?: any[];
  @Input() nombreArchivo?: any;
  @Input() selectRow?: boolean;
  @Input() arrayCombo?: any[];
  @Input() optionButtons?: any[];
  @Input() expandRow?: boolean;
  @Input() rowGroupMetadata?: any;
  @Input() textGroupMetadata?: string;
  @Input() colGroupMetadata?: any;
  @Input() datakey?: any;
  @Input() rowSelectDefault?: boolean;
  @Input() tblCheckbox?: boolean;
  @Input() selectionMode?: string;
  @Input() globalFilter: boolean;
  @Input() frozenWidth?: any;
  @Input() frozenColumns?: any[];
  @Input() cabeceraTabla?: string;

  @Output() rowSelectEmitter: EventEmitter<any> = new EventEmitter();
  @Output() rowUnSelectEmitter: EventEmitter<any> = new EventEmitter();
  @Output() rowUpdate: EventEmitter<any> = new EventEmitter();
  @Output() rowDelete: EventEmitter<any> = new EventEmitter();
  @Output() rowAdd: EventEmitter<any> = new EventEmitter();

  cols: any[];
  filtroGlobal: boolean = false;
  urlEnlace: string;
  filterSomeHeader: boolean;

  msgs: Message[] = [];
  visualizarMensaje = false;
  semaforo: SelectItem[];

  selectedRow: any;
  condition: boolean = true;

  selectedModes: any;

  @ViewChild("dt", { static: true }) dt: Table;
  constructor(private configuracion: ConfiguracionService,
    private excelService: ExcelService,
    private router: Router,
    private renderer: Renderer2
  ) { }

  ngOnInit() {
    this.onResize(window.innerWidth);
    this.scrollHeight = (this.scrollHeight) ? this.scrollHeight : '100%';
    this.selectionMode = (this.selectionMode) ? this.selectionMode : "single";
    this.urlEnlace = this.configuracion.ipRecursos.concat(this.configuracion.contextoRecurso).concat('/');
    if ((this.column) && this.column.length > 0) {
      this.cols = this.column;
      //revisar si alguna cabecera tiene filter
      this.filterSomeHeader = this.cols.filter(col => { if (col.filter) return true }).length > 0;
      setTimeout(() => {
        if (this.selectRow) {
          const rowSelected: any = document.getElementsByClassName("ui-selectable-row");
          if ((rowSelected) && rowSelected.length > 0 && this.rowSelectDefault) {
            //rowSelected[0].classList.add("ui-state-highlight");
            rowSelected[0].click();
            const event: any = {};
            event["data"] = this.data[0];
            this.onRowSelect(event);
          }
        }
      }, 10)

    } else {
      this.cols = [
        { field: '', header: 'N°' },
        { field: '', header: 'Default' },
        { field: '', header: 'Default' }
      ];
    }

    this.semaforo = [
      { label: 'Todo', value: null },
      { label: 'green', value: 1, icon: 'icon-green2' },
      { label: 'amber', value: 2, icon: 'icon-yellow' },
      { label: 'red', value: 3, icon: 'icon-red2' }
    ];
  }

  onResize(innerWidth: any) {
    if ((this.globalFilter) && this.globalFilter) {
      setTimeout(() => {
        if ((this.dt)) {
          this.renderer.setStyle(this.dt.el.nativeElement.querySelectorAll(".ui-table-caption")[0], "display", "block");
        }
      }, 0)
      this.filtroGlobal = true;
    } else {
      let windowWidth = innerWidth;
      if (windowWidth > 100 && windowWidth < 641) {
        this.filtroGlobal = true;
      } else {
        this.filtroGlobal = false;
        //this.dt.filterGlobal('', 'contains');
      }
    }
  }

  accederEnlace(url: string) {
    if ((url)) {
      if (url.indexOf('@') >= 0) {
        const path = url.split('@')[1];
        if ((path) && path.indexOf('?') >= 0) {
          const routerLink = path.split('?')[0];
          const idProceso = path.split('?')[1];
          if ((routerLink)) {
            localStorage.setItem('notificacion.id', idProceso);
            this.router.navigateByUrl(routerLink);
          }
        }
      } else {
        window.open(url, "_blank");
      }
    }

  }

  exportAsXLSX(): void {
    if (this.dataExport && this.dataExport.length > 0) {
      this.msgs = [];
      this.visualizarMensaje = true;
      this.msgs.push({ severity: 'info', summary: 'Service Message Phoenix', detail: 'Descargando reporte, Por favor espere ...' });
      this.excelService.exportAsExcelFile(this.dataExport, this.nombreArchivo);
      setInterval(() => {
        this.msgs = [];
        this.visualizarMensaje = false;
      }, 4000)
    } else MensajePhoenixInfo("No hay información para ser exportada.")
  }

  onRowSelect(event: any) {
    this.rowSelectEmitter.emit(event);
  }

  onRowUnselect(event: any) {
    this.rowUnSelectEmitter.emit(event);
  }

  updateRow(rowData?: any) {
    this.rowUpdate.emit(rowData);
  }

  deleteRow(rowData?: any) {
    this.rowDelete.emit(rowData);
  }

  addRow(rowData?: any) {
    this.rowAdd.emit(rowData);
  }

}
