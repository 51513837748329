import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfiguracionService } from '../../seguridad_api/configurations/configuracion.service';
import { RoutingConst } from 'src/app/constantes/routing';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ClaseTipoCambio, ResponseClaseTipoCambio, TipoCambio, ResponseTipoCambio, TipoCambioParam } from 'src/app/model/finanzas/tipo-cambio/TipoCambio';

@Injectable({
  providedIn: 'root'
})
export class TipoCambioService {
  url: string;

  constructor(private http: HttpClient,
    private configuracion: ConfiguracionService) {
    this.url = this.configuracion.serverUrlApiSegura + '/maestro';
  }

  obtenerClaseTipoCambio(): Observable<ClaseTipoCambio[]> {
    const url = `${this.url}/clase-tipo-cambio/`;
    return this.http.get<ResponseClaseTipoCambio>(url)
      .pipe(
        map((response: ResponseClaseTipoCambio) => {
          return response.data;
        })
      );
  }

  obtenerTipoCambio(param: TipoCambioParam): Observable<TipoCambio[]> {
    let url = `${this.url}/tipo-cambio?feConvStr=${param.FE_CONV_STR}&coTipoConv=${param.CO_TIPO_CONV}`;
    url = url + `&coMndaDsde=${param.CO_MNDA_DSDE}&coMndaHsta=${param.CO_MNDA_HSTA}`;
    console.log(url);
    return this.http.get<ResponseTipoCambio>(url)
      .pipe(
        map((response: ResponseTipoCambio) => {
          return response.data;
        })
      );
  }

}
