import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Mail } from 'src/app/model/mail.class';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ConfiguracionService } from '../seguridad_api/configurations/configuracion.service';

@Injectable({
  providedIn: 'root'
})
export class MailService {

  url: string;
  constructor(private http: HttpClient,
    private configuracion: ConfiguracionService) {
    this.url = this.configuracion.serverUrlApi;
  }

  public secuencialMail(): Observable<any> {
    let url = this.url + `/mail/secuencialMail`;
    return this.http.get<any[]>(url).pipe(
      map((response: any) => {
        return response.data;
      })
    );
  }

  public registrarMail(mail: Mail): Observable<any> {
    let url = this.url + `/mail/registrar-mail`;
    return this.http.post<any[]>(url, mail).pipe(
      map((response: any) => {
        return response.data;
      })
    );
  }


}
