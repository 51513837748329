import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { ConfiguracionService } from './configuracion.service';
//import { CookieService } from 'ngx-cookie-service';


@Injectable({
  providedIn: 'root'
})
export class GuardService implements CanActivate {
  path: ActivatedRouteSnapshot[];
  route: ActivatedRouteSnapshot;

  url: string;

  constructor(private httpClient: HttpClient,
    private configuracion: ConfiguracionService
    /*private cookieService: CookieService*/) {
    this.url = this.configuracion.serverUrlApi + '/validar-token';
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const token = sessionStorage.getItem('JSESSIONREFID'); //this.cookieService.get('JSESSIONREFID');
    return this.httpClient.get(`${this.url}?tkn=${token}`).pipe(
      map((response: any) => {
        if (response && response != null && response.data.success) {
          return true;
        } else {
          return false;
        }
      }),
      catchError((err) => {
        return of(false);
      })
    );

  }
}
