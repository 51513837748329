import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './component/access-control/login/login.component';
import { ResetPasswordComponent } from './component/access-control/reset-password/reset-password/reset-password.component';
import { Page404Component } from './component/template/page404/page404.component';
import { Page500Component } from './component/template/page500/page500.component';
import { PagePasswordActualizadoComponent } from './component/template/page-password-actualizado/page-password-actualizado.component';
import { GuardService } from './services/seguridad_api/configurations/guard.service';
import { PageTokenInvalidoComponent } from './component/template/page-token-invalido/page-token-invalido.component';
import { PageTokenVencidoComponent } from './component/template/page-token-vencido/page-token-vencido.component';
import { PageCambioPasswordComponent } from './component/template/page-cambio-password/page-cambio-password.component';
import { PrincipalComponent } from './component/main-component/principal/principal.component';
import { ProyectoComponent } from './component/core/negocio/proyectos/proyecto/proyecto.component';
import { AlmacenComponent } from './component/core/negocio/almacen/almacen.component';
import { VentasComponent } from './component/core/negocio/ventas/ventas.component';
import { ComprasComponent } from './component/core/negocio/compras/compras.component';
import { GestionComponent } from './component/core/gestion/gestion.component';
import { ImportacionesComponent } from './component/core/negocio/importaciones/importaciones.component';
import { ContabilidadComponent } from './component/core/negocio/contabilidad/contabilidad.component';
import { ParametrosComponent } from './component/core/negocio/parametros/parametros.component';
import { CuentaCobrarComponent } from './component/core/negocio/cuenta-cobrar/cuenta-cobrar.component';
import { ReportesComponent } from './component/core/gestion/reportes/reportes.component';
import { CuentasPorPagarComponent } from './component/core/gestion/reportes/cuentas-por-pagar/cuentas-por-pagar.component';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  //{ path: 'sistema-phoenix/principal', component: PrincipalComponent, canActivate: [GuardService] },
  {
    path: 'sistema-phoenix', component: PrincipalComponent, canActivate: [GuardService],  children: [
      {
        path: 'reportes', component: ReportesComponent,
        loadChildren: () => import('./component/core/gestion/reportes/reportes.module').then(m => m.ReportesModule),
      },
      {
        path: 'gestion', component: GestionComponent,
        loadChildren: () => import('./component/core/gestion/gestion.module').then(m => m.GestionModule)
      },
      {
        path: 'almacen', component: AlmacenComponent,
        loadChildren: () => import('./component/core/negocio/almacen/almacen.module').then(m => m.AlmacenModule),
      },
      {
        path: 'proyecto', component: ProyectoComponent,
        loadChildren: () => import('./component/core/negocio/proyectos/proyecto/proyecto.module').then(m => m.ProyectoModule)
      },
      {
        path: 'compras', component: ComprasComponent,
        loadChildren: () => import('./component/core/negocio/compras/compras.module').then(m => m.ComprasModule)
      },
      {
        path: 'ventas', component: VentasComponent,
        loadChildren: () => import('./component/core/negocio/ventas/ventas.module').then(m => m.VentasModule)
      }
      /* SA0089-2019 - RSIS1- Inicio */
      , {
        path: 'importaciones', component: ImportacionesComponent,
        loadChildren: () => import('./component/core/negocio/importaciones/importaciones.module').then(m => m.ImportacionesModule)
      }
      /* SA0089-2019 - RSIS1- Fin */
      , {
        path: 'pagos', component: ComprasComponent,
        loadChildren: () => import('./component/core/negocio/pagos/pagos.module').then(m => m.PagosModule)
      },
      {
        path: 'contabilidad', component: ContabilidadComponent,
        loadChildren: () => import('./component/core/negocio/contabilidad/contabilidad.module').then(m => m.ContabilidadModule)
      },
      {
        path: 'parametros', component: ParametrosComponent,
        loadChildren: () => import('./component/core/negocio/parametros/parametros.module').then(m => m.ParametrosModule)
      },
      // SA0003-2020
      //se pasa cuentas-cobrar a componente aparte.
      {
        path: 'cuentas-cobrar', component: CuentaCobrarComponent,
        loadChildren: () => import('./component/core/negocio/cuenta-cobrar/cuenta-cobrar.module').then(m => m.CuentaCobrarModule)
      },
      {
        path: 'facturacion-electronica', component: ParametrosComponent,
        loadChildren: () => import('./component/core/negocio/facturacion-electronica/facturacion-electronica.module').then(m => m.FacturacionElectronicaModule)
      },
    ]
  },
  { path: 'page404', component: Page404Component },
  { path: 'page500', component: Page500Component },
  { path: 'page-password-actualizado', component: PagePasswordActualizadoComponent },
  { path: 'page-access-denied', component: PageTokenInvalidoComponent },
  { path: 'page-access-timeout', component: PageTokenVencidoComponent },
  { path: 'page-password-change', component: PageCambioPasswordComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})

export class AppRoutingModule { }
