<div class="wrapper fadeInDown">
  <div id="formContent">
    <p-card header="Ya se realizó el cambio de contraseña"
      subheader="El recurso que está buscando ya no está disponible" [style]="{width: '350px'}"
      styleClass="ui-card-shadow card-style">
      <p-header style="text-align: center">
        <img class="center" src="Card" src="./../../../../assets/icons/icon-contrasena.png" id="icon" alt="Password Actualizado"
          style="width: 60%; height: 80%;" />
      </p-header>
      <div class="ui-g ui-fluid">
        <div class="ui-g-12 ui-md-12">
          <br><br><br><br>
          <div class="ui-g form-group">
            <button pButton type="button" label="Ir a la Página de Inicio" [routerLink]="['/']"
              class="ui-button-raised ui-button-info btn-color"></button>
          </div>
        </div>
      </div>

    </p-card>
  </div>
</div>