export class Mail {
    _id: string;
    ID_SEC: number;
    CO_PROC: string;
    DE_MAIL_FROM: string;
    DE_MAIL_TO: string;
    DE_MAIL_CC: string;
    CO_SPRD: string;
    DE_ASNT: string;
    DE_MSJE: string;
    DE_ARCH: string;
    BI_ARCH: Blob;
    CO_TIPO_MAIL: string;
    ID_ESTA: number;
    CO_USUA_CREA: string;
    FE_CREA: Date;
    IP_TERM_CREA: string;
    CO_USUA_MODI: string;
    FE_MODI: Date;
    IP_TERM_MODI: string;
}