import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class InformarService {

  principal: EventEmitter<any> = new EventEmitter();

  cuentasPorCobrarEmitter: EventEmitter<boolean> = new EventEmitter();
  cuentasPorPagarEmitter: EventEmitter<boolean> = new EventEmitter();

  constructor() { }

  informarPrincipal(page){
    this.principal.emit(page);
  }

  informarCuentasPorCobrar(){
    this.cuentasPorCobrarEmitter.emit(true);
  }

  informarCuentasPorPagar(){
    this.cuentasPorPagarEmitter.emit(true);
  }
  
}
