import { NgModule } from "@angular/core";
import { PanelModule } from 'primeng/panel';
import { ButtonModule } from 'primeng/button';
import { ChartModule } from 'primeng/chart';
import { TableModule } from 'primeng/table';
import { MultiSelectModule } from 'primeng/multiselect';
import { OrderListModule } from 'primeng/orderlist';
import { PaginatorModule } from 'primeng/paginator';
import { CardModule } from 'primeng/card';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { MessageService, ConfirmationService } from 'primeng/api';
import { SplitButtonModule } from 'primeng/splitbutton';
import { DropdownModule } from 'primeng/dropdown';
import { ToastModule } from 'primeng/toast';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { PasswordModule } from 'primeng/password';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { MegaMenuModule } from 'primeng/megamenu';
import { MenubarModule } from 'primeng/menubar';
import { ToolbarModule } from 'primeng/toolbar';
import { SidebarModule } from 'primeng/sidebar';
import { TabViewModule } from 'primeng/tabview';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TooltipModule } from 'primeng/tooltip';
import { FieldsetModule } from 'primeng/fieldset';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { EditorModule } from 'primeng/editor';
import { CalendarModule } from 'primeng/calendar';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputMaskModule } from 'primeng/inputmask';
import { TreeTableModule } from 'primeng/treetable';
import { DataViewModule } from 'primeng/dataview';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { KeyFilterModule } from 'primeng/keyfilter';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ChipsModule } from 'primeng/chips';
import { StepsModule } from 'primeng/steps';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CheckboxModule } from 'primeng/checkbox';
import { FileUploadModule } from 'primeng/fileupload';
import { AccordionModule } from 'primeng/accordion';
import { TabMenuModule } from 'primeng/tabmenu';
import { CarouselModule } from 'primeng/carousel';
import {MenuModule} from 'primeng/menu';
import {DynamicDialogModule} from 'primeng/dynamicdialog';
import {InputNumberModule} from 'primeng/inputnumber';
import {PanelMenuModule} from 'primeng/panelmenu';

@NgModule({
    imports: [DynamicDialogModule, InputNumberModule],
    declarations: [],
    exports: [
        PanelMenuModule,
        PanelModule,
        ButtonModule,
        ChartModule,
        TableModule,
        MultiSelectModule,
        //DataTableModule,
        OrderListModule,
        PaginatorModule,
        DropdownModule,
        CardModule,
        ToastModule,
        MessageModule,
        MessagesModule,
        ConfirmDialogModule,
        DialogModule,
        PasswordModule,
        BreadcrumbModule,
        MegaMenuModule,
        MenubarModule,
        ToolbarModule,
        SplitButtonModule,
        SidebarModule,
        TabViewModule,
        OverlayPanelModule,
        SelectButtonModule,
        TooltipModule,
        FieldsetModule,
        ProgressSpinnerModule,
        AutoCompleteModule,
        EditorModule,
        CalendarModule,
        CalendarModule,
        InputTextareaModule,
        InputMaskModule,
        TreeTableModule,
        DataViewModule,
        ToggleButtonModule,
        KeyFilterModule,
        InputSwitchModule,
        ChipsModule,
        StepsModule,
        RadioButtonModule,
        CheckboxModule,
        FileUploadModule,
        AccordionModule,
        TabMenuModule,
        CarouselModule,
        MenuModule,
        DynamicDialogModule,
        InputNumberModule
    ],
    providers: [MessageService, 
                ConfirmationService]
})
export class primeNgModule { }