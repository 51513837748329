import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfiguracionService } from '../seguridad_api/configurations/configuracion.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResponseRoles, Roles } from 'src/app/model/seguridad/roles';

@Injectable({
  providedIn: 'root'
})
export class RolesService {

  url: string;
  constructor(private http: HttpClient,
    private configuracion: ConfiguracionService) {
    this.url = this.configuracion.serverUrlApiSegura;
  }

  public obtenerRolesPorUsuario(idUsuario: string): Observable<any[]> {
    let url = this.url + `/usuario-roles?idUsuario=${idUsuario}`;
    return this.http.get<any[]>(url).pipe(
      map((response: any) => {
        return response.data;
      })
    )
  }

  public obtenerRolesTipoPorUsuario(idUsuario: string, tipo: string, idUnidOper: any): Observable<Roles[]> {
    let url = this.url + `/roles-tipo?usuario=${idUsuario}&tipo=${tipo}&idUnidOper=${idUnidOper}`;
    return this.http.get<ResponseRoles>(url).pipe(
      map((response: ResponseRoles) => {
        return response.data;
      })
    )
  }

}
