<p-card header="Cuentas por Cobrar {{deCia}}" [style]="{'background-color': '#b1ffca'}">
  <div class="p-grid">
      <div class="p-col-6">
          <span>Total deuda:</span>
      </div>
      <div class="p-col-6">
          <span>S/. 150,000.00</span>
      </div>
      <div class="p-col-6">
          <span>Total deuda vencida:</span>
      </div>
      <div class="p-col-6">
          <span>S/. 15,000.00</span>
      </div>
      <div class="p-col-6">
          <span>% de cumplimiento:</span>
      </div>
      <div class="p-col-6">
          <span>90%</span>
      </div>
      <div class="p-col-12">
        <button type="button" pButton pRipple icon="pi pi-search" (click)="verDetalle()" 
                pTooltip="Ver Detalle" tooltipPosition="bottom" label="Ver Detalle" 
                style="background-color:transparent; border-color:black; color:black"></button>
      </div>
  </div>
</p-card>