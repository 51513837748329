import { Component, OnInit, OnDestroy, ViewChild, Inject, Input, OnChanges} from '@angular/core';
import { Subscription } from 'rxjs';
import { WebsocketService } from 'src/app/services/websocket.service';
import { NotificacionService } from 'src/app/services/notificacion/notificacion.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { LoadingService } from 'src/app/services/util/loading.service';
import { ConfiguracionService } from 'src/app/services/seguridad_api/configurations/configuracion.service';
import { InformarService } from 'src/app/services/util/informar.service';
import { VentasService } from 'src/app/services/indicadores/ventas.service';

import { HeaderComponent } from '../header/header.component';
import { MenuComponent } from '../menu/menu.component';
import { UsuariosService } from 'src/app/services/seguridad/usuarios.service';
import { DOCUMENT } from '@angular/common';
import { ContentComponent } from '../content/content.component';
import { SharedService } from '../../services/shared.service';
import { Usuario } from 'src/app/model/seguridad/usuario';
import { ComboUnidOperativa } from 'src/app/model/maestro/combos';
import { MaestroService } from 'src/app/services/maestros/maestro.service';
import { map } from 'rxjs/operators';
import { InitCoordinatorService } from '../../services/init-coordinator.service';
import { setLoadingSpinner } from '../../util/Util';

@Component({
  selector: 'app-principal',
  templateUrl: './principal.component.html',
  styleUrls: ['./principal.component.scss']
})
export class PrincipalComponent implements OnInit, OnDestroy, OnChanges {

  //private refreshNotificacion: Subscription;
  //private pushNotification: Subscription;
  private informePrincipal: Subscription;
  private refreshVentas: Subscription;
  private refreshCuentasPagar: Subscription;
  private refreshCuentasCobrarDocuVenc: Subscription;
  private refreshCuentasCobrarDeudaVenc: Subscription;

  private refreshNotificacion: Subscription;
  private pushNotification: Subscription;
  private pushMsgNoti: Subscription;
  private openSocket: Subscription;
  private closeSocket: Subscription;

  //private pushMsgNoti: Subscription;

  abrirMenu = false;
  usuario: string;
  cia: any = {};

  mostrarIndicadores: boolean;
  mostrarNotificaciones: boolean;
  mostrarReportes: boolean;

  loadingSpinner: Subscription;
  bloquearCardIndicadores: boolean;
  bloquearCardNotificacion: boolean;
  bloquearCardReporte: boolean;

  flexMenu: number;
  flexContent: number;

  url: string;
  urlEnlace: string;
  logo: string;
  permiso = false; //cambiar el permiso para desbloquear opciones
  username: any;
  datosUsuario: Usuario;

  @ViewChild('header', { static: true }) header: HeaderComponent;
  @ViewChild('menu', { static: true }) menu: MenuComponent;
  @ViewChild('content', { static: true }) content: ContentComponent;

  constructor(
    private usuarioService: UsuariosService,
    private notificacionService: NotificacionService,
    private spinner: NgxSpinnerService,
    private router: Router,
    private maestroService: MaestroService,
    private configuracionService: ConfiguracionService,
    private informarService: InformarService,
    private ventasService: VentasService,
    private initCoordinator: InitCoordinatorService
    ) {
    this.urlEnlace = this.configuracionService.ipRecursos + this.configuracionService.contextoRecurso;
  }

  ngOnInit() {
    console.log('ngOnInit-principal');
    this.usuario = sessionStorage.getItem('SESSIONUSER');
    if (this.usuario && this.usuario != null) {
      this.username = this.usuario;
      this.openMenu(true);
      this.obtenerDatosUsuario();
    }
   }

   obtenerDatosUsuario() {
    this.usuarioService.obtenerUsuarioSeguro(this.username).subscribe({
      next: (usuario: Usuario) => {
        if (usuario) {
          this.datosUsuario = usuario;
          this.obtenerListacia();
        }
      }
    });
  }

  obtenerListacia() {
    console.log('Principal-obtenerListacia');
    const cia = this.maestroService.obtenerUnidadOperativa(this.datosUsuario.ID_USUA).pipe(
      map((lista: ComboUnidOperativa[]) => {
        const cia = localStorage.getItem("data-header");
        if (cia){
          console.log('cia-existe', cia);
        }else{
          localStorage.setItem('data-header', btoa(JSON.stringify(lista.filter(f=>f.IN_ORGN_DFLT == '1')[0])));
        }

        console.log('Principal-obtenerListacia-Fin');
        this.initCoordinator.notifyInit();
      })
    );

    setLoadingSpinner(this.spinner, cia);
  }

    
  ngOnDestroy(): void {
    if ((this.openSocket)) this.openSocket.unsubscribe();
    if ((this.closeSocket)) this.closeSocket.unsubscribe();
    this.unsubscribeEvents();
  }


  unsubscribeEvents() {
    if ((this.refreshNotificacion)) this.refreshNotificacion.unsubscribe();
    if ((this.pushNotification)) this.pushNotification.unsubscribe();
    if ((this.pushMsgNoti)) this.pushMsgNoti.unsubscribe();
  }



  cambioCia(event: any): void {
    console.log('cambioCiaPrincipal', event);
    this.cia = event;
    this.logo = event.DE_LOGO;
    localStorage.setItem('data-header', btoa(JSON.stringify(event)));
    //this.router.navigateByUrl(`/sistema-phoenix/gestion`);
    window.location.reload();
  }

  cambioContent(event:any): void {
    console.log('cambioContent', event);
    this.content.cia = event;
  }

  openMenu(event:any): void {
    //console.log('openMenu', event);

    if (this.abrirMenu){
      this.abrirMenu = false;
      this.flexContent = 100;
      this.flexMenu = 0;
    }else{
      this.abrirMenu = true;
      this.flexContent = 85;
      this.flexMenu = 15;
    }
  }

  pushNotificacion(obj: any, personalizado: boolean) {
    let conf: Array<any> = [];
    if (!personalizado) {
      conf.push({
        'title': 'SISTEMA PHOENIX',
        'content': `Tienes ${obj.count} pendientes de revisión.`,
        'link': '',
        'icon': '../../assets/icons/phoenix_logo.png',
        'sound': '../../assets/sound/birdroid.mp3' //la ruta debe ser desde notification.service.ts
      });
      //this.notificacionPushService.crearNotification(conf);
      //Si se mostro la notificación (EL PUSH SE MOSTRO EN LA PANTALLA) => actualizar la notificación
      this.notificacionService.actualizarNotificacionVistoPorUsuario(obj.id).subscribe();
    } else {
      if ((obj)) {
        conf.push({
          'title': 'SISTEMA PHOENIX',
          'content': `${obj.DE_NOTI}`,
          'link': `${this.urlEnlace}/${obj.DE_PRMT_01}`,
          'icon': '../../assets/icons/phoenix_logo.png',
          'sound': `../../assets/sound/${obj.DE_SNDO}` //la ruta debe ser desde notification.service.ts
        });
        //this.notificacionPushService.crearNotification(conf);
        //Si se mostro la notificación (EL PUSH SE MOSTRO EN LA PANTALLA) => actualizar la notificación
        this.notificacionService.actualizarNotificacionVisto(obj).subscribe();
      }
    }

  }

  verIndicadores(mostrarIndicadores) {
    this.mostrarIndicadores = this.location('/phoenix/indicadores') ? false : !mostrarIndicadores;
    if (this.mostrarIndicadores) {
      this.bloquearCardIndicadores = true;
      this.mostrarNotificaciones = false;
      this.mostrarReportes = false;
      this.router.navigateByUrl('/phoenix/indicadores');
    } else {
      this.router.navigateByUrl('/phoenix');
    }
  }

  verNotificaciones(mostrarNotificaciones) {
    this.mostrarNotificaciones = this.location('/sistema-phoenix/gestion/notificacion') ? false : !mostrarNotificaciones;
    if (this.mostrarNotificaciones) {
      this.bloquearCardNotificacion = true;
      this.mostrarIndicadores = false;
      this.mostrarReportes = false;
      this.router.navigateByUrl('/sistema-phoenix/gestion/notificacion');
    } else {
      this.router.navigateByUrl('/phoenix');
    }
  }

  verReportes(mostrarReportes) {
    this.mostrarReportes = this.location('/phoenix/reportes') ? false : !mostrarReportes;
    if (this.mostrarReportes) {
      this.bloquearCardReporte = true;
      this.mostrarIndicadores = false;
      this.mostrarNotificaciones = false;
      this.router.navigateByUrl('/phoenix/reportes');
    } else {
      this.router.navigateByUrl('/phoenix');
    }
  }

  location(path: string): boolean {
    var href = location.href;
    this.url = this.configuracionService.servidorLocation;
    if (href && href != null && href == this.url.concat(path)) {
      return true;
    } return false;
  }

  validarLocation() {
    const pathName = location.pathname;
    if (pathName == '/phoenix/indicadores') {
      this.mostrarIndicadores = true;
      this.mostrarNotificaciones = false;
      this.mostrarReportes = false;
    } else if (pathName == '/sistema-phoenix/gestion/notificacion') {
      this.mostrarIndicadores = false;
      this.mostrarNotificaciones = true;
      this.mostrarReportes = false;
    } else if (pathName == '/phoenix/reportes') {
      this.mostrarIndicadores = false;
      this.mostrarNotificaciones = false;
      this.mostrarReportes = true;
    } else {
      this.mostrarIndicadores = false;
      this.mostrarNotificaciones = false;
      this.mostrarReportes = false;
    }
  }

  RefreshVentas() {
    const pathName = location.pathname;
    if (pathName == '/phoenix/indicadores') {
      const cardActive: any = document.getElementsByClassName("card-active-ind");
      if (cardActive && cardActive != null) {
        const textCard = cardActive[0] && cardActive[0] != null ? cardActive[0].innerText : '';
        if (textCard == 'Ventas') {
          this.ventasService.emitirEventoVentas();
        }
      }
    }

  }

  RefreshCuentasPagar() {
    const pathName = location.pathname;
    if (pathName == '/phoenix/indicadores') {
      const cardActive: any = document.getElementsByClassName("card-active-ind");
      if (cardActive && cardActive != null) {
        const textCard = cardActive[0] && cardActive[0] != null ? cardActive[0].innerText : '';
        if (textCard == 'Cuentas Por Pagar') {
          this.informarService.informarCuentasPorPagar();
        }
      }
    }
  }

  RefreshCuentasCobrarDocuVenc() {
    const pathName = location.pathname;
    if (pathName == '/phoenix/indicadores') {
      const cardActive: any = document.getElementsByClassName("card-active-ind");
      if (cardActive && cardActive != null) {
        const textCard = cardActive[0] && cardActive[0] != null ? cardActive[0].innerText : '';
        if (textCard == 'Cuentas por Cobrar') {
          this.informarService.informarCuentasPorCobrar();
        }
      }
    }
  }

  RefreshCuentasCobrarDeudaVenc() {
    const pathName = location.pathname;
    if (pathName == '/phoenix/indicadores') {
      const cardActive: any = document.getElementsByClassName("card-active-ind");
      if (cardActive && cardActive != null) {
        const textCard = cardActive[0] && cardActive[0] != null ? cardActive[0].innerText : '';
        if (textCard == 'Cuentas por Cobrar') {
          this.informarService.informarCuentasPorCobrar();
        }
      }
    }
  }

  ngOnChanges() {
    /**********THIS FUNCTION WILL TRIGGER WHEN PARENT COMPONENT UPDATES 'someInput'**************/
    //Write your code here
     console.log('ngOnChanges-principal');

  }

}