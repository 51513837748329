import { Injectable, EventEmitter } from "@angular/core";

@Injectable()
export class IndicadorCuentasPorPagarServicioEmiter{

    change: EventEmitter<any> = new EventEmitter();

    clickGrafico(opcion: any, select: any){
        var emitir = [opcion, select];
        this.change.emit(emitir);
    }
}