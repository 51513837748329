import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { AuthorizationService } from 'src/app/services/authorization/authorization.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService, Message } from 'primeng/api';
import { MailService } from 'src/app/services/general/mail.service';
import { UsuariosService } from 'src/app/services/seguridad/usuarios.service';
import { Mail } from 'src/app/model/mail.class';
import { HTMLRecuperacionContrasena } from '../../../plantillas-html/recuperacion-contrasena';
import { ParametrosService } from 'src/app/services/general/parametros.service';
import { forkJoin } from 'rxjs';
import { WebServicePhoenixService } from 'src/app/services/authorization/web-service-phoenix.service';
import { ConfiguracionService } from 'src/app/services/seguridad_api/configurations/configuracion.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  msgs: Message[] = [];
  display = false;
  submitted = false;
  userInvalid = false;
  correo: any;
  correoInvalido = false;
  usuarioTemporal: any;
  permiso: boolean = true;

  uploadedFiles = [];

  @ViewChild('username', { static: true }) username: ElementRef;
  constructor(
    private route: Router,
    private authorizationService: AuthorizationService,
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private mailService: MailService,
    private usuarioService: UsuariosService,
    private parametroService: ParametrosService,
    private webServicePhoenixService: WebServicePhoenixService,
    private configuracion: ConfiguracionService,
    private spinner: NgxSpinnerService,
    private cookieService: CookieService) { }

  ngOnInit() {
    document.getElementsByTagName('body')[0].classList.add('body-dashboard');

    sessionStorage.clear();
    localStorage.clear();
    this.cookieService.deleteAll();
    this.createForm();
    this.spinner.hide();
  }

  createForm() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  get f() { return this.loginForm.controls; }

  webService(token: string) {
    this.webServicePhoenixService.verificarEstadoServidor().subscribe({
      next: (response: any) => {
        if (response) {
          if (response && response != null && response.parameter.estado) {
            const url = this.configuracion.serverUrlRecurso + `/acceso_externo.xhtml?accion=login&parametros=${token}`;
            window.name = 'parent';
            window.open(url, '_blank');
            window.parent.focus();
            this.spinner.show();
            setTimeout(() => {
              /** Descomentar para visualizar todo */
              /*this.route.navigateByUrl('/phoenix/dashboard');
              this.usuarioService.abrirSocket();*/
              /** Ruta para iniciar en notificacion */
              this.route.navigateByUrl('/sistema-phoenix/gestion');
            }, 2000);
          }
        } else {
          this.messageService.add({
            severity: 'error',
            summary: 'Service Message Phoenix',
            detail: 'Error al comunicarse con los recursos de phoenix. Por favor comunicarse con el área de sistemas'
          });
        }
      }
    });
  }

  login() {
    this.submitted = true;
    if (this.loginForm.valid) {
      this.authorizationService.accesoLogin(this.loginForm.value).subscribe({
        next: (response: any) => {
          if (response && response != null && response.success !== undefined && !response.success) {
            this.messageService.add({ severity: 'error', summary: 'Service Message Phoenix', detail: response.msg });
          } else {
            // _this.cookieService.deleteAll();
            sessionStorage.clear();
            // _this.cookieService.set('JSESSIONID', response['Set-Cookie']);
            // _this.cookieService.set('JSESSIONREFID', response['refresh-token']);
            sessionStorage.setItem('JSESSIONID', response['Set-Cookie']);
            sessionStorage.setItem('JSESSIONREFID', response['refresh-token']);
            sessionStorage.setItem('KEYSESSION', response['key']);
            sessionStorage.setItem('SESSIONUSER', this.loginForm.controls.username.value);
            this.webService(response['Set-Cookie']);
          }
        }
      });
    }
  }

  actualizarIndCambioPassword(datosUsuario: any, indCambioPassword: number) {
    this.authorizationService.actuaIndCambioPassword(datosUsuario, indCambioPassword).subscribe();
  }


  restablecerContrasena() {
    this.correo = [];
    const username = this.loginForm.controls.username.value;
    // Encontrar los datos del usuario
    if (!username || username == null || username.trim() === '') {
      this.userInvalid = true;
      this.username.nativeElement.focus();
      return false;
    } else {
      // validar Usuario
      this.usuarioService.obtenerUsuario(username).subscribe({
        next: (respuesta: any) => {
          if (respuesta && respuesta != null) {
            this.usuarioTemporal = Object.assign({}, respuesta);
            if (respuesta.DE_CORREO) {
              // funciona: /(?<=.{3}).(?=.*@)/g
              // /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]/
              /*var re = /(?<=.{3}).(?=.*@)/g; ///^[?<=.{3}.(?=.*@)]/g; ///(?<=.{3}).(?=.*@)/g;
              if (respuesta.DE_CORREO.search(",") != -1) {
                const correos = respuesta.DE_CORREO.split(",");
                correos.forEach(correo => {
                  _this.correo.push(correo.replace(re, "*"));
                })
              } else {
                _this.correo = [respuesta.DE_CORREO.replace(re, '*')];
              }*/
              this.correo = respuesta.MAIL_EXPRESS;
              this.correoInvalido = false;
              this.display = true;
            } else {
              this.correo = ['correo no registrado'];
              this.correoInvalido = true;
            }
          } else {
            this.userInvalid = true;
            this.submitted = false;
          }
        }
      });
    }
  }

  confirmarRestablecerContrasena() {
    const username = this.loginForm.controls.username.value;
    const secuencialMail = this.mailService.secuencialMail();
    const tiempoExpiracion = this.parametroService.obtenerParametro('TIEMPO_EXPI_PASSWORD');
    const enlaceCambioPass = this.parametroService.obtenerParametro('LINK_CAMBIAR_PASSWORD');
    const tokenEnvioCorreo = this.authorizationService.generarTokenAuthForMail(username);

    forkJoin(secuencialMail, tiempoExpiracion, enlaceCambioPass, tokenEnvioCorreo)
      .subscribe(([secuencialMailResponse, tiempoExpiracionResponse,
        enlaceCambioPassResponse, tokenEnvioCorreoResponse]: [any, any, any, string]) => {
        const mail: Mail = new Mail();
        mail.ID_SEC = secuencialMailResponse.secuencial + 1;
        mail.CO_PROC = 'MAIL';
        mail.DE_MAIL_FROM = 'sysadmin@dest.com.pe';
        mail.DE_MAIL_TO = this.usuarioTemporal.DE_CORREO;
        mail.DE_MAIL_CC = '';
        mail.CO_SPRD = '';
        mail.DE_ASNT = 'Recuperación de Contraseña';
        mail.DE_MSJE = HTMLRecuperacionContrasena
          .replace(/{nombreUsuario}/g, (this.usuarioTemporal.AP_PATE + ' ' + this.usuarioTemporal.AP_MATE))
          .replace(/{link}/g, (enlaceCambioPassResponse && enlaceCambioPassResponse != null
            && enlaceCambioPassResponse.length > 0 ?
            enlaceCambioPassResponse[0].DE_VALO_PARM.concat(`?token=${tokenEnvioCorreoResponse}`) : ''))
          .replace(/{tmpoExpiPass}/g, (tiempoExpiracionResponse && tiempoExpiracionResponse != null
            && tiempoExpiracionResponse.length > 0 ? tiempoExpiracionResponse[0].DE_VALO_PARM : ''));
        mail.DE_ARCH = '';
        mail.BI_ARCH = null;
        mail.CO_TIPO_MAIL = '2';
        mail.ID_ESTA = 10;
        mail.CO_USUA_CREA = this.usuarioTemporal.CO_USUA;
        mail.FE_CREA = new Date();
        mail.IP_TERM_CREA = '';
        mail.CO_USUA_MODI = '';
        mail.FE_MODI = null;
        mail.IP_TERM_MODI = '';

        this.mailService.registrarMail(mail).subscribe({
          next: (response: any) => {
            if (response && response.status === 200) {
              this.actualizarIndCambioPassword(this.usuarioTemporal, 0);
              this.usuarioTemporal = null;
              this.messageService.add({
                severity: 'success',
                summary: 'Service Message Phoenix', detail: 'Se ha enviado un correo para restablecer su contraseña'
              });
            } else {
              this.messageService.add({
                severity: 'error',
                summary: 'Service Message Phoenix', detail: 'Error al registrar el correo de recuperación de contraseña'
              });
            }
            this.display = false;
          }
        });
      });

  }

  cancelarRestablecerContrasena() {
    this.display = false;
  }

  onUpload(event) {
    for (let file of event.files) {
      this.uploadedFiles.push(file);
    }

    this.parametroService.archivo(event.files[0]).subscribe({
      next: (response: any) => {
        this.messageService.add({ severity: 'info', summary: 'File Uploaded', detail: '' });
      }
    })


  }

}
