import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfiguracionService } from '../seguridad_api/configurations/configuracion.service';
import { Observable } from 'rxjs';
import { map, share } from 'rxjs/operators';
import { ComboAlmacen, ComboTipoLinea, ComboImpuestos, ComboImpuestoEtapas, ComboUnidOperativa, ComboTipoPersona, ComboDepartamento, ComboProvincia, ComboDistrito, ComboGenerico, ComboTipoTransaccion } from 'src/app/model/maestro/combos';
import { ResponseFlex, Flex } from 'src/app/model/maestro/flex/contexto/Flex';
import { SerieGuia, ResponseSerieGuia } from 'src/app/model/maestro/series/SerieGuia';
import { ResponseTipoDocumento, TipoDocumento } from 'src/app/model/maestro/TipoDocumento';
import { RoutingConst } from 'src/app/constantes/routing';
import { TerminoPagoMaestro, ZonaVenta, Motivos, ComboMoneda, SubinventarioVistaResponse, SubinventarioVista, TerminoPagoLine } from 'src/app/model/maestro/maestro';
import { ResponseTiposFactura, TipoFactura } from 'src/app/model/finanzas/facturacion/TipoFactura';
import { Lookup, ResponseLookup } from 'src/app/model/maestro/flex/Lookup';
import { ResponseBancos, Banco, ResponseBancosSucursal, BancoSucursal } from 'src/app/model/maestro/pagos/Bancos';
import { AlmacenPorCiaVista, ResponseAlmacenPorCiaVista } from 'src/app/model/maestro/organizacion/Organizacion';
import { Procesos } from 'src/app/model/maestro/contabilidad/Procesos';
import { ParametrosInventario, ParametrosInventarioResponse, UnidadMedida, UnidadMedidaResponse } from 'src/app/model/maestro/inventario/Inventario';
import { GrupoRetencion, ResponseGrupoRetencion } from 'src/app/model/maestro/proveedor/proveedor';
import { getParamsForUrl } from 'src/app/component/util/Util';
import { MotivoDevolucionSunat, ResponseMotivoDevolucionSunat } from 'src/app/model/maestro/ventas/Ventas';
import { FormulaPrecioVenta, ResponseFormulaPrecioVenta } from 'src/app/model/ventas/precio-venta';
import { Periodo } from 'src/app/model/periodos/periodo';
@Injectable({
  providedIn: 'root'
})
export class MaestroService {

  urlServicioWeb: string;
  constructor(private httpClient: HttpClient,
    private configuracion: ConfiguracionService) {
    this.urlServicioWeb = this.configuracion.serverUrlApiSegura.concat(RoutingConst.maestro);
  }

  obtenerDatosCabecera(params: any): Observable<any> {
    const url = `${this.urlServicioWeb}/obtener-datos-cabecera${getParamsForUrl(params)}`;
    return this.httpClient.get<any>(url).pipe(
        map((response: any) => {
            return response.data;
        })
    );
}

  obtenerPeriodoProceso(params: any): Observable<Periodo[]> {
    const url = `${this.urlServicioWeb}/obtener-periodo-proceso${getParamsForUrl(params)}`;
    return this.httpClient.get<any>(url).pipe(
      map((response: any) => {
        return response.data;
      })
    );
  }

  obtenerUnidadOperativa(idUsuario: number): Observable<ComboUnidOperativa[]> {
    const url = `${this.urlServicioWeb}/compania?idUsua=${idUsuario}`;
    return this.httpClient.get<any>(url).pipe(
      map((response: any) => {
        return response.data;
      })
    );
  }

  obtenerUnidadOperativaPorUsuarioRol(idUsuario: number, deRol: string): Observable<ComboUnidOperativa[]> {
    const url = `${this.urlServicioWeb}/compania-by-usuario-rol?idUsua=${idUsuario}&deRol=${deRol}`;
    return this.httpClient.get<any>(url).pipe(
      map((response: any) => {
        return response.data;
      })
    );
  }


  obtenerMonedaVenta(): Observable<ComboMoneda[]> {
    const url = `${this.urlServicioWeb}/moneda-venta`;
    return this.httpClient.get<any>(url).pipe(
      map((response: any) => {
        return response.data;
      })
    );
  }

  obtenerUnidadMedida(): Observable<any> {
    const url = `${this.urlServicioWeb}/unidad-medida`;
    return this.httpClient.get<any>(url).pipe(
      map((response: any) => {
        return response.data;
      })
    );
  }

  obtenerArticulos(undOper: number): Observable<any> {
    const url = `${this.urlServicioWeb}/articulos?undOper=${undOper}`;
    return this.httpClient.get<any>(url).pipe(
      map((response: any) => {
        return response.data;
      })
    );
  }

  obtenerMarcaCategoriaModelo(idOrgn: string, coTipo: string, coMrca: string, coLnea: string, coCtga: string, coMdlo: string): Observable<any> {
    const url = `${this.urlServicioWeb}/marca-categoria-modelo?idOrgn=${idOrgn}&coTipo=${coTipo}&coMrca=${coMrca}&coLnea=${coLnea}&coCtga=${coCtga}&coMdlo=${coMdlo}`;
    return this.httpClient.get<any>(url).pipe(
      map((response: any) => {
        return response.data;
      })
    );
  }

  obtenerUnidadMedidaNotClass(coUnidMedi: string, coLang: string, inBase: string, notCoClas: string[]): Observable<any> {
    const url = `${this.urlServicioWeb}/und-medida-not-class?coUnidMedi=${coUnidMedi}&coLang=${coLang}&inBase=${inBase}&notCoClas=${notCoClas}`;
    return this.httpClient.get<any>(url).pipe(
      map((response: any) => {
        return response.data;
      })
    );
  }

  obtenerAlmacenByUnidOper(idUniOper: number): Observable<ComboAlmacen[]> {
    const url = `${this.urlServicioWeb}/almacen?idUniOper=${idUniOper}`;
    return this.httpClient.get<any>(url).pipe(
      map((response: any) => {
        return response.data;
      })
    );
  }

  obtenerAlmacenesPorCia(idUnidOper: number): Observable<AlmacenPorCiaVista[]> {
    const url = `${this.urlServicioWeb}/almacenes-por-cia?idUnidOper=${idUnidOper}`;
    return this.httpClient.get<ResponseAlmacenPorCiaVista>(url).pipe(
      map((response: ResponseAlmacenPorCiaVista) => {
        return response.data;
      })
    );
  }

  obtenerAlmacenes(): Observable<ComboAlmacen[]> {
    const url = `${this.urlServicioWeb}/almacenes`;
    return this.httpClient.get<any>(url).pipe(
      map((response: any) => {
        return response.data;
      })
    );
  }

  obtenerTipoLinea(nombValoFlex: string, valorPareLow: string, valorPareHigh: string): Observable<ComboTipoLinea[]> {
    const url = `${this.urlServicioWeb}/tipo-linea?nombValoFlex=${nombValoFlex}&valorPareLow=${valorPareLow}&valorPareHigh=${valorPareHigh}`;
    return this.httpClient.get<any>(url).pipe(
      map((response: any) => {
        return response.data;
      })
    );
  }

  obtenerImpuestos(): Observable<ComboImpuestos[]> {
    const url = `${this.urlServicioWeb}/impuestos`;
    return this.httpClient.get<any>(url).pipe(
      map((response: any) => {
        return response.data;
      })
    );
  }

  obtenerImpuestoEtapas(coProc: string): Observable<ComboImpuestoEtapas[]> {
    const url = `${this.urlServicioWeb}/impuesto-etapas?coProc=${coProc}`;
    return this.httpClient.get<any>(url).pipe(
      map((response: any) => {
        return response.data;
      })
    );
  }

  obtenerDepartamentos(): Observable<ComboDepartamento[]> {
    const url = `${this.urlServicioWeb}/departamentos`;
    return this.httpClient.get<any>(url).pipe(
      map((response: any) => {
        return response.data;
      })
    );
  }

  obtenerProvincias(coDepa: string): Observable<ComboProvincia[]> {
    const url = `${this.urlServicioWeb}/provincias?codDepa=${coDepa}`;
    return this.httpClient.get<any>(url).pipe(
      map((response: any) => {
        return response.data;
      })
    );
  }

  obtenerDistritos(coDepa: string, coProv: string): Observable<ComboDistrito[]> {
    const url = `${this.urlServicioWeb}/distritos?codDepa=${coDepa}&codProv=${coProv}`;
    return this.httpClient.get<any>(url).pipe(
      map((response: any) => {
        return response.data;
      })
    );
  }

  obtenerTipoPersona(): Observable<ComboTipoPersona[]> {
    const url = `${this.urlServicioWeb}/tipo-persona`;
    return this.httpClient.get<any>(url).pipe(
      map((response: any) => {
        return response.data;
      })
    );
  }

  obtenerTipoDocumento(): Observable<ComboGenerico[]> {
    const url = `${this.urlServicioWeb}/tipo-documento`;
    return this.httpClient.get<any>(url).pipe(
      map((response: any) => {
        return response.data;
      })
    );
  }

  obtenerFlexByFlexSet(idFlexSet: any): Observable<Flex[]> {
    const url = `${this.urlServicioWeb}/flex/flex-by-flex-set/${idFlexSet}`;
    return this.httpClient.get<ResponseFlex>(url).pipe(
      map((response: ResponseFlex) => {
        return response.data;
      })
    );
  }

  obtenerFlexByParentLow(valorPareLow: any): Observable<Flex[]> {
    const url = `${this.urlServicioWeb}/flex/flex-by-parent-low/${valorPareLow}`;
    return this.httpClient.get<ResponseFlex>(url).pipe(
      map((response: ResponseFlex) => {
        return response.data;
      })
    );
  }

  obtenerFlexByName(nombValoFlex: any): Observable<Flex[]> {
    const url = `${this.urlServicioWeb}/flex/flex-by-name/${nombValoFlex}`;
    return this.httpClient.get<ResponseFlex>(url).pipe(
      map((response: ResponseFlex) => {
        return response.data;
      })
    );
  }

  obtenerFlexByParams(deNombValoFlex: any, deValoPareLow: any, deValoPareHigh: any): Observable<Flex[]> {
    console.log(deNombValoFlex,deValoPareLow,deValoPareHigh);
    const url = `${this.urlServicioWeb}/flex/flex-by-params?deNombValoFlex=${deNombValoFlex}&deValoPareLow=${deValoPareLow}&deValoPareHigh=${deValoPareHigh}`;
    return this.httpClient.get<any>(url).pipe(
      map((response: any) => {
        return response.data;
      })
    );
  }

  obtenerSeriesGuia(idUnidOper: any, idOrgn: any): Observable<SerieGuia[]> {
    const url = `${this.urlServicioWeb}/serie-guia/series-guia-by-orgn?idUnidOper=${idUnidOper}&idOrgn=${idOrgn}`;
    return this.httpClient.get<ResponseSerieGuia>(url).pipe(
      map((response: ResponseSerieGuia) => {
        return response.data;
      })
    );
  }

  obtenerTipoDocumentoByUO(tipoDocu: string, coLang: string, unidOper: any): Observable<TipoDocumento[]> {
    const url = `${this.urlServicioWeb}/tipo-documentos-by-uo?tipoDocu=${tipoDocu}&coLang=${coLang}&unidOper=${unidOper}`;
    return this.httpClient.get<ResponseTipoDocumento>(url).pipe(
      map((response: ResponseTipoDocumento) => {
        return response.data;
      })
    );
  }

  obtenerTipoTransaccionPorUnidadOperativa(idUnidOper: any): Observable<ComboTipoTransaccion[]> {
    console.log('obtenerTipoTransaccionPorUnidadOperativa');
    const url = `${this.urlServicioWeb}/tipo-transaccion?idUnidOper=${idUnidOper}`;
    return this.httpClient.get<any>(url).pipe(
      map((response: any) => {
        //console.log(response.data);
        return response.data;
      })
    );
  }

  obtenerClaseDocumento(idUnidOper: number, idTipoTrx: number): Observable<ComboGenerico[]> {
    console.log('obtenerClaseDocumento')
    const url = `${this.urlServicioWeb}/clase-documento?idUnidOper=${idUnidOper}&idTipoTrx=${idTipoTrx}`;
    return this.httpClient.get<any>(url).pipe(
      map((response: any) => {
        return response.data;
      })
    );
  }

  obtenerTerminoPago(): Observable<TerminoPagoMaestro[]> {
    console.log('obtenerTerminoPago')
    const url = `${this.urlServicioWeb}/termino-pago`;
    return this.httpClient.get<any>(url).pipe(
      map((response: any) => {
        return response.data;
      })
    );
  }

  obtenerTerminoPagoLine(idTerm: any): Observable<TerminoPagoLine[]> {
    console.log('obtenerTerminoPagoLine')
    const url = `${this.urlServicioWeb}/termino-pago-line?idTerm=${idTerm}`;
    return this.httpClient.get<any>(url).pipe(
      map((response: any) => {
        return response.data;
      })
    );
  }

  obtenerZonaVenta(idUnidOper: number): Observable<ZonaVenta[]> {
    console.log('obtenerZonaVenta')
    const url = `${this.urlServicioWeb}/zona-venta?idUnidOper=${idUnidOper}`;
    return this.httpClient.get<any>(url).pipe(
      map((response: any) => {
        //console.log(response.data);
        return response.data;
      })
    );
  }

  obtenerVendedor(idUnidOper: number): Observable<ComboGenerico[]> {
    console.log('obtenerVendedor')
    const url = `${this.urlServicioWeb}/vendedor?idUnidOper=${idUnidOper}`;
    return this.httpClient.get<any>(url).pipe(
      map((response: any) => {
        //console.log(response.data);
        return response.data;
      })
    );
  }

  obtenerMotivos(coTipo: string, coTipoDocu: string): Observable<Motivos[]> {
    console.log('obtenerMotivos')
    const url = `${this.urlServicioWeb}/motivos?coTipo=${coTipo}&coTipoDocu=${coTipoDocu}`;
    return this.httpClient.get<any>(url).pipe(
      map((response: any) => {
        //console.log(response.data);
        return response.data;
      })
    );
  }

  obtenerTipoFactura(): Observable<TipoFactura[]> {
    const url = `${this.urlServicioWeb}/tipos-factura`;
    return this.httpClient.get<ResponseTiposFactura>(url).pipe(
      map((response: ResponseTiposFactura) => {
        return response.data;
      })
    );
  }

  obtenerTiposLookup(): Observable<Lookup[]> {
    const url = `${this.urlServicioWeb}/tipos-lookup`;
    return this.httpClient.get<ResponseLookup>(url).pipe(
      map((response: ResponseLookup) => {
        return response.data;
      })
    );
  }

  obtenerTiposPagos(): Observable<Lookup[]> {
    const url = `${this.urlServicioWeb}/tipos-pagos`;
    return this.httpClient.get<ResponseLookup>(url).pipe(
      map((response: ResponseLookup) => {
        return response.data;
      })
    );
  }

  obtenerListaLookup(lookupType: string, language: string, idAplication: any): Observable<Lookup[]> {
    const url = `${this.urlServicioWeb}/lista-lookup?lookupType=${lookupType}&language=${language}&idAplication=${idAplication}`;
    return this.httpClient.get<ResponseLookup>(url).pipe(
      map((response: ResponseLookup) => {
        return response.data;
      })
    );
  }

  obtenerListaBancos(): Observable<Banco[]> {
    const url = `${this.urlServicioWeb}/bancos`;
    return this.httpClient.get<ResponseBancos>(url).pipe(
      map((response: ResponseBancos) => {
        return response.data;
      })
    );
  }

  obtenerListaBancosSucursal(idBnco: any): Observable<BancoSucursal[]> {
    const url = `${this.urlServicioWeb}/bancos-sucursal/${idBnco}`;
    return this.httpClient.get<ResponseBancosSucursal>(url).pipe(
      map((response: ResponseBancosSucursal) => {
        return response.data;
      })
    );
  }

  obtenerProcesos(idUnidOper: number): Observable<Procesos[]> {
    const url = `${this.urlServicioWeb}/procesos/obtener-procesos?idUnidOper=${idUnidOper}`;
    return this.httpClient.get<any>(url).pipe(
      map((response: any) => {
        return response.data;
      })
    );
  }

  obtenerSubinventarios(idOrgn: any): Observable<SubinventarioVista[]> {
    const url = `${this.urlServicioWeb}/subinventarios?idOrgn=${idOrgn}`;
    return this.httpClient.get<SubinventarioVistaResponse>(url).pipe(
      map((response: SubinventarioVistaResponse) => {
        return response.data;
      })
    );
  }

  obtenerSubinventariosPorParametros(params: any): Observable<SubinventarioVista[]> {
    let url = `${this.urlServicioWeb}/subinventarios`;
    url += getParamsForUrl(params);
    console.log('url', url);
    return this.httpClient.get<SubinventarioVistaResponse>(url).pipe(
      map((response: SubinventarioVistaResponse) => {
        return response.data;
      })
    );
  }

  obtenerLocalizador(idOrgn: any, coSubi: any): Observable<SubinventarioVista[]> {
    const url = `${this.urlServicioWeb}/localizadores?idOrgn=${idOrgn}&coSubi=${coSubi}`;
    return this.httpClient.get<SubinventarioVistaResponse>(url).pipe(
      map((response: SubinventarioVistaResponse) => {
        return response.data;
      })
    );
  }

  obtenerLocalizadorPorParametros(params: any): Observable<SubinventarioVista[]> {
    let url = `${this.urlServicioWeb}/localizadores`;
    url += getParamsForUrl(params);
    return this.httpClient.get<SubinventarioVistaResponse>(url).pipe(
      map((response: SubinventarioVistaResponse) => {
        return response.data;
      })
    );
  }

  obtenerParametrosInventarioPorOrganizacion(idOrgn: any): Observable<ParametrosInventario[]> {
    const url = `${this.urlServicioWeb}/obtener-parametros-inventario?idOrgn=${idOrgn}`;
    return this.httpClient.get<ParametrosInventarioResponse>(url).pipe(
      map((response: ParametrosInventarioResponse) => {
        return response.data;
      })
    );
  }

  obtenerUnidadMedidaPorClase(coUnidMedi: any): Observable<UnidadMedida[]> {
    const url = `${this.urlServicioWeb}/obtener-unidad-medida-clase?coUnidMedi=${coUnidMedi}`;
    return this.httpClient.get<UnidadMedidaResponse>(url).pipe(
      map((response: UnidadMedidaResponse) => {
        return response.data;
      })
    );
  }
  
  obtenerGrupoRetencion(idUnidOper: any): Observable<GrupoRetencion[]> {
    const url = `${this.urlServicioWeb}/grupo-retencion/${idUnidOper}`;
    return this.httpClient.get<ResponseGrupoRetencion>(url).pipe(
      map((response: ResponseGrupoRetencion) => {
        return response.data;
      })
    );
  }

  obtenerMotivoDevolucionSunat(): Observable<MotivoDevolucionSunat[]> {
    const url = `${this.urlServicioWeb}/motivo-devolucion-sunat`;

    return this.httpClient.get<ResponseMotivoDevolucionSunat>(url).pipe(
      map((response: ResponseMotivoDevolucionSunat) => {
        return response.data;
      })
    );
  }

  obtenerConversionUnidMedPrim(coUnidMedi: any): Observable<number> {
    const url = `${this.urlServicioWeb}/conversion-ump?coUnidMedi=${coUnidMedi}`;
    return this.httpClient.get<any>(url).pipe(
      map((response: any) => {
        return response.data;
      })
    );
  }

  obtenerFormulaPrecioVenta(idFrml: any): Observable<FormulaPrecioVenta[]> {
    const url = `${this.urlServicioWeb}/formula-precio-vta?idFrml=${idFrml}`;
    return this.httpClient.get<ResponseFormulaPrecioVenta>(url).pipe(
      map((response: any) => {
        return response.data;
      })
    );
  }

  
  obtenerFactorConversionEspecial(coConv: any): Observable<any[]> {
    const url = `${this.urlServicioWeb}/factor-conversion-especial?coConv=${coConv}`;
    return this.httpClient.get<any>(url).pipe(
      map((response: any) => {
        return response.data;
      })
    );
  }

  obtenerEtapaVenta(coTabl: any): Observable<any[]> {
    const url = `${this.urlServicioWeb}/etapa-vta?coTabl=${coTabl}`;
    return this.httpClient.get<any>(url).pipe(
      map((response: any) => {
        return response.data;
      })
    );
  }

  obtenerEstadosVenta(): Observable<any[]> {
    const url = `${this.urlServicioWeb}/estados-vta`;
    return this.httpClient.get<any>(url).pipe(
      map((response: any) => {
        return response.data;
      })
    );
  }
  
}
