import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-token-invalido',
  templateUrl: './page-token-invalido.component.html',
  styleUrls: ['./page-token-invalido.component.scss']
})
export class PageTokenInvalidoComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    document.getElementsByTagName('body')[0].classList.add('body-dashboard');
  }

}
