<div class="wrapper fadeInDown">
  <div id="formContent">
    <p-card header="El tiempo ha finalizado" subheader="El recurso ya no se encuentra disponible"
      [style]="{width: '350px'}" styleClass="ui-card-shadow card-style">
      <p-header style="text-align: center">
        <img class="center" src="Card" src="./../../../../assets/icons/icon-timeout.png" id="icon" alt="Token Vencido"
          style="width: 50%; height: 70%;" />
      </p-header>
      <div class="ui-g ui-fluid">
        <div class="ui-g-12 ui-md-12">
          <br><br><br><br>
          <div class="ui-g form-group">
            <button pButton type="button" label="Ir a la Página de Inicio" [routerLink]="['/']"
              class="ui-button-raised ui-button-info"></button>
          </div>
        </div>
      </div>

    </p-card>
  </div>
</div>